/*
  Note: this mixin also requires that you import the "toastHelperMixin".
  TODO: think about whether we really need to check such kind of thing on the frontend -
  ideally, the filter should only be on the backend; it's just not optimal doing this
  check because it's redundant - it does not improve security in any way; usually, this kind of
  functionality is achieved by setting an isAdmin/role property on login and keeping that data until
  the end of the session
*/
export const checkIfAdminMixin = {
  methods: {
    /**
     * Check if a user is an admin and return true if he is, or false if
     * he's not (or the check failed)
     * @param {boolean} showToast Whether to show the "Insufficient permissions" message
     * @returns {boolean}
     */
    // todo remove this, on login return a user object that contains the user's role to know what to show and store it
    // todo make role checks in the backend at the start of every request - mm
    async isUserAdmin(axios, showToast) {
      try {
        const isUserAdminResponse = await axios.get(
          '/.netlify/functions/is-user-admin'
        )
        if (!isUserAdminResponse || !isUserAdminResponse.data || !isUserAdminResponse.data.isUserAdmin) {
          if (showToast) {
            this.showToast('Insufficient permissions. Operation aborted.', 'error')
          }
          return false
        }
        return true
      } catch (error) {
        this.showToast('Unable to check admin status.', 'error')
        return false
      }
    },
    async isUserAdminOrOAdmin(axios, showToast) {
      try {
        const isUserAdminResponse = await axios.get(
          '/.netlify/functions/is-user-admin-or-organisation-admin'
        )
        if (!isUserAdminResponse || !isUserAdminResponse.data || !isUserAdminResponse.data.isUserAdminOrOAdmin) {
          if (showToast) {
            this.showToast('Insufficient permissions. Operation aborted.', 'error')
          }
          return false
        }
        return true
      } catch (error) {
        this.showToast('Unable to check admin status.', 'error')
        return false
      }
    }
  }
}
