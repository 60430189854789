import axios from '../../../axios';
import PieChartRectangle from '../../components/pieChartRectangle/pieChartRectangle.vue';
import addOrganizationDialog from '../../components/addOrganizationDialog/addOrganizationDialog.vue';
import BigPieCharts from '../../components/bigPieCharts/bigPieCharts.vue';
import AddEntityBox from '../../components/addEntityBox/addEntityBox.vue';
import organizationsPageYaml from 'js-yaml-loader!../../../../content/organizations.yaml';
import { toastHelperMixin } from '../../mixins/toastHelperMixin';
import EditOrganizationModal from '../../components/modals/editOrganizationModal/editOrganizationModal.vue';
import { checkIfAdminMixin } from '../../mixins/checkIfAdminMixin';

export default {
  name: 'Organizations',
  mixins: [toastHelperMixin, checkIfAdminMixin],
  components: {
    addOrganizationDialog,
    PieChartRectangle,
    BigPieCharts,
    AddEntityBox,
    EditOrganizationModal
  },
  data: function() {
    return {
      type: undefined,
      isAdmin: false,
      title: '',
      organizations: [],
      name: String,
      isRightVisible: false,
      isLeftVisible: false,
      organizationsWithDataOdd: [],
      organizationsWithDataEven: [],
      features: [],
      scenarios: [],
      steps: [],
      period: 'month',
      showAddOrganizationDialog: false,
      userCount: undefined,
      showEditDigitalProductModal: false,
      currentlyEditingDigitalProductID: undefined,
      currentlyEditingDigitalProductName: undefined
    };
  },
  async created() {
    await axios.get('/.netlify/functions/get-user-preferences').then(data => {
      this.type = data.data.userPreference.type;
    });
    await axios.get('/.netlify/functions/get-organizations').then(data => {
      this.organizations = data.data;
      if (
        this.organizations.length === 1 &&
        JSON.parse(localStorage.getItem('userHasOneOrganization'))
      ) {
        this.$router.push(
          `/organizations/${this.organizations[0].name}/digital-products`
        );
      }
    });
    axios
      .post('/.netlify/functions/get-statistics-organizations', { type: this.type })
      .then(allOrganizationsData => {
        this.features.push([
          'PASSED',
          allOrganizationsData.data.passedBuckets || 0
        ]);
        this.features.push([
          'FAILED',
          allOrganizationsData.data.failedBuckets || 0
        ]);
        this.scenarios.push([
          'PASSED',
          allOrganizationsData.data.passedScenarios || 0
        ]);
        this.scenarios.push([
          'FAILED',
          allOrganizationsData.data.failedScenarios || 0
        ]);
        this.steps.push(['PASSED', allOrganizationsData.data.passedSteps || 0]);
        this.steps.push(['FAILED', allOrganizationsData.data.failedSteps || 0]);
        this.steps.push(['SKIPPED', allOrganizationsData.data.skippedSteps || 0]);
        this.steps.push(['SUGGESTED', allOrganizationsData.data.suggestedSteps || 0]);
        this.steps.push(['PERFORMANCE_FAILURE', allOrganizationsData.data.performanceFailure || 0]);

      });
    this.isAdmin = await this.isUserAdmin(axios, false);
    if (this.organizations.length % 2 == 0 && this.isAdmin)
      this.isLeftVisible = true;
    else if (this.organizations.length % 2 != 0 && this.isAdmin)
      this.isRightVisible = true;
    await this.getOrganizationsData();
  },
  async mounted() {
    this.title = organizationsPageYaml.title;
  },


  methods: {
    async getOrganizationsData(period = Number(process.env.DEFAULT_PERIOD)) {
      this.organizationsWithDataOdd = [];
      this.organizationsWithDataEven = [];
      const data = [];
      for (let i = 0; i < this.organizations.length; i++) {
        data.push({
          type: 4,
          organizationName: this.organizations[i].name,
          period: period
        });
      }
      const digitalProductsStatisticsList = await axios.post('/.netlify/functions/get-statistics-digital-products-list', data);
      this.handleRequestData(digitalProductsStatisticsList.data);
    },
    updateStatistics(type, period) {
      this.features = [];
      this.scenarios = [];
      this.steps = [];
      axios
        .post('/.netlify/functions/get-statistics-organizations', { type: type, period: period })
        .then(allOrganizationsData => {
          this.features.push([
            'PASSED',
            allOrganizationsData.data.passedBuckets || 0
          ]);
          this.features.push([
            'FAILED',
            allOrganizationsData.data.failedBuckets || 0
          ]);
          this.scenarios.push([
            'PASSED',
            allOrganizationsData.data.passedScenarios || 0
          ]);
          this.scenarios.push([
            'FAILED',
            allOrganizationsData.data.failedScenarios || 0
          ]);
          this.steps.push(['PASSED', allOrganizationsData.data.passedSteps || 0]);
          this.steps.push(['FAILED', allOrganizationsData.data.failedSteps || 0]);
          this.steps.push(['SKIPPED', allOrganizationsData.data.skippedSteps || 0]);
          this.steps.push(['SUGGESTED', allOrganizationsData.data.suggestedSteps || 0]);
          this.steps.push(['PERFORMANCE_FAILURE', allOrganizationsData.data.performanceFailure || 0]);
          this.getOrganizationsData(period);
        });
    },
    editDigitalProduct(id, name) {
      this.currentlyEditingDigitalProductID = id;
      this.currentlyEditingDigitalProductName = name;
      this.showEditDigitalProductModal = true;
    },
    async updateOrganizations() {
      this.organizationsWithDataEven = [];
      this.organizationsWithDataOdd = [];
      await axios.get('/.netlify/functions/get-organizations').then(data => {
        this.organizations = data.data;
      });
      if (this.organizations.length % 2 == 0 && this.isAdmin) {
        this.isLeftVisible = true;
        this.isRightVisible = false;
      } else if (this.organizations.length % 2 != 0 && this.isAdmin) {
        this.isRightVisible = true;
        this.isLeftVisible = false;
      }
      const requests = [];
      for (let i = 0; i < this.organizations.length; i++) {
        requests.push(axios.post('/.netlify/functions/get-statistics-digital-products', {
          organizationName: this.organizations[i].name
        }));
      }
      await Promise.all(requests).then(requests => {
        this.handleRequestData(requests);
      });
    },

    handleRequestData(requestData) {
      for (let i = 0; i < requestData.length; i++) {
        const organizationData = requestData[i];
        if (i % 2 == 0) {
          this.organizationsWithDataOdd.push({
            name: this.organizations[i].name,
            id: this.organizations[i].id,
            providers: this.organizations[i].providers,
            features: [
              ['PASSED', organizationData.passedBuckets || 0],
              ['FAILED', organizationData.failedBuckets || 0]
            ],
            scenarios: [
              ['PASSED', organizationData.passedScenarios || 0],
              ['FAILED', organizationData.failedScenarios || 0]
            ],
            steps: [
              ['PASSED', organizationData.passedSteps || 0],
              ['FAILED', organizationData.failedSteps || 0],
              ['SKIPPED', organizationData.skippedSteps || 0],
              ['SUGGESTED', organizationData.suggestedSteps || 0],
              ['PERFORMANCE_FAILURE', organizationData.performanceFailure || 0]
            ]
          });
        } else {
          this.organizationsWithDataEven.push({
            name: this.organizations[i].name,
            id: this.organizations[i].id,
            providers: this.organizations[i].providers,
            features: [
              ['PASSED', organizationData.passedBuckets || 0],
              ['FAILED', organizationData.failedBuckets || 0]
            ],
            scenarios: [
              ['PASSED', organizationData.passedScenarios || 0],
              ['FAILED', organizationData.failedScenarios || 0]
            ],
            steps: [
              ['PASSED', organizationData.passedSteps || 0],
              ['FAILED', organizationData.failedSteps || 0],
              ['SKIPPED', organizationData.skippedSteps || 0],
              ['SUGGESTED', organizationData.suggestedSteps || 0],
              ['PERFORMANCE_FAILURE', organizationData.performanceFailure || 0]
            ]
          });
        }
      }
    },
    organizationUpdated(name, id) {
      let organizationIndex = this.organizationsWithDataOdd.findIndex(o => o.id == id);

      if (organizationIndex == -1) {
        organizationIndex = this.organizationsWithDataEven.findIndex(o => o.id == id);

        this.organizationsWithDataEven[organizationIndex]['name'] = name;
      } else {
        this.organizationsWithDataOdd[organizationIndex] ['name'] = name;
      }
    },
    navigate(organizationName) {
      const route = `/organizations/${organizationName}/digital-products`;
      if (event.button == 0) this.$router.push(route);
      else if (event.button == 1) window.open(route);
    }
  }
};
