<template>
  <div class="progress-bar">
    <div class="boxes">
      <div
        v-if="!userHasOneOrganization"
        class="progress-bar-clickable"
        @click="navigate('organizations')"
      >
        {{ title }}
      </div>
      <img
        v-if="!userHasOneOrganization"
        src="/assets/images/progress-bar-angle-right.svg"
      />
      <div
        v-if="!userHasOneDigitalProduct && organizationName"
        :class="{
          'progress-bar-clickable': digitalProductName,
          'progress-bar-not-clickable': !digitalProductName,
        }"
        @click="navigate('digitalProducts')"
      >
        {{ organizationName }}
      </div>
      <img
        v-if="!userHasOneDigitalProduct && digitalProductName"
        src="/assets/images/progress-bar-angle-right.svg"
      />
      <div
        v-if="!userHasOneEnvironment && digitalProductName"
        :class="{
          'progress-bar-clickable': environmentName,
          'progress-bar-not-clickable': !environmentName,
        }"
        @click="navigate('environments')"
      >
        {{ digitalProductName }}
      </div>
      <img
        v-if="!userHasOneEnvironment && environmentName"
        src="/assets/images/progress-bar-angle-right.svg"
      />
      <div
        v-if="environmentName"
        :class="{ disabled: !bucketName }"
        class="progress-bar-clickable"
        @click="navigate('buckets')"
      >
        {{ environmentName }}
      </div>
      <img
        v-if="!userHasOneBucket && bucketName"
        src="/assets/images/progress-bar-angle-right.svg"
      />
      <div
        v-if="bucketName"
        :class="{ disabled: !scenarioName }"
        class="progress-bar-clickable"
        @click="navigate('scenarios')"
      >
        {{ bucketName }}
      </div>
      <img
        v-if="scenarioName"
        src="/assets/images/progress-bar-angle-right.svg"
      />
      <div
        v-if="scenarioName"
        :class="{ disabled: !reportId }"
        class="progress-bar-clickable"
        @click="navigate('reports')"
      >
        {{ scenarioName }}
      </div>
      <img
        v-if="reportId"
        src="/assets/images/progress-bar-angle-right.svg"
      />
      <div
        v-if="reportId"
        :class="{ disabled: !featureId }"
        class="progress-bar-clickable"
        @click="navigate('reports')"
      >
        {{ reportId }}
      </div>
      <img
        v-if="featureId"
        src="/assets/images/progress-bar-angle-right.svg"
      />
      <div v-if="featureId" class="progress-bar-not-clickable">
        {{ featureId }}
      </div>
    </div>
    <div class="additional-boxes">
      <div v-if="result && result === 'failed'" class="box failed-box">
        {{ crumBarFailed }}
      </div>
      <div v-if="result && result === 'passed'" class="box passed-box">
        {{ crumBarPassed }}
      </div>
      <a v-if="url" :href="url" target="_blank">
        <div class="box link-box">
          {{ crumBarLink }}
        </div>
      </a>
    </div>
  </div>
</template>

<script src="./progressBar.js"></script>

<style lang="scss" scoped src="./progressBar.scss"></style>
