import flowChart from 'js-yaml-loader!../../../../content/flowchart.yaml';
import homePageYaml from 'js-yaml-loader!../../../../content/home.yaml';
import {changeChartTextMixin} from '../../mixins/changeChartTextMixin';

export default {
  name: 'FlowChart',
  mixins: [changeChartTextMixin],
  data: function () {
    return {
      image: homePageYaml.architecture.image,
    };
  },
  mounted() {
    this.changeText('title', flowChart.chart.title);
    this.changeText('repeater', flowChart.chart.repeater.bold);
    this.changeText('repeater-heart', flowChart.chart.repeater.small);
    this.changeText('other', flowChart.chart.other.bold);
    this.changeText('other-heart', flowChart.chart.other.small);
    this.changeText('scheduler', flowChart.chart.scheduler.bold);
    this.changeText('runner', flowChart.chart.runner.bold);
    this.changeText(
      'function',
      flowChart.chart.puppeteer.connections.functions.bold
    );
    this.changeText(
      'provider',
      flowChart.chart.puppeteer.connections.functions.provider
    );
    this.changeText(
      'raspberry',
      flowChart.chart.puppeteer.connections.raspberrypi.bold
    );
    this.changeText(
      'kubernetes',
      flowChart.chart.puppeteer.connections.kubernetes.bold
    );
    this.changeText(
      'gaming',
      flowChart.chart.puppeteer.connections.gaming.bold
    );
    this.changeText('puppeteer', flowChart.chart.puppeteer.bold);
    this.changeText('ta-git', flowChart.chart.puppeteer.firstdash);
    this.changeText('schedule', flowChart.chart.puppeteer.seconddash);
    this.changeText('features-1', flowChart.chart.puppeteer.thirddash.firstrow);
    this.changeText(
      'features-2',
      flowChart.chart.puppeteer.thirddash.secondrow
    );
    this.changeText('reporter', flowChart.chart.reporter.bold);
    this.changeText('selenium', flowChart.chart.selenium.bold);

    this.changeText('auxta-live', flowChart.chart.reporter.small);
    this.changeText('frontend', flowChart.chart.reporter.middle);
    this.changeText('notifications', flowChart.chart.notifications.bold);
    this.changeText('database', flowChart.chart.database.bold);
    this.changeText('app', flowChart.chart.app.bold);
    this.changeText('channels', flowChart.chart.channels.bold);
    this.changeText('channels-1', flowChart.chart.channels.firstrow);
    this.changeText('channels-2', flowChart.chart.channels.secondrow);
  },
};
