<template>
  <div class="team-container">
    <div class="title">
      <h1>{{ team.title }}</h1>
    </div>
    <div class="team">
      <div v-for="(individual, i) of team.people" :key="i" class="person">
        <div class="portrait">
          <img :src="individual.image"/>
        </div>
        <h1>{{ individual.person }}</h1>
        <h2>{{ individual.info }}</h2>
        <div class="contact">
          <img :src="team.location"/>
          <h3>{{ individual.location }}</h3>
        </div>
        <div class="contact">
          <img :src="team.mail"/>
          <h3>{{ individual.mail }}</h3>
        </div>
        <div class="contact">
          <img :src="team.phone"/>
          <h4>{{ individual.phone }}</h4>
        </div>
        <div class="contact">
          <img :src="team.linkedin"/>
          <h5>{{ individual.linkedin }}</h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./team.js"></script>

<style lang="scss" src="./team.scss"></style>
