import currentReportHeaderBoxesYaml from 'js-yaml-loader!../../../../content/current-report.yaml';
import {dateHelperMixin} from '../../mixins/dateHelperMixin'

export default {
  name: 'CurrentScenarioHeaderBox',
  mixins: [dateHelperMixin],
  props: {
    report: Object,
  },
  data: function () {
    return {
      headerBoxes: currentReportHeaderBoxesYaml,
      passedOrFailedReport: '',
      duration: this.report.duration
    };
  },
  methods: {
    transformDuration() {
      const seconds = this.report.duration / 1000;
      const minutes = parseInt(seconds / 60);
      return `${minutes}m ${seconds.toFixed(2)}s`
    }
  }
};
