import {useToast} from 'primevue/usetoast';

export const toastHelperMixin = {
  data: function () {
    return {
      toast: useToast()
    }
  },
  methods: {
    /**
     * Show a toast message
     * @param {string} message The message on the toast
     * @param {string} type Eg. 'danger' or 'success' (optional)
     */
    showToast(message, type) {
      this.toast.add({
        life: 2000,
        detail: message,
        position: 'top-center',
        severity: type ? `${type}` : 'info',
      });
    },
    showGenericErrorToast() {
      this.toast.add({
        life: 2000,
        detail: 'A problem occurred. Please, try again later.',
        position: 'top-center',
        severity: 'error'
      });
    }
  }
}
