<template>
  <div class="whom-container">
    <div class="title">
      <h1>{{ forWhom.title }}</h1>
    </div>
    <div class="cards">
      <div v-for="(card, i) of forWhom.cards" :key="i" class="for-whom">
        <div class="whom-card">
          <div class="text">
            <h1>{{ card.text }}</h1>
            <h5>{{ card.target }}</h5>
          </div>
        </div>
        <div class="additional">
          <h5>{{ card.additional }}</h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./forWhom.js"></script>

<style lang="scss" src="./forWhom.scss"></style>
