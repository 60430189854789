<template>
  <div class="registration-page-container">
    <div class="registration-page-content">
      <div
        :style="{ background: `url(${leftSideBackgroundImage}) no-repeat` }"
        class="registration-page-left"
      >
        <div class="auxta-logo">
          <HeaderLogo :logo-color="'white'"/>
        </div>
        <div class="title">
          {{ title }}
        </div>
        <div class="text page-white-text">
          {{ text }}
        </div>
        <div class="phone page-white-text">
          <img :src="phoneIcon" class="data-icon"/>{{ phoneNumber }}
        </div>
        <div class="mail page-white-text">
          <img :src="mailIcon" class="data-icon"/>{{ mail }}
        </div>
        <div class="bottom-suggestion">
          <div class="suggestion-text">
            {{ alreadyHaveAnAccountQuestion }}
          </div>
          <a class="suggestion-link" @click="toLogin()">{{
              alreadyHaveAnAccountLink
            }}</a>
        </div>
      </div>
      <div class="registration-page-right">
        <TopNavigationSmall/>
      </div>
    </div>
    <FooterComponent/>
  </div>
</template>

<script src="./registration.js"></script>

<style lang="scss" scoped src="./registration.scss"></style>
