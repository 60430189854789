<template>
  <p-dialog :aria-modal="null"
            :breakpoints="{'2000px': '40vw', '1060px': '60vw', '640px': '80vw'}"
            :modal=true
            :style="{width: '40vw'}"
            :visible="show"
            @update:visible="$emit('on-close')">
    <template #header>
      <h3>Add new environment</h3>
    </template>
    <div class="modal-card">
      <section class="modal-card-body">
        <!-- ------------------------------------------------------- -->
        <VeeForm v-slot="{handleSubmit}" :validation-schema="schema" as="div">
          <form id="a-form" @submit="handleSubmit($event,inviteUserToEnvironment())">
            <Field v-slot="{ field, errorMessage }" name="envName">
              <div class="input-field">
                <label>Environment name:</label>
                <span class="p-input-icon-left">
                  <i class="pi pi-building"/>
                  <p-input v-model="environmentName"
                           :class="{ 'p-invalid': errorMessage }"
                           placeholder="Enviroment name"
                           v-bind="field"
                  />
                </span>
                <small class="p-error">{{ errorMessage }}</small>
              </div>
            </Field>
            <!-- ------------------------------------------------------- -->
            <Field v-slot="{ field, errorMessage }" name="envUrl">
              <div class="input-field">
                <label>Environment URL:</label>
                <span class="p-input-icon-left">
              <i class="pi pi-building"/>
              <p-input v-model="environmentUrl"
                       :class="{ 'p-invalid': errorMessage }"
                       placeholder="Environment URL"
                       v-bind="field"/>
            </span>
                <small class="p-error">{{ errorMessage }}</small>

              </div>
            </Field>
            <!-- ------------------------------------------------------- -->
            <Field v-slot="{ field, errorMessage }" name="envLiveBranch">
              <div class="input-field">
                <label>Environment Live Branch:</label>
                <span class="p-input-icon-left">
              <i class="pi pi-building"/>
              <p-input v-model="environmentLiveBranch"
                       :class="{ 'p-invalid': errorMessage }"
                       placeholder="Environment live branch"
                       v-bind="field"
              />
            </span>
                <small class="p-error">{{ errorMessage }}</small>
              </div>
            </Field>
            <!-- ----------------------------------------------------- -->
            <Field v-slot="{field, errorMessage, handleChange}" name="environmentType">
              <div class="input-field select-field">
                <h5>Enviroment flag:</h5>
                <p-select
                  v-model="environmenType"
                  :class="{ 'p-invalid': errorMessage }"
                  :model-value="field"
                  :options="typeRoles"
                  optionLabel="label"
                  optionValue="value"
                  placeholder="Select role"
                  @update:model-value="handleChange"
                >
                </p-select>
                <small class="p-error">
                  {{ errorMessage }}</small>
              </div>
            </Field>
            <!-- ------------------------------------------------------- -->
            <Field v-slot="{ field, errorMessage }" name="adminName">
              <div class="input-field">
                <label>Administrator name:</label>
                <span class="p-input-icon-left">
              <i class="pi pi-building"/>
              <p-input v-model="environmentAdminName"
                       :class="{ 'p-invalid': errorMessage }"
                       placeholder="Administrator name"
                       v-bind="field"
              />
            </span>
                <small class="p-error">{{ errorMessage }}</small>
              </div>
            </Field>
            <!-- ------------------------------------------------------- -->
            <Field v-slot="{ field, errorMessage }" name="adminEmail">
              <div class="input-field">
                <label>Administrator email:</label>
                <span class="p-input-icon-left">
              <i class="pi pi-building"/>
              <p-input v-model="environmentAdminEmail"
                       :class="{ 'p-invalid': errorMessage }"
                       placeholder="Administrator email"
                       v-bind="field"
              />
            </span>
                <small class="p-error">{{ errorMessage }}</small>
              </div>
            </Field>
            <!-- ------------------------------------------------------- -->
          </form>
        </VeeForm>
      </section>
    </div>
    <template #footer>
      <div class="submit-btn">
        <p-button
          :loading="disableButton"
          form="a-form"
          type="submit"
        >
          <span>Done</span></p-button
        >
      </div>
    </template>
  </p-dialog>
</template>

<script src="./addEnvironmentDialog.js"></script>

<style lang="scss" scoped src="./addEnvironmentDialog.scss"></style>
