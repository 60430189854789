import manualReportsPageYaml from 'js-yaml-loader!../../../../content/manualReports.yaml';
import axios from '@/axios';

export default {
  name: 'ManualReports',
  data() {
    return {
      Periodicals: [],
      showAddPeriodical: false,
      showPeriodical: {},
      manualReportsPageYaml: manualReportsPageYaml,
      organizations: [],
      organizationId: undefined,
      organizationName: undefined,
      digitalProducts: [],
      digitalProductId: undefined,
      digitalProductName: undefined,
      responses: [false, false],
    };
  },
  async created() {
    await axios.get('/.netlify/functions/get-organizations').then(data => {
      this.organizations = data.data;
    });
  },
  methods: {
    async updateOrganization(organizationName, organizationId = undefined) {
      this.Periodicals = [];
      if (organizationName) {
        this.organizationName = organizationName;
      } else {
        this.organizationName = this.organizations.find((element) => element.id === organizationId).name;
        organizationName = this.organizationName;
      }
      if (!this.organizationId) {
        this.organizationId = this.organizations.find((element) => element.name === organizationName).id;
      }
      await axios.post('/.netlify/functions/get-digital-products', {
        organizationName: organizationName
      }).then(async data => {
        this.digitalProducts = data.data;
        if(this.digitalProducts.length == 1){
          this.digitalProductName = this.digitalProducts[0].name
          this.updateDigitalProduct(this.digitalProductName);
        }
        this.responses[1] = true;
        });

    },
    async updateDigitalProduct(digitalProductName, digitalProductId = undefined) {
      this.Periodicals = [];
      if (digitalProductName) {
        this.digitalProductName = digitalProductName;
      } else {
        this.digitalProductName = this.digitalProducts.find((element) => element.id === digitalProductId).name;
        digitalProductName = this.digitalProductName;
      }
      if (!this.digitalProductId) {
        this.digitalProductId = this.digitalProducts.find((element) => element.name === digitalProductName).id;
      }
      this.responses[2] = true;
      this.getPeriodicals(this.organizationName, digitalProductName);
    },
    async getPeriodicals(organizationName, digitalProductName){
      await axios
        .get('/.netlify/functions/get-periodicals', {
          organizationName: organizationName,
          digitalProductName: digitalProductName,
        })
        .then((res) => {
          res.data.forEach(p => {
            this.Periodicals.push(p)
          });
        });
    },
    async addPeriodical(organizationName, digitalProductName) {
      const name = document.getElementById('periodicalName').value;
      if (this.name != '') {
        this.showAddPeriodical = false;
        const response = await axios.post('/.netlify/functions/create-periodical', {
          name: name,
          key: name,
          organizationName: organizationName,
          digitalProductName: digitalProductName,
          reports: []
        })
        this.Periodicals.push(response.data.newPeriodical);
      }
    },
    open(organization, digitalProduct, name, periodicalId) {
      this.$router.push(`/reports/${organization}/${digitalProduct}/${name}/${periodicalId}`);
    },
    togglePeriodical(key) {
      this.showPeriodical[key] = !this.showPeriodical[key];
    },
  },
};
