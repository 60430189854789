<template>
  <div class="page-wrapper" :key="name">
    <h1 class="header">{{ name }}</h1>
    <div class="data">
        <div class="reportsPage" :key="name">
          <button class="addReport" @click="showAddReport = !showAddReport">
            + Report
          </button>
          <div class="addReportPopUp" v-if="showAddReport">
            <input type="text" placeholder="Name" id="reportName" />
            <div class="p-dropdown date-picker">
                <vue-date-picker 
                    v-model="dateFrom" 
                    :clearable="true" 
                    :enable-time-picker="false" 
                    :max-date="new Date()" 
                    placeholder="Date from:"
                    @update:model-value="updateStartDate(dateFrom)">
                </vue-date-picker>
            </div>
            <div class="p-dropdown date-picker">
                <vue-date-picker 
                    v-model="dateTo" 
                    :clearable="true" 
                    :enable-time-picker="false" 
                    :max-date="new Date()" 
                    placeholder="Date to: "
                    @update:model-value="updateEndDate(dateTo)">
                </vue-date-picker>
            </div>
            <button class="add" @click="addReport()">Add Report</button>
          </div>
          <div class="reports" :key="name">
            <div class="report" v-for="report in reports" :key="report.key">
              <button class="open" @click="toggleReport(report)">
                <div class="btn" >
                    <span>
                        {{ report.name }}
                    </span>
                    <span>
                        {{ formatDate(report.dateFrom) }} - {{ formatDate(report.dateTo) }}
                    </span>
                </div>
              </button>
              <div class="expanded" v-if="showReport[report.key]">
                <div class="fields">
                    <p class="title" style="margin-top: 0;">Speed and quantity</p>
                    <span class="item">
                        <div class="dot">
                            <p class="color" :style="{backgroundColor: fieldColors.completedItems}"></p>
                            Items completed this period:
                        </div>
                        <input type="number" v-model.number="report.values.completedItems" />
                    </span>
                    <span class="item" >
                        <div class="dot">
                            <p class="color" :style="{backgroundColor: fieldColors.automatableItems}"></p>
                            Automatable items this period:
                        </div>
                        <input type="number" v-model.number="report.values.automatableItems" />
                    </span>
                    <span class="item">
                        <div class="dot">
                            <p class="color" :style="{backgroundColor: fieldColors.automatedItems}"></p>
                            Automated items this period:
                        </div>
                      <input type="number" v-model.number="report.values.automatedItems" />
                    </span>
                    <span class="item">Not automated items this period:
                        <span>{{ report.values.notAutomatedItems }}</span>
                    </span>
                    <p class="line"></p>
                    <span class="item">All completed items until this period:
                      <span>{{ report.values.completedItemsUntilThisPeriod }}</span>
                    </span>
                    <span class="item">All automatable items until this period:
                      <span>{{ report.values.automatableItemsUntilThisPeriod }}</span>
                    </span>
                    <span class="item">All automated items until this period:
                      <span>{{ report.values.automatedItemsUntilThisPeriod}}</span>
                    </span>
                    <span class="item">All not automated items until this period:
                      <span>{{ report.values.notAutomatedItemsUntilThisPeriod }}</span>
                    </span>
                    <span class="item" v-if="report.totalTimeTestRun > 0">Total time test run this period:
                      <span>{{ convertSecondsToTime(report.totalTimeTestRun) }}</span>
                    </span>
                    <p class="title">Quality</p>
                    <span class="item">
                        <div class="dot">
                            <p class="color" :style="{backgroundColor: fieldColors.bugsByDevelopers}"></p>
                            Bugs found by developers:
                        </div>
                      <input type="number" v-model.number="report.values.bugsByDevelopers" />
                    </span>
                    <span class="item">
                        <div class="dot">
                            <p class="color" :style="{backgroundColor: fieldColors.bugsByTA}"></p>
                            Bugs found by TA:
                        </div>
                      <input type="number" v-model.number="report.values.bugsByTA" />
                    </span>
                    <span class="item">
                        <div class="dot">
                            <p class="color" :style="{backgroundColor: fieldColors.bugsByBusiness}"></p>
                            Bugs found by business team:
                        </div>
                      <input type="number" v-model.number="report.values.bugsByBusiness" />
                    </span>
                    <span class="item">
                        <div class="dot">
                            <p class="color" :style="{backgroundColor: fieldColors.bugsByEndUser}"></p>
                            Bugs found by end user:
                        </div>
                      <input type="number" v-model.number="report.values.bugsByEndUser" />
                    </span>
                    <span class="item">Cost of bugs this period:
                      <span>{{ report.costOfBugs }} &euro;</span>
                    </span>
                    <p class="title">Time and efficiency</p>
                    <span class="item">
                        <div class="dot">
                            <p class="color" :style="{backgroundColor: fieldColors.hoursForDevelopment}"></p>
                            Hours for development time:
                        </div>
                      <input type="number" v-model.number="report.values.hoursForDevelopment" />
                    </span>
                    <span class="item">
                      <div class="dot">
                          <p class="color" :style="{backgroundColor: fieldColors.hoursForTA}"></p>
                          Hours for TA:
                      </div>
                      <input type="number" v-model.number="report.values.hoursForTA" />
                    </span>
                    <span class="item">Hours for manual testing:
                      <span>{{ report.values.hoursForManualTesting }}</span>
                    </span>
                    <p class="title">Description</p>
                    <TextArea v-model="report.description" autoResize rows="5" cols="30" style="padding: 10px"></TextArea>
                </div>
                <div class="btn">
                  <button class="add" @click="saveReportValues(report)">Save</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="charts">
            <div class="chart1" ref="chart1" :key="name"></div>
            <div class="chart2" ref="chart2" :key="name"></div>
            <div class="chart3" ref="chart3" :key="name"></div>
        </div>

      </div>
    </div>
</template>

<script src="./reportsCharts.js"></script>

<style lang="scss" scoped src="./reportsCharts.scss"></style>
