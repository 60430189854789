import {arc as d3arc, pie as d3pie, select as d3select} from 'd3'
import {v4 as uuidv4} from 'uuid'

export default {
  name: 'GooglePieChart',
  props: {
    data: Array,
    backgroundColor: String,
    d: Number,
  },
  data: function () {
    return {
      id: this.randomId()
    }
  },
  watch: {
    data: {
      handler() {
        this.initCharts()
      },
      deep: true,
    }
  },
  mounted() {
    this.initCharts()
  },
  methods: {
    randomId() {
      return uuidv4({
        random: Array.from({length: 16}, () =>
          Math.floor(Math.random() * 255)
        )
      })
    },
    removeOld() {
      const el = document.querySelector(`#id-${this.id}`)
      if (el) {
        if (el.firstChild) el.removeChild(el.firstChild)
        const tooltipEl = document.querySelector(`#tooltip-${this.id}`)
        if (tooltipEl.firstChild) tooltipEl.removeChild(tooltipEl.firstChild)
      }
    },
    getPercentages() {
      if (this.data) {
        let total = 0
        for (const d of this.data)
          total += d[1]

        if (total > 0) {
          let output = []
          for (let i = 0; i < this.data.length; i++) {
            const d = this.data[i]
            let percentage = d[1] / total * 100
            const originalPercentage = Math.round(percentage * 100) / 100
            if (percentage > 0 && percentage < 0.75) percentage = 0.75
            let o = [...this.data[i], percentage, originalPercentage]
            switch (this.data[i][0]) {
              case 'PASSED':
                o.push('#9EE493')
                break
              case 'FAILED':
                o.push('#EE6352')
                break
              case 'SKIPPED':
                o.push('#ffc219')
                break
              case 'SUGGESTED':
                o.push('#3273dc')
                break
              case 'PERFORMANCE_FAILURE':
                o.push('#710193')
                break
            }
            output.push(o)
          }

          return output
        }
      }
      return [['NO DATA', 0, 1, 0, '#c5c5c5']]
    },
    drawChart() {
      this.removeOld()

      // Create dummy data
      let percentages = this.getPercentages()

      const svg = d3select(`#id-${this.id}`)
        .append('svg')
        .attr('width', this.d)
        .attr('height', this.d)
        .append('g')
        .attr('transform', `translate(${this.d / 2}, ${this.d / 2})`)

      const div = d3select(`#tooltip-${this.id}`)
        .append('div')
        .attr('class', 'tooltip')
        .style('opacity', 0)

      // Compute the position of each group on the pie:
      const pie = d3pie()
        .startAngle(3 * (Math.PI / 4))
        .endAngle(Math.PI * 2 + 3 * (Math.PI / 4))
        .value(function (d) {
          return d[2]
        })
      const data_ready = pie(percentages)

      // Build the pie chart: Basically, each part of the pie is a path that we build using the arc function.
      svg.selectAll('w')
        .data(data_ready)
        .join('path')
        .attr('d', d3arc()
          .innerRadius(0)
          .outerRadius(this.d / 2)
        )
        .attr('fill', function (d) {
          return d.data[4]
        })
        .style('opacity', 1)
        .on('mousemove', function (event, data) {
          div.transition()
            .duration(0)
            .style('opacity', .9)
          div.html(`${data.data[0]}<br><p>${data.data[1]} (${data.data[3]}%)</p>`)
            .style('left', (event.clientX) + 'px')
            .style('top', (event.clientY + window.scrollY - 32) + 'px')
        })
        .on('mouseout', function () {
          div.transition()
            .duration(0)
            .style('opacity', 0)
        })
    },
    initCharts() {
      this.drawChart()
    },
  },
}
