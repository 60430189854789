export const changeChartTextMixin = {
  methods: {
    changeText(textid, text) {
      var a = document.getElementById('chart');
      a.addEventListener(
        'load',
        function () {
          // Gets the content of the SVG
          var svgDoc = a.contentDocument;
          // Gets one of the SVG items by ID;
          var svgItem = svgDoc.getElementById(textid);
          // Sets the text to something else
          svgItem.textContent = text;
        },
        false
      );
    },
  }
}
