<template>
  <div class="implementation-container">
    <div class="title">
      <h1>{{ implementation.title }}</h1>
    </div>
    <div class="lines">
      <div class="blue-table">
        <div class="blue line">
          <h4>{{ blueSections.we }}</h4>
        </div>
        <div class="blue-rows">
          <div
            v-for="(section, i) of blueSections.sections"
            :key="'blue' + i"
            class="column-list"
          >
            <div class="container blue">
              <h1>{{ section.section }}</h1>
            </div>
            <ul class="lister">
              <li v-for="(item, t) of section.list" :key="t">
                {{ item.item }}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="gray-table">
        <div class="gray line">
          <h4>{{ graySections.you }}</h4>
        </div>
        <div
          v-for="(section, i) of graySections.sections"
          :key="'gray' + i"
          class="column-list"
        >
          <div class="container gray">
            <h1>{{ section.section }}</h1>
          </div>
          <ul class="lister">
            <li v-for="(item, t) of section.list" :key="t">
              {{ item.item }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./implementationModel.js"></script>

<style lang="scss" src="./implementationModel.scss"></style>
