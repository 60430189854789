import DomainTagList from '../../domainTagList/domainTagList.vue';
import axios from '../../../../axios';
import {toastHelperMixin} from '../../../mixins/toastHelperMixin';

export default {
  name: 'EditOrganizationModal',
  mixins: [toastHelperMixin],
  components: {
    DomainTagList,
  },
  props: {
    id: String,
    name: String,
  },
  data: function () {
    return {
      loading: true,
      show: true,
      domainNameInput: '',
      saveDataRequestRunning: false,
      organizationName: '',
      allowedDomains: [],
    };
  },
  created: async function () {
    try {
      this.allowedDomains = (
        await axios.get(
          `/.netlify/functions/get-organization-domains?organizationId=${this.id}`
        )
      ).data;
      this.organizationName = this.name;
      this.loading = false;
    } catch (error) {
      this.showGenericErrorToast();
    }
  },
  methods: {
    async saveData() {
      if (!this.organizationName) {
        this.showToast('Invalid organization name.', 'error');
        return;
      }
      if (!this.allowedDomains.length) {
        this.showToast(
          'You must specify at least one allowed domain.',
          'error'
        );
        return;
      }
      this.saveDataRequestRunning = true;
      let res = await this.updateData().then(res => {
        this.$emit('organizationUpdate', this.organizationName, this.id);
        return res
      }).catch((err) => {
        this.showToast(err.response.data.message, 'error')
        return err
      });
      if (res.status == 200) {
        this.showToast('Organization updated.', 'success');
        this.$emit('on-close');
        this.saveDataRequestRunning = false;

      } else {
        this.saveDataRequestRunning = false;
        return
      }
    },
    removeDomain(domainName) {
      this.allowedDomains = this.allowedDomains.filter((d) => d !== domainName);
    },
    addDomain() {
      if (this.domainNameInput.startsWith('www.')) {
        this.domainNameInput = this.domainNameInput.replace(/www./g, '');
      }
      if (this.allowedDomains.some((d) => d === this.domainNameInput)) {
        this.showToast('This domain is already in the list!', 'error');
        return;
      }
      this.allowedDomains = [...this.allowedDomains, this.domainNameInput];
      this.domainNameInput = '';
    },
    async updateData() {
      return axios.post('/.netlify/functions/update-organization', {
        organizationId: this.id,
        name: this.organizationName,
        domains: this.allowedDomains,
      })
    },
  },
};
