import axios from '../../../../axios';
import {toastHelperMixin} from '@/app/mixins/toastHelperMixin';

export default {
  name: 'EditDigitalProductModal',
  mixins: [toastHelperMixin],
  props: {
    id: String,
    name: String,
    token: String
  },
  data: function () {
    return {
      loading: true,
      show: true,
      domainNameInput: '',
      saveDataRequestRunning: false,
      digitalProductName: '',
      environmentRepositoryHostingService: '',
      environmentRepositoryURL: '',
    };
  },
  created: async function () {
    try {
      const digitalProductData = (
        await axios.get(
          `/.netlify/functions/get-digital-product?digitalProductId=${this.id}`
        )
      ).data;
      this.digitalProductName = this.name;
      this.environmentRepositoryHostingService = digitalProductData.repositoryHostingService;
      this.environmentRepositoryURL = digitalProductData.repositoryLocation;
      this.loading = false;
    } catch (error) {
      this.showGenericErrorToast();
    }
  },
  methods: {
    async saveData() {
      if (!this.digitalProductName) {
        this.showToast('Invalid digital product name.', 'error');
        return;
      }
      this.saveDataRequestRunning = true;

      let res = await this.updateData().then(res => {
        this.$emit('digitalProductUpdate', this.digitalProductName, this.id)
        return res
      }).catch(err => {
        this.showToast(err.response.data.message, 'error')
        return err
      });
      if (res.status == 200) {
        this.showToast('Digital product updated.', 'success');
        this.$emit('on-close');
        this.saveDataRequestRunning = false;
      } else {
        this.saveDataRequestRunning = false;
      }
    },
    async updateData() {
      return axios.post('/.netlify/functions/update-digital-product', {
        digitalProductId: this.id,
        name: this.digitalProductName,
      })
    },
  },
};
