import axios from '../../../axios';
import CurrentScenarioHeaderBox from '../../components/currentScenarioHeaderBox/currentScenarioHeaderBox.vue';
import ProgressBar from '../../components/progressBar/progressBar.vue';
import CurrentScenarioAccordion from '../../components/currentScenarioAccordion/currentScenarioAccordion.vue';

export default {
  name: 'CurrentScenario',
  components: {
    CurrentScenarioHeaderBox,
    ProgressBar,
    CurrentScenarioAccordion
  },
  data: function() {
    return {
      scenarios: [],
      steps: [],
      feature: {},
      passedScenarios: 0,
      failedScenarios: 0,
      skippedSteps: 0,
      suggestedSteps: 0,
      performanceFailure: 0,
      failedSteps: 0,
      perfomanceFailedDirCount: -1,
      failedDirCount: -1,
      errorStepIndex: 0,
      isPageOnTop: true,
      duration: 0,
      // todo why the report is an array? - mm
      report: {},
      reportId: this.$route.params['report_id'],
      scenarioName: this.$route.params['scenario_name'],
      reportHeaderBoxes: {},
      didResponseCome: false
    };
  },
  async created() {
    if (localStorage.getItem('token')) {
      await axios.post('/.netlify/functions/get-scenarios', {
          reportId: this.reportId
        }).then((data) => {
          this.didResponseCome = true;
          this.scenarios = data.data.scenarios;
        });
      await axios.post('/.netlify/functions/get-report', {
          reportId: this.reportId
        })
        .then((data) => {
          this.didResponseCome = true;
          this.report = data.data;
          this.report.features.map((val) => {
            this.feature = val;
          });
          this.scenarios.map(async (el) => {
            if (el.status == 'passed') {
              this.passedScenarios++;
            } else this.failedScenarios++;

            await axios.post('/.netlify/functions/get-steps', { scenarioId: el.stepRef }).then(({ data }) => {
                for (const dataStep of data.steps) {
                  switch (dataStep.status) {
                    case 'skipped':
                      this.skippedSteps++;
                      break;
                    case 'suggested':
                      this.suggestedSteps++;
                      break;
                    case 'failed':
                      this.failedSteps++;
                      break;
                    case 'performanceFailure':
                      this.performanceFailure++;
                      break;
                  }
                  this.duration += dataStep.duration;
                  this.steps = data.steps;
                }
              });
            this.makeReportHeaderBoxes();
            this.errorStep();
          });
        });
    }
  },
  methods: {
    makeReportHeaderBoxes() {
      this.reportHeaderBoxes = {
        ...this.report,
        passedScenarios: this.passedScenarios,
        failedScenarios: this.failedScenarios,
        stepsCount: this.feature.stepsCount,
        duration: this.duration,
        skippedSteps: this.skippedSteps,
        suggestedSteps: this.suggestedSteps,
        performanceFailure: this.performanceFailure
      };
    },
    errorStep(){
      for (let i = 0; i < this.steps.length; i++) {
        const step = this.steps[i];
        if (step.status === 'failed') {
          if(step.errorMessage){
            this.errorStepIndex = i + 1;
          }
        }
      }
    },
    scrollUp() {
      this.isPageOnTop = true;
      this.perfomanceFailedDirCount = -1;
      this.failedDirCount = -1;
      window.scrollTo(0, 0);
    },
    scrollToTestEnd(status, direction) {
      let failedStepIndex = [];
      let perfomanceFailedStepIndex = [];
      for (let i = 0; i < this.steps.length; i++) {
        const step = this.steps[i];
        if (step.status === 'failed') {
          failedStepIndex.push(i + 1);
        }
        if (step.status === 'performanceFailure') {
          perfomanceFailedStepIndex.push(i + 1);
        }
      }

      if(status === 'performanceFailed'){
        if (direction === 'up') {
          if (this.perfomanceFailedDirCount > 0) {
            this.perfomanceFailedDirCount -= 1;
          }
        } else if (direction === 'down') {
          if (this.perfomanceFailedDirCount < perfomanceFailedStepIndex.length - 1) {
            this.isPageOnTop = false;
            this.failedDirCount = -1;
            this.perfomanceFailedDirCount += 1;
          }
        }
  
        if (this.perfomanceFailedDirCount >= 0 && this.perfomanceFailedDirCount < perfomanceFailedStepIndex.length >= 1) {
            document.getElementById(perfomanceFailedStepIndex[this.perfomanceFailedDirCount]).scrollIntoView({ behavior: 'smooth' });
        }
      }

      if(status === 'failed'){
        if (direction === 'up') {
          if (this.failedDirCount > 0) {
            this.failedDirCount -= 1;
          }
        } else if (direction === 'down') {
          if (this.failedDirCount < failedStepIndex.length - 1) {
            this.isPageOnTop = false;
            this.perfomanceFailedDirCount = -1;
            this.failedDirCount += 1;
          }
        }
  
        if (this.failedDirCount >= 0 && this.failedDirCount < failedStepIndex.length >= 1) {
            document.getElementById(failedStepIndex[this.failedDirCount]).scrollIntoView({ behavior: 'smooth' });
        }
      }

      if(status === 'error' && direction === 'mid'){
        if(this.errorStepIndex){
          document.getElementById(this.errorStepIndex).scrollIntoView({ behavior: 'smooth' });
        }
      }
    },
    scrollDown() {
      this.isPageOnTop = false;
      window.scrollTo(0, document.body.scrollHeight);
    }
  }
};
