<template>
  <div class="pie-charts">
    <p-select
      v-if="hide"
      v-model="environmentType"
      :options="typeRoles"
      class="type-select"
      optionLabel="label"
      optionValue="value"
      placeholder="Select role"
      @change="updateStatistics(environmentType, periodOfTest)"
    >
    </p-select>
    <p-select
      v-if="hide"
      v-model="periodOfTest"
      :options="period"
      class="type-select"
      optionLabel="label"
      optionValue="value"
      placeholder="Period"
      @change="updateStatistics(environmentType, periodOfTest)"
    >
    </p-select>
    <div v-if="data1">
      <GooglePieChart
        ref="featuresChart"
        :background-color="'transparent'"
        :d="250"
        :data="data1"
        class="pie-chart"
      />
      <div class="pie-chart-text">
        {{ text1 }}
      </div>
    </div>
    <div v-if="data2" class="pie-2">
      <GooglePieChart
        ref="scenariosChart"
        :background-color="'transparent'"
        :d="250"
        :data="data2"
        class="pie-chart"
      />
      <div class="pie-chart-text">
        {{ text2 }}
      </div>
    </div>
    <div v-if="data3" class="pie-3">
      <GooglePieChart
        ref="stepsChart"
        :background-color="'transparent'"
        :d="250"
        :data="data3"
        class="pie-chart"
      />
      <div class="pie-chart-text">
        {{ text3 }}
      </div>
    </div>
  </div>
</template>

<script src="./bigPieCharts.js"></script>

<style lang="scss" scoped src="./bigPieCharts.scss"></style>
