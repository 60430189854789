<template>
  <div v-if="report && headerBoxes.headerBoxes" class="header-boxes-container">
    <div class="header-box header-box-1">
      <div class="header-box-white-text">
        {{ headerBoxes.headerBoxes[0] }}
      </div>
      <div class="header-box-white-content">
        {{ report.passedScenarios }}
      </div>
    </div>
    <div class="header-box header-box-2">
      <div class="header-box-white-text">
        {{ headerBoxes.headerBoxes[1] }}
      </div>
      <div class="header-box-white-content">
        {{ report.failedScenarios }}
      </div>
    </div>
    <div class="header-box header-box-3">
      <div class="header-box-white-text">
        {{ headerBoxes.headerBoxes[2] }}
      </div>
      <div class="header-box-white-content">{{ report.skippedSteps }}</div>
    </div>
    <div class="header-box header-box-4">
      <div class="header-box-white-text">
        {{ headerBoxes.headerBoxes[3] }}
      </div>
      <div class="header-box-white-content">{{ report.suggestedSteps }}</div>
    </div>
    <div class="header-box header-box-grey">
      <div class="header-box-blue-text">
        {{ headerBoxes.headerBoxes[4] }}
      </div>
      <div class="header-box-black-content">
        {{ report.duration }}
      </div>
    </div>
    <div class="header-box header-box-grey header-box-big">
      <div class="header-box-blue-text">
        {{ headerBoxes.headerBoxes[5] }}
      </div>
      <div class="header-box-black-content">
        {{ formatDateTimeFull(report.start) }}
      </div>
    </div>
    <div class="header-box header-box-grey">
      <div class="header-box-blue-text">
        {{ headerBoxes.headerBoxes[6] }}
      </div>
      <div
        :class="{
          'failed-streak': report.result === 'failed',
          'passed-streak': report.result === 'passed',
        }"
        class="header-box-content-no-color"
      >
        {{ report.streak }}
      </div>
    </div>
    <div class="header-box header-box-grey">
      <div class="header-box-blue-text">
        {{ headerBoxes.headerBoxes[7] }}
      </div>
      <div class="header-box-black-content">
        {{ report.stepsCount }}
      </div>
    </div>
  </div>
</template>

<script src="./currentReportHeaderBoxes.js"></script>

<style lang="scss" scoped src="./currentReportHeaderBoxes.scss"></style>
