import navbarsYaml from 'js-yaml-loader!../../../../content/navbars.yaml';
import axios from '../../../axios';
import {localStorageHelperMixin} from '../../mixins/localStorageHelperMixin';
import {toastHelperMixin} from '../../mixins/toastHelperMixin';

export default {
  mixins: [toastHelperMixin, localStorageHelperMixin],
  data: function () {
    return {
      logoutText: '',
      reportsOverview: '',
      manualReports: '',
      userHasOneOrganization: this.localStorageGetItem(
        'userHasOneOrganization'
      ),
      userHasOneDigitalProduct: this.localStorageGetItem(
        'userHasOneDigitalProduct'
      ),
      organizationName: this.$route.params['organization_name'],
      digitalProductName: this.$route.params['digital_product_name'],
      reportId: this.$route.params['report_id']
    };
  },
  created() {
    if (!localStorage.token && this.$route.path !== '/') {
      delete localStorage.token;
      localStorage.setItem('pathAfterLogin', this.$route.path)
      this.$router.push('/login');
    }
  },
  mounted() {
    this.logoutText = navbarsYaml.sideNavbar.logoutText;
    this.reportsOverview = navbarsYaml.sideNavbar.reportsOverview;
    this.manualReports = navbarsYaml.sideNavbar.manualReports;
  },
  methods: {
    async logout() {
      await axios.get('/.netlify/functions/logout').then(() => {
        localStorage.clear();
        localStorage.clear();
        this.$router.push('/login');
      }).catch(() => {
        this.showGenericErrorToast();
      });
    },
    async navigateToReportsOverviewPage() {
      this.$router.push('/reports-overview');
    },
    async navigateToManualReportsPage(){
      this.$router.push('/manual-reports');
    },
    navigateToFirstPage() {
      if (this.userHasOneDigitalProduct) {
        this.$router.push(
          `/organizations/${this.organizationName}/digital-products/${this.digitalProductName}/reports`
        );
      } else if (this.userHasOneOrganization) {
        this.$router.push(
          `/organizations/${this.organizationName}/digital-products`
        );
      } else if (this.$route.path == '/organizations') {
        return
      } else {
        this.$router.push('/organizations');
      }
    }
  }
};
