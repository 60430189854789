import currentReportHeaderBoxesYaml from 'js-yaml-loader!../../../../content/current-report.yaml';
import {dateHelperMixin} from '../../mixins/dateHelperMixin'

export default {
  name: 'CurrentReportHeaderBoxes',
  mixins: [dateHelperMixin],
  props: {
    report: Object,
  },
  data: function () {
    return {
      headerBoxes: currentReportHeaderBoxesYaml,
      passedOrFailedReport: '',
    };
  },
};
