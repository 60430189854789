import companyExistingUserEmailYAML
  from 'js-yaml-loader!../../../content/emails/organizations/invite-existing-user.yaml';
import projectExistingUserEmailYAML
  from 'js-yaml-loader!../../../content/emails/digital-products/invite-existing-user.yaml';
import environmentIdExistingUserEmailYAML
  from 'js-yaml-loader!../../../content/emails/environments/invite-existing-user.yaml';

import companyNewUserEmailYAML from 'js-yaml-loader!../../../content/emails/organizations/invite-new-user.yaml';
import projectNewUserEmailYAML from 'js-yaml-loader!../../../content/emails/digital-products/invite-new-user.yaml'
import environmentIdNewUserEmailYAML from 'js-yaml-loader!../../../content/emails/environments/invite-new-user.yaml'

import forgotPassYaml from 'js-yaml-loader!../../../content/emails/forgot-password-email.yaml';
import tokenYaml from 'js-yaml-loader!../../../content/emails/token-email.yaml';
import emailHTML from 'raw-loader!../../../email-templates/general-email.html';
import tokenEmailHTML from 'raw-loader!../../../email-templates/token-email.html';

export const emailsMixin = {
  methods: {
    /**
     * Based on user and entity we import and return the right email
     * Maybe can be written a little better to replace the mails' content here, not in the backend
     * @param isUserRegistered - boolean - if the user is registered or not
     * @param backendEntity - string - can be company,project or environmentId
     */
    async inviteUserToEntitiyEmailSend(isUserRegistered, backendEntity) {
      if (isUserRegistered) {
        switch (backendEntity) {
          case 'organization': {
            return {emailYaml: companyExistingUserEmailYAML, emailHTML: emailHTML}
          }
          case 'digital-product': {
            return {emailYaml: projectExistingUserEmailYAML, emailHTML: emailHTML}
          }
          case 'environment': {
            return {emailYaml: environmentIdExistingUserEmailYAML, emailHTML: emailHTML}
          }
        }
      } else if (!isUserRegistered) {
        switch (backendEntity) {
          case 'organization': {
            return {emailYaml: companyNewUserEmailYAML, emailHTML: emailHTML}
          }
          case 'digital-product': {
            return {emailYaml: projectNewUserEmailYAML, emailHTML: emailHTML}
          }
          case 'environment': {
            return {emailYaml: environmentIdNewUserEmailYAML, emailHTML: emailHTML}
          }
        }
      }
    },
    async getResetPasswordEmailTemplates() {
      return {emailYaml: forgotPassYaml, emailHTML: emailHTML}
    },
    async getTokenEmailTemplates() {
      return {emailYaml: tokenYaml, emailHTML: tokenEmailHTML}
    }
  }
}
