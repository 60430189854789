import Navigation from '../../components/navigation/navigation.vue';

export default {
  name: 'SidebarLayout',
  components: {
    Navigation,
  },
  data: function () {
    return {};
  },
  async created() {
  },
  async mounted() {
  },
  methods: {},
};
