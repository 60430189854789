import DomainTagList from '../../domainTagList/domainTagList.vue';
import axios from '../../../../axios';
import {toastHelperMixin} from '../../../mixins/toastHelperMixin';

export default {
  name: 'EditEnvironmentModal',
  mixins: [toastHelperMixin],
  components: {
    DomainTagList,
  },
  props: {
    id: String,
    name: String,
  },
  data: function () {
    return {
      loading: true,
      show: true,
      domainNameInput: '',
      saveDataRequestRunning: false,
      environmentName: '',
      environmentURL: '',
      environmentLiveBranch: '',
      environmentType: undefined,
      typeRoles: [{label: 'Live', value: 0},
        {label: 'Acceptence', value: 1},
        {label: 'Q&A', value: 2},],
    };
  },
  created: async function () {
    try {
      const environmentData = (
        await axios.get(
          `/.netlify/functions/get-environment?environmentId=${this.id}`
        )
      ).data;
      this.environmentName = environmentData.name;
      this.environmentURL = environmentData.url;
      this.environmentLiveBranch = environmentData.gitBranch;
      this.environmentType = environmentData.type;
      this.loading = false;
    } catch (error) {
      this.showGenericErrorToast();
    }
  },
  methods: {
    async saveData() {
      if (
        !this.environmentName ||
        !this.environmentURL ||
        !this.environmentLiveBranch
      ) {
        this.showToast('All fields are required.', 'error');
        return;
      }
      this.saveDataRequestRunning = true;
      try {
        await axios.post('/.netlify/functions/update-environment', {
          environmentId: this.id,
          name: this.environmentName,
          url: this.environmentURL,
          gitBranch: this.environmentLiveBranch,
          type: this.environmentType,
        }).then(() => {
          this.$emit('environmentUpdated', this.environmentName, this.environmentType, this.id)
        });
        this.showToast('Environment updated.', 'success');
        this.$emit('on-close');
      } catch (error) {
        this.showGenericErrorToast();
      } finally {
        this.saveDataRequestRunning = false;
      }
    },
  },
};
