<template>
  <div class="login-page-container">
    <div class="login-page-content">
      <div class="login-page-left">
        <div class="auxta-logo">
          <HeaderLogo :logo-color="'white'"/>
        </div>
        <div class="title">
          {{ title }}
        </div>
        <div class="text page-white-text">
          {{ text }}
        </div>
        <div class="phone page-white-text">
          <img :src="phoneIcon" class="data-icon"/>{{ phoneNumber }}
        </div>
        <div class="mail page-white-text">
          <img :src="mailIcon" class="data-icon"/>{{ mail }}
        </div>
        <div class="bottom-suggestion">
          <div class="suggestion-text">
            {{ dontHaveAnAccountQuestion }}
          </div>
          <a class="suggestion-link" @click="toRegistration()">{{
              dontHaveAnAccountLink
            }}</a>
        </div>
      </div>
      <div class="login-page-right">
        <TopNavigationSmall/>
        <div class="login-form-container">
          <form class="login-form" @submit.prevent="formSubmit()">
            <div v-if="emailValidate === false" class="login-form-helper-text">
              {{ formHelperText1 }}
            </div>
            <div v-if="emailValidate === false" class="input-email-container">
              <input
                v-model="email"
                :placeholder="formHelperText1"
                class="input-email"
                type="email"
                @click="isCredentialsRight = true"
              />
              <img :src="emailImage" class="email-image"/>
            </div>
            <div v-if="emailValidate" class="login-form-helper-text helper-text-password">
              <div>
                {{ formHelperText2 }}
              </div>
            </div>
            <div v-if="emailValidate" class="input-password-container">
              <input
                v-model="password"
                :placeholder="formHelperText2"
                :type="'text'"
                class="input-password"
                @click="isCredentialsRight = true"
              />
              <img :src="passwordImage" class="password-image"/>
            </div>
            <p-button v-if="!emailValidate"
                      :loading="loginRequest"
                      class="login-btn"
                      icon="pi pi-arrow-right"
                      iconPos="right"
                      label="Continue"
                      @click="validateEmail"
            ></p-button>
            <p-button v-if="emailValidate"
                      :loading="loginRequest"
                      class="login-btn"
                      icon="pi pi-arrow-right"
                      iconPos="right"
                      label="Log in"
                      @click="requestLogin"
            ></p-button>
            <div
              v-if="!isCredentialsRight"
              class="alert alert-danger customize-alert"
              role="alert"
            >
              {{ invalidLoginCredentials }}
            </div>
          </form>
        </div>
      </div>
    </div>
    <FooterComponent/>
  </div>
</template>

<script src="./login.js"></script>
<style lang="scss" scoped src="./login.scss"></style>
