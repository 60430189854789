<template>
  <div class="page-wrapper">
    <div v-if="isAdmin" class="participants-bar">
      <a @click="openParticipantsDialog()"
      >{{ userCount !== undefined ? userCount : 'Loading' }} participants</a
      >
      <ParticipantsModal
        v-if="showParticipantsModal"
        :id="organizationId"
        :isAdmin="isAdmin"
        :name="organizationName"
        :users="userCount"
        viewType="organization"
        @userInteraction="getUsersCount"
        @on-close="showParticipantsModal = false"
      />
    </div>
    <ProgressBar :result="report.result" :url="report.url"/>
    <h1 class="page-title">
      Scenarios
    </h1>
    <div class="current-reports-big-pie-charts">
      <Skeleton
        v-if="!didResponseCome"
      ></Skeleton>
      <BigPieCharts
        v-if="didResponseCome"
        :data1=undefined
        :data2="scenarios"
        :data3="steps"
        :hide="false"
      />
    </div>
    <ScenarioList v-if="didResponseCome"
      :report-data-features="scenariosList"
      @on-navigate="navigate()"
    />
  </div>
</template>

<script src="./scenarios.js"></script>

<style lang="scss" scoped src="./scenarios.scss"></style>
