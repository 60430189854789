<template>
  <div
    class="add-entity-box"
    @click="$emit('click')"
  >
    <p class="add-entity-box-text">{{ text }}</p>
    <img class="add-entity-box-btn" src="/assets/images/add-plus.svg"/>
  </div>
</template>

<script src="./addEntityBox.js"></script>

<style lang="scss" scoped src="./addEntityBox.scss"></style>
