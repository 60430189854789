<template>
  <div class="page-wrapper">
    <div v-if="isAdmin" class="participants-bar">
      <a @click="openParticipantsDialog()"
      >{{ userCount !== undefined ? userCount : 'Loading' }} participants</a
      >
      <ParticipantsModal
        v-if="showParticipantsModal"
        :id="organizationId"
        :isAdmin="isAdmin"
        :name="organizationName"
        :users="userCount"
        viewType="organization"
        @userInteraction="getUsersCount"
        @on-close="showParticipantsModal = false"
      />
    </div>
    <div v-if="!isAdmin" class="separator"></div>
    <ProgressBar/>
    <h1 class="page-title">
      {{ bucketsYaml.title }}
    </h1>
    <div class="environments-big-pie-charts">
      <div v-if="!didResponseCome" class="skeletonCircles">
        <Skeleton shape="circle" size="250px"/>
        <Skeleton shape="circle" size="250px"/>
        <Skeleton shape="circle" size="250px"/>
      </div>
      <BigPieCharts
        v-if="didResponseCome"
        :data1="bucketFeatures"
        :data2="scenarios"
        :data3="steps"
        @getStatistics="updateStatistics"
      />
    </div>
    <div class="rectangles-container">
      <div class="rectangle-col-1">
        <!-- platfrom loader placeholders odd -->
        <div v-for="(environment) of buckets" :key="environment.name" class="oddCompanyPlaceholder">
          <div v-if="bucketsWithDataOdd.length <= 0" class="custom-skeleton">
            <Skeleton height="39px"/>
            <div class="circles">
              <Skeleton shape="circle" size="150px"/>
              <Skeleton shape="circle" size="150px"/>
              <Skeleton shape="circle" size="150px"/>
            </div>
          </div>
        </div>
        <PieChartRectangle
          v-for="bucket of bucketsWithDataOdd"
          :key="bucket.id"
          :data1="bucket.features"
          :data2="bucket.scenarios"
          :data3="bucket.steps"
          :isAdmin="isAdmin"
          :name="bucket.name"
          :type="bucket.type"
          @on-edit="editBucket(bucket.id, bucket.name)"
          @on-navigate="navigate(bucket.name)"
        />
      </div>
      <div class="rectangle-col-2">
        <!-- Platform loader placeholder even -->
        <div v-for="(environment) of buckets" :key="environment.name" class="oddCompanyPlaceholder">
          <div v-if="bucketsWithDataEven.length <= 0 && buckets.length > 1" class="custom-skeleton">
            <Skeleton height="39px"/>
            <div class="circles">
              <Skeleton shape="circle" size="150px"/>
              <Skeleton shape="circle" size="150px"/>
              <Skeleton shape="circle" size="150px"/>
            </div>
          </div>
        </div>
        <PieChartRectangle
          v-for="environment of bucketsWithDataEven"
          :key="environment.id"
          :data1="environment.features"
          :data2="environment.scenarios"
          :data3="environment.steps"
          :isAdmin="isAdmin"
          :name="environment.name"
          :type="environment.type"
          @on-edit="editBucket(environment.id, environment.name)"
          @on-navigate="navigate(environment.name)"
        />
      </div>
      <addBucketDialog
        v-if="showAddBucketDialog"
        @on-close="showAddBucketDialog = false"
        @update-products="updateBuckets"
      />
      <EditBucketModal
        v-if="showEditBucketModal"
        :id="currentlyEditingBucketID"
        :name="currentlyEditingBucketName"
        @bucketUpdated="bucketUpdated"
        @on-close="showEditBucketModal = false"
      />
    </div>
  </div>
</template>

<script src="./buckets.js"></script>

<style lang="scss" scoped src="./buckets.scss"></style>
