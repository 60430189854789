import GooglePieChart from '../googlePieChart/googlePieChart.vue';
import piechartRectangleYaml from 'js-yaml-loader!../../../../content/piechart-rectangle.yaml';
import {rolesEnumHelperMixin} from '../../mixins/rolesEnumHelperMixin';

export default {
  name: 'PieChartRectangle',
  components: {
    GooglePieChart,
  },
  mixins: [
    rolesEnumHelperMixin
  ],
  props: {
    name: String,
    token: String,
    data1: Array,
    data2: Array,
    data3: Array,
    type: Number,
    providers: String,
    isAdmin: Boolean,
  },
  data: function () {
    return {
      buildingImg: '',
      text1: '',
      text2: '',
      text3: '',
    };
  },
  mounted() {
    this.buildingImg = piechartRectangleYaml.buildingImg;
    this.text1 = piechartRectangleYaml.text1;
    this.text2 = piechartRectangleYaml.text2;
    this.text3 = piechartRectangleYaml.text3;
  },
  methods: {},
};
