<template>
  <div v-if="report && featuresForAccordion.length !== 0" class="page-wrapper">
    <ProgressBar :result="report.result" :url="report.url"/>
    <CurrentReportHeaderBoxes :report="reportHeaderBoxes"/>
    <h1 class="page-title">
      Bucket
    </h1>
    <div class="current-reports-big-pie-charts">
      <Skeleton
        v-if="!didResponseCome"
      ></Skeleton>
      <BigPieCharts
        v-if="didResponseCome"
        :data1="features"
        :data2="scenarios"
        :data3="steps"
        :hide="false"
      />
    </div>
    <CurrentReportAccordion
      :report-data-features="featuresForAccordion"
      @on-navigate="navigate()"
    />
  </div>
</template>

<script src="./currentReport.js"></script>

<style lang="scss" scoped src="./currentReport.scss"></style>
