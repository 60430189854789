import TopNavigationArticle from '../../components/topNavigationArticle/topNavigationArticle.vue';
import FooterComponent from '../../components/footer/footer.vue';
import {dateHelperMixin} from '../../mixins/dateHelperMixin';

export default {
  name: 'ManagersArticle',
  components: {
    TopNavigationArticle,
    FooterComponent,
  },
  mixins: [dateHelperMixin],
  data: function () {
    return {
      pageHeadingContent: {},
      articles: [],
      yamlContent: {},
      authorContent: {},
    };
  },
  mounted() {
    this.yamlContent = require(`js-yaml-loader!../../../../content/${
      this.$route.params['template']
    }/${this.$route.params['content'] + '.yaml'}`);
    this.authorContent = require(`js-yaml-loader!../../../../content/authors/${this.yamlContent.header.author}`);
    this.pageHeadingContent = this.yamlContent.header;
    this.articles = this.yamlContent.article;
  },
};
