<template>
  <div class="top-navigation-bar">
    <div class="content">
      <div class="left-side">
        <HeaderLogo :logo-color="logoColor"/>
      </div>
      <div class="right-side">
        <ul class="list-items">
          <li
            v-for="(listItem, index) of listItems"
            :key="listItem[index]"
            @click="navigate(index), emitScrollToValue(listItem)"
          >
            {{ listItem }}
          </li>
        </ul>
        <button @click="getStarted()">
          {{ buttonText }}
        </button>
      </div>
      <div class="right-side-burger" @click="openedBurgerMenu = true">
        <img v-if="!openedBurgerMenu" src="/assets/images/burger-menu.svg"/>
      </div>
      <div v-if="openedBurgerMenu" class="opened-burger-container">
        <div class="close">
          <img
            src="/assets/images/X-button-burger.svg"
            @click="openedBurgerMenu = false"
          />
        </div>
        <div class="opened-burger-content">
          <ul class="list-items-opened-burger">
            <li
              v-for="(listItem, index) of listItemsBurger"
              :key="listItemsBurger[index]"
              :to="{
                hash: `#${listItem}.replace(/\s/g,'')`,
              }"
            >
              {{ listItem }}
            </li>
          </ul>
          <button class="register-btn" @click="getStarted()">
            {{ buttonRegister }}
          </button>
          <button class="login-btn" @click="toLogin()">
            {{ buttonLogin }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./topNavigationBar.js"></script>

<style lang="scss" scoped src="./topNavigationBar.scss"></style>
