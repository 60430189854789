import axios from '../../../axios'
import ScenarioRectangle from '../scenarioRectangle/scenarioRectangle.vue'
import {useRoute} from 'vue-router'

export default {
  name: 'ScenarioList',
  components: {ScenarioRectangle},
  props: {
    reportDataFeatures: Array,
  },
  data: function () {
    return {
      route: undefined,
      accordionFeatures: [],
      accordionScenariosWithEvenData: [],
      accordionScenariosWithOddData: [],
      repositoryLocation: '',
      liveBranch: '',
      environmentName: this.$route.params['environment_name'],
      digitalProductName: this.$route.params['digital_product_name'],
      repositoryHostingService: '',
      hostingServiceImg: '',
      waitForResponse: false, // loader for features tabs
      waitForStepsResponse: false, // loader for steps
      currentFeatureIndex: '', // current feature array index
      currentFeatureId: '', // current feature id in DB
      showStepIndex: '', // on which step to show the loader
      scrolled: true,
      currentPage: 0,
    }
  },
  async created() {
    this.route = useRoute()
    await axios.post('/.netlify/functions/get-repository-location', {
      environmentName: this.environmentName,
      digitalProductName: this.digitalProductName
    })
      .then((data) => {
        this.repositoryLocation = data.data.repositoryLocation
        this.liveBranch = data.data.gitBranch
        this.repositoryHostingService = data.data.repositoryHostingService
      })
      .catch(() => {
      })
    for (let i = 0; i < this.reportDataFeatures.length; i++) {
      let o = ({
        name: this.reportDataFeatures[i].name,
        status: this.reportDataFeatures[i].failedScenarios !== 1,
        uri: this.reportDataFeatures[i].uri,
        scenariosCount: this.reportDataFeatures[i].scenariosCount,
        stepsCount: this.reportDataFeatures[i].stepsCount,
        performanceFailure: this.reportDataFeatures[i].performanceFailure ? this.reportDataFeatures[i].performanceFailure : 0,
        suggestedSteps: this.reportDataFeatures[i].suggestedSteps ? this.reportDataFeatures[i].suggestedSteps : 0,
        id: this.reportDataFeatures[i].id,
        openedFeature: false,
        openedScenarios: [],
      })
      if (i % 2 == 0) {
        this.accordionScenariosWithOddData.push(o)
      } else this.accordionScenariosWithEvenData.push(o)
    }

  },
  methods: {
    navigate(name) {
      name = name.toString().replace(/\s/g, '_')
      const newPath = `${this.route.path}/${name}/reports`;
      if (event.button == 0) this.$router.push(newPath);
    }
  },
}
