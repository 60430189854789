import axios from '../../../axios';
import ProgressBar from '../../components/progressBar/progressBar.vue';
import BigPieCharts from '@/app/components/bigPieCharts/bigPieCharts.vue';
import CurrentReportHeaderBoxes from '@/app/components/currentReportHeaderBoxes/currentReportHeaderBoxes.vue';
import ScenarioList from '@/app/components/scenarioList/scenarioList.vue';
import {canShowParticipantsModalMixin} from '../../mixins/canShowParticipantsModalMixin'
import {toastHelperMixin} from '@/app/mixins/toastHelperMixin';
import {checkIfAdminMixin} from '@/app/mixins/checkIfAdminMixin';
import ParticipantsModal from '@/app/components/modals/participantsModal/participantsModal.vue';

export default {
  name: 'CurrentReport',
  mixins: [canShowParticipantsModalMixin, toastHelperMixin, checkIfAdminMixin],
  components: {
    BigPieCharts,
    ParticipantsModal,
    CurrentReportHeaderBoxes,
    ScenarioList,
    ProgressBar,
  },
  data: function () {
    return {
      isAdmin: false,
      userCount: undefined,
      scenariosList: [],
      scenarios: [],
      steps: [],
      features: [],
      passedScenarios: 0,
      failedScenarios: 0,
      skippedSteps: 0,
      suggestedSteps: 0,
      duration: 0,
      // todo why the report is an array? - mm
      report: {},
      organizationName: this.$route.params['organization_name'],
      digitalProductName: this.$route.params['digital_product_name'],
      environmentName: this.$route.params['environment_name'],
      bucketName: this.$route.params['bucket_name'],
      digitalProductId: undefined,
      environmentId: undefined,
      organizationId: undefined,
      bucketId: undefined,
      reportHeaderBoxes: {},
      didResponseCome: false,
    };
  },
  async created() {
    this.isAdmin = await this.isUserAdmin(axios, false)
    this.digitalProductId = await axios.post('/.netlify/functions/get-digital-product-id', {
      digitalProductName: this.digitalProductName
    }).then((res) => res.data.digitalProductId);
    if (localStorage.getItem('token')) {
      await axios.post('/.netlify/functions/get-scenarios-list', {
        digitalProductId: this.digitalProductId,
        environmentName: this.environmentName,
        bucketName: this.bucketName,
      }).then(data => {
        this.didResponseCome = true;
        this.scenariosList = data.data;
      })
      await this.getCurrentReportsData();
    }
    try {
      if (this.isAdmin) {
        const projectIdResponse = (
          await axios.post('/.netlify/functions/get-organization-id', {
            organizationName: this.organizationName
          })
        ).data;
        this.organizationId = projectIdResponse.organizationId;
        this.userCount = (
          await axios.get(
            `/.netlify/functions/get-organization-users?organizationId=${this.organizationId}`
          )
        ).data.length;
      }
    } catch (error) {
      this.showGenericErrorToast();
    }
  },
  methods: {
    async getCurrentReportsData() {
      this.digitalProductId = await axios.post('/.netlify/functions/get-digital-product-id', {
        digitalProductName: this.digitalProductName,
      }).then((res) => res.data.digitalProductId);
      this.environmentId = await axios.post('/.netlify/functions/get-environment-id', {
        digitalProductId: this.digitalProductId,
        environmentName: this.environmentName,
      }).then((res) => res.data.environmentId);
      this.bucketId = await axios.post('/.netlify/functions/get-bucket',
        {bucketName: this.bucketName, environmentId: this.environmentId}).then((res) => res.data.id);
      await axios.post('/.netlify/functions/get-statistics-reports', {
        period: Number(process.env.DEFAULT_PERIOD),
        environmentName: this.environmentName,
        bucketId: this.bucketId
      }).then(({data}) => {
        this.features.push([
          'PASSED',
          data.passedBuckets || 0
        ])
        this.features.push([
          'FAILED',
          data.failedBuckets || 0
        ])
        this.scenarios.push([
          'PASSED',
          data.passedScenarios || 0
        ])
        this.scenarios.push([
          'FAILED',
          data.failedScenarios || 0
        ])
        this.steps.push([
          'PASSED',
          data.passedSteps || 0
        ])
        this.steps.push([
          'FAILED',
          data.failedSteps || 0
        ])
        this.steps.push([
          'SKIPPED',
          data.skippedSteps || 0
        ])
        this.steps.push([
          'SUGGESTED',
          data.suggestedSteps || 0
        ])
        this.steps.push([
          'PERFORMANCE_FAILURE',
          data.performanceFailure || 0
        ])
      })
    },
    async getUsersCount(entityId) {
      if (this.isAdmin) {
        this.userCount = (
          await axios.get(
            `/.netlify/functions/get-digital-product-users?digitalProductId=${entityId}`
          )
        ).data.length
      }
    },
  }
};
