<template>
  <div class="page-wrapper">
    <h1 class="page-title">
      {{ title }}
    </h1>
    <div class="organizations-big-pie-charts">
      <BigPieCharts
        :data1="features"
        :data2="scenarios"
        :data3="steps"
        @getStatistics="updateStatistics"
      />
    </div>
    <div class="rectangles-container">
      <div class="rectangle-col-1">
        <PieChartRectangle
          v-for="organization of organizationsWithDataOdd"
          :key="organization.id"
          :data1="organization.features"
          :data2="organization.scenarios"
          :data3="organization.steps"
          :isAdmin="isAdmin"
          :name="organization.name"
          :providers="organization.providers"
          @on-edit="editDigitalProduct(organization.id, organization.name)"
          @on-navigate="navigate(organization.name)"
        />
        <AddEntityBox
          v-show="isLeftVisible"
          :text="'Add organization'"
          @click="showAddOrganizationDialog = true"
        />
      </div>
      <div class="rectangle-col-2">
        <PieChartRectangle
          v-for="organization of organizationsWithDataEven"
          :key="organization.id"
          :data1="organization.features"
          :data2="organization.scenarios"
          :data3="organization.steps"
          :isAdmin="isAdmin"
          :name="organization.name"
          :providers="organization.providers"
          @on-edit="editDigitalProduct(organization.id, organization.name)"
          @on-navigate="navigate(organization.name)"
        />
        <AddEntityBox
          v-show="isRightVisible"
          :text="'Add organization'"
          @click="showAddOrganizationDialog = true"
        />
      </div>
      <addOrganizationDialog
        v-if="showAddOrganizationDialog"
        @on-close="showAddOrganizationDialog = false"
        @update-organizations="updateOrganizations"
      />
      <EditOrganizationModal
        v-if="showEditDigitalProductModal"
        :id="currentlyEditingDigitalProductID"
        :name="currentlyEditingDigitalProductName"
        @organizationUpdate="organizationUpdated"
        @on-close="showEditDigitalProductModal = false"
      />
    </div>
  </div>
</template>

<script src="./organizations.js"></script>

<style lang="scss" scoped src="./organizations.scss"></style>
