import axios from '../../../../axios';
import {toastHelperMixin} from '@/app/mixins/toastHelperMixin';

export default {
  name: 'EditBucketModal',
  mixins: [toastHelperMixin],
  props: {
    id: String,
    name: String,
  },
  data: function () {
    return {
      loading: true,
      show: true,
      domainNameInput: '',
      saveDataRequestRunning: false,
      bucketName: '',
    };
  },
  created: async function () {
    try {
      const bucket = await axios.post(
        '/.netlify/functions/get-bucket', {bucketName: this.name,environmentId: this.id});
      this.bucketName = bucket.data.name;
      this.loading = false;
    } catch (error) {
      this.showGenericErrorToast();
    }
  },
  methods: {
    async saveData() {
      if (!this.bucketName) {
        this.showToast('All fields are required.', 'error');
        return;
      }
      this.saveDataRequestRunning = true;
      try {
        await axios.post('/.netlify/functions/update-bucket', {
          bucketId: this.id,
          name: this.bucketName,
        }).then(() => {
          this.$emit('bucketUpdated', this.bucketName, this.id)
        });
        this.showToast('Bucket updated.', 'success');
        this.$emit('on-close');
      } catch (error) {
        this.showGenericErrorToast();
      } finally {
        this.saveDataRequestRunning = false;
      }
    }
  },
};
