export default {
  props: {
    logoColor: String,
  },
  methods: {
    navigateToHome() {
      this.$router.push('/');
    },
  },
};
