import axios from '../../../axios'
import ProgressBar from '../../components/progressBar/progressBar.vue'
import PieChartRectangle from '../../components/pieChartRectangle/pieChartRectangle.vue'
import AddEnvironmentDialog from '../../components/addEnvironmentDialog/addEnvironmentDialog.vue'
import BigPieCharts from '../../components/bigPieCharts/bigPieCharts.vue'
import environmentsYaml from 'js-yaml-loader!../../../../content/environments.yaml'
import AddEntityBox from '../../components/addEntityBox/addEntityBox.vue'
import {canShowParticipantsModalMixin} from '../../mixins/canShowParticipantsModalMixin'
import ParticipantsModal from '../../components/modals/participantsModal/participantsModal.vue'
import {toastHelperMixin} from '../../mixins/toastHelperMixin'
import EditEnvironmentModal from '../../components/modals/editEnvironmentModal/editEnvironmentModal.vue'
import {checkIfAdminMixin} from '../../mixins/checkIfAdminMixin'

export default {
  name: 'Environments',
  mixins: [canShowParticipantsModalMixin, toastHelperMixin, checkIfAdminMixin],
  components: {
    AddEnvironmentDialog,
    PieChartRectangle,
    BigPieCharts,
    ProgressBar,
    AddEntityBox,
    ParticipantsModal,
    EditEnvironmentModal,
  },
  data: function () {
    return {
      type: undefined,
      isAdmin: false,
      title: '',
      environments: [],
      environmentsYaml: environmentsYaml,
      isRightVisible: false,
      isLeftVisible: false,
      organizationId: undefined,
      organizationName: this.$route.params['organization_name'],
      digitalProductName: this.$route.params['digital_product_name'],
      environmentsWithDataOdd: [],
      environmentsWithDataEven: [],
      buckets: [],
      scenarios: [],
      steps: [],
      period: 'month',
      showAddEnvironmentDialog: false,
      digitalProductId: undefined,
      userCount: undefined,
      showEditEnvironmentModal: false,
      currentlyEditingEnvironmentID: undefined,
      didResponseCome: false,
      currentlyEditingEnvironmentName: undefined
    }
  },
  async created() {
    await axios.get('/.netlify/functions/get-user-preferences').then(data => {
      this.type = data.data.userPreference.type;
    })
    await axios
      .post('/.netlify/functions/get-environments', {
        digitalProductName: this.digitalProductName
      })
      .then(res => {
        this.environments = res.data
      }).catch(() => {
        this.$router.push('/organizations')
      })
    axios
      .post('/.netlify/functions/get-statistics-environments', {
        type: this.type,
        digitalProductName: this.digitalProductName,
        period: Number(process.env.DEFAULT_PERIOD)
      })
      .then((allEnvironmentsData) => {
        this.didResponseCome = true
        this.buckets.push([
          'PASSED',
          allEnvironmentsData.data.passedBuckets || 0
        ])
        this.buckets.push([
          'FAILED',
          allEnvironmentsData.data.failedBuckets || 0
        ])
        this.scenarios.push([
          'PASSED',
          allEnvironmentsData.data.passedScenarios || 0
        ])
        this.scenarios.push([
          'FAILED',
          allEnvironmentsData.data.failedScenarios || 0
        ])
        this.steps.push([
          'PASSED',
          allEnvironmentsData.data.passedSteps || 0
        ])
        this.steps.push([
          'FAILED',
          allEnvironmentsData.data.failedSteps || 0
        ])
        this.steps.push([
          'SKIPPED',
          allEnvironmentsData.data.skippedSteps || 0
        ])
        this.steps.push([
          'SUGGESTED',
          allEnvironmentsData.data.suggestedSteps || 0
        ])
        this.steps.push([
          'PERFORMANCE_FAILURE',
          allEnvironmentsData.data.performanceFailure || 0
        ])
      }).catch(() => this.didResponseCome = true)
    this.isAdmin = await this.isUserAdmin(axios, false)
    if (this.environments.length % 2 == 0 && this.isAdmin)
      this.isLeftVisible = true
    else if (this.environments.length % 2 != 0 && this.isAdmin)
      this.isRightVisible = true
    await this.getCurrentReportsData()
    try {
      if (this.isAdmin) {
        const projectIdResponse = (
          await axios.post('/.netlify/functions/get-organization-id', {
            organizationName: this.organizationName
          })
        ).data;
        this.organizationId = projectIdResponse.organizationId;
        this.userCount = (
          await axios.get(
            `/.netlify/functions/get-organization-users?organizationId=${this.organizationId}`
          )
        ).data.length;
      }
    } catch (error) {
      this.showGenericErrorToast();
    }
  },
  async mounted() {
    this.environmentsYaml = environmentsYaml
  },
  methods: {

    async getCurrentReportsData(period = Number(process.env.DEFAULT_PERIOD)) {
      this.environmentsWithDataOdd = [];
      this.environmentsWithDataEven = [];
      const requests = [];
      for (let i = 0; i < this.environments.length; i++) {
        requests.push(axios.post('/.netlify/functions/get-statistics-reports', {
          environmentId: this.environments[i].id,
          period: period
        }));
      }
      await Promise.all(requests).then(requests => {
        this.handleRequests(requests);
      })
    },

    updateStatistics(type, period) {
      this.buckets = []
      this.scenarios = []
      this.steps = []
      axios.post('/.netlify/functions/get-statistics-environments', {
        type: type,
        period: period,
        digitalProductName: this.digitalProductName
      })
        .then((allEnvironmentsData) => {
          this.didResponseCome = true
          this.buckets.push([
            'PASSED',
            allEnvironmentsData.data.passedBuckets || 0
          ])
          this.buckets.push([
            'FAILED',
            allEnvironmentsData.data.failedBuckets || 0
          ])
          this.scenarios.push([
            'PASSED',
            allEnvironmentsData.data.passedScenarios || 0
          ])
          this.scenarios.push([
            'FAILED',
            allEnvironmentsData.data.failedScenarios || 0
          ])
          this.steps.push([
            'PASSED',
            allEnvironmentsData.data.passedSteps || 0
          ])
          this.steps.push([
            'FAILED',
            allEnvironmentsData.data.failedSteps || 0
          ])
          this.steps.push([
            'SKIPPED',
            allEnvironmentsData.data.skippedSteps || 0
          ])
          this.steps.push([
            'SUGGESTED',
            allEnvironmentsData.data.suggestedSteps || 0
          ])
          this.steps.push([
            'PERFORMANCE_FAILURE',
            allEnvironmentsData.data.performanceFailure || 0
          ])

          this.getCurrentReportsData(period)


        }).catch(() => this.didResponseCome = true)
    },
    editEnvironment(id, name) {
      this.currentlyEditingEnvironmentID = id
      this.currentlyEditingEnvironmentName = name
      this.showEditEnvironmentModal = true
    },
    async updateEnvironments() {
      this.environmentsWithDataEven = []
      this.environmentsWithDataOdd = []
      await axios.post('/.netlify/functions/get-environments', {
        digitalProductName: this.digitalProductName
      })
        .then(res => {
          this.environments = res.data
        })
      if (this.environments.length % 2 == 0 && this.isAdmin) {
        this.isLeftVisible = true
        this.isRightVisible = false
      } else if (this.environments.length % 2 != 0 && this.isAdmin) {
        this.isRightVisible = true
        this.isLeftVisible = false
      }
      const requests = [];
      for (let i = 0; i < this.environments.length; i++) {
        requests.push(axios.post('/.netlify/functions/get-statistics-reports', {
          environmentId: this.environments[i].id,
          period: 24
        }));
      }
      await Promise.all(requests).then(requests => {
        this.handleRequests(requests);
      })
    },
    handleRequests(requests) {
      for (let i = 0; i < requests.length; i++) {
        const data = requests[i].data;
        const o = {
          name: this.environments[i].name,
          id: this.environments[i].id,
          type: this.environments[i].type,
          buckets: [
            ['PASSED', data.passedBuckets],
            ['FAILED', data.failedBuckets]
          ],
          scenarios: [
            ['PASSED', data.passedScenarios],
            ['FAILED', data.failedScenarios]
          ],
          steps: [
            ['PASSED', data.passedSteps],
            ['FAILED', data.failedSteps],
            ['SKIPPED', data.skippedSteps],
            ['SUGGESTED', data.suggestedSteps],
            ['PERFORMANCE_FAILURE', data.performanceFailure],
          ]
        }
        if (i % 2 === 0) {
          this.environmentsWithDataOdd.push(o)
        } else {
          this.environmentsWithDataEven.push(o)
        }
      }
    },
    environmentUpdated(name, type, envId) {
      let environmentIndex = this.environmentsWithDataOdd.findIndex(e => e.id == envId)

      if (environmentIndex == -1) {
        environmentIndex = this.environmentsWithDataEven.findIndex(e => e.id == envId)

        this.environmentsWithDataEven[environmentIndex]['type'] = type
        this.environmentsWithDataEven[environmentIndex]['name'] = name
      } else {
        this.environmentsWithDataOdd[environmentIndex]['name'] = name
        this.environmentsWithDataOdd[environmentIndex]['type'] = type

      }
    },
    navigate(environmentName) {
      // eslint-disable-next-line
      const route = `/organizations/${this.organizationName}/digital-products/${this.digitalProductName}/environments/${environmentName}/buckets`
      if (event.button == 0) this.$router.push(route)
      else if (event.button == 1) window.open(route)
    },
    async getUsersCount(entityId) {
      if (this.isAdmin) {
        this.userCount = (
          await axios.get(
            `/.netlify/functions/get-organization-users?organizationId=${entityId}`
          )
        ).data.length
      }
    },
  },
}
