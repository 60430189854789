<template>
  <div class="home-page-container">
    <div class="top-navbar">
      <TopNavigationBar @scroll-to="scrollTo"/>
    </div>
    <div class="title-container">
      <div class="title-content">
        <div class="title-left-side">
          <h1 class="title">
            {{ title }}
          </h1>
          <h2 class="subtitle">
            {{ subtitle }}
          </h2>
          <div class="title-actions">
            <button class="get-started" @click="getStarted()">
              {{ getStartedBtn }}
            </button>
            <button class="learn-more">
              {{ learnMorebtn }}
            </button>
          </div>
        </div>
        <div class="title-right-side">
          <div class="image">
            <img :src="titleImage"/>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="logos-container">
      <div class="logos-images-container">
        <img class="logo-images" :src="mediaCompetenceLogo" />
        <img class="logo-images" :src="thomasLloydLogo" />
        <img class="logo-images" :src="smeFinanceLogo" />
      </div>
    </div> -->
    <div id="Whatweoffer" class="what-we-offer-container">
      <div class="what-we-offer-content">
        <h3 class="what-we-offer-title">
          {{ whatWeOfferTitle }}
        </h3>
        <div class="what-we-offer-boxes-container">
          <div
            v-for="whatWeOfferBox of whatWeOfferBoxes"
            :key="whatWeOfferBox.class"
            class="what-we-offer-box"
          >
            <img :src="whatWeOfferBox.icon" alt/>
            <h2>{{ whatWeOfferBox.title }}</h2>
            <h4>{{ whatWeOfferBox.text }}</h4>
          </div>
        </div>
      </div>
    </div>
    <ForWhom/>
    <ImplementationModel/>
    <Flowchart/>
    <Team/>
    <div id="Articles" class="test-insights-container">
      <div class="test-insights-container-content">
        <div class="test-insights-texts">
          <h2>{{ testAutomationInsightsTitle }}</h2>
          <h4>{{ testAutomationInsightsText }}</h4>
        </div>
        <div class="text-insights-boxes">
          <div class="text-insights-box-wrapper">
            <div class="text-insights-box">
              <img :src="testAutoBoxImg1" class="text-insights-box-img"/>
              <div class="text-insights-box-header">
                {{ testAutoBoxTitle1 }}
              </div>
              <div class="text-insights-box-text">
                {{ testAutoBoxText1 }}
              </div>
              <a
                class="text-insights-box-link"
                @click="
                  toArticle(testAutoBoxYamlTemplate1, testAutoBoxYamlContent1)
                "
              >{{ testAutoBoxButtonText1 }}</a
              >
            </div>
          </div>
          <div class="text-insights-box-wrapper">
            <div class="text-insights-box">
              <img :src="testAutoBoxImg2" class="text-insights-box-img"/>
              <div class="text-insights-box-header">
                {{ testAutoBoxTitle2 }}
              </div>
              <div class="text-insights-box-text">
                {{ testAutoBoxText2 }}
              </div>
              <a
                class="text-insights-box-link"
                @click="
                  toArticle(testAutoBoxYamlTemplate2, testAutoBoxYamlContent2)
                "
              >{{ testAutoBoxButtonText2 }}</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      :style="{
        background: `url(${bottomBlueContainerBackgroundImage}) no-repeat`,
      }"
      class="bottom-blue-container"
    >
      <div class="bottom-blue-content">
        <div class="bottom-blue-image">
          <img :src="bottomBlueContainerImage"/>
        </div>
        <div class="bottom-blue-texts">
          <div class="bottom-blue-title">
            {{ bottomBlueContainerTitle }}
          </div>
          <button class="bottom-blue-button" @click="getStarted()">
            {{ bottomBlueContainerButtonText }}
          </button>
        </div>
      </div>
    </div>
    <div class="footer-container">
      <FooterComponent/>
    </div>
  </div>
</template>

<script src="./home.js"></script>

<style lang="scss" scoped src="./home.scss"></style>
