import footerYaml from 'js-yaml-loader!../../../../content/footer.yaml';

export default {
  name: 'FooterComponent',
  data: function () {
    return {
      footerLogo: '',
      footerLinks: [],
      footerRightsReserved: '',
      link1Content: [],
      link2Content: [],
      place: '',
    };
  },
  mounted() {
    this.footerLogo = footerYaml.footerLogo;
    this.footerLinks = footerYaml.footerLinks;
    this.footerRightsReserved = footerYaml.footerRightsReserved;
    this.link1Content = footerYaml.footerLinks.link1Content;
    this.link2Content = footerYaml.footerLinks.link2Content;
    this.place = footerYaml.place;
  },
  methods: {},
};
