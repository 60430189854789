import bigPieChartsYaml from 'js-yaml-loader!../../../../content/big-piecharts.yaml';
import axios from '../../../axios';
import GooglePieChart from '../googlePieChart/googlePieChart.vue';

export default {
  name: 'BigPieCharts',
  components: {
    GooglePieChart,
  },
  props: {
    data1: Array,
    data2: Array,
    data3: Array,
    hide: {
      type: Boolean,
      default: true,
    },
  },
  data: function () {
    return {
      text1: '',
      text2: '',
      text3: '',
      environmentType: undefined,
      periodOfTest: 0,
      typeRoles: [{label: 'Live', value: 0},
        {label: 'Acceptence', value: 1},
        {label: 'Q&A', value: 2},
        {label: 'Without FLag', value: 3},
        {label: 'All', value: 4}],
      period: [
        {label: '24 hours', value: 24},
        {label: '6 hours', value: 6},
        {label: '1 hour', value: 1},
        {label: 'Last test', value: 0},
      ],
    };
  },
  async created() {
    await axios.get('/.netlify/functions/get-user-preferences').then(data => {
      this.environmentType = data.data.userPreference.type
      this.periodOfTest = data.data.userPreference.period
    },)
  },
  mounted() {
    this.text1 = bigPieChartsYaml.text1;
    this.text2 = bigPieChartsYaml.text2;
    this.text3 = bigPieChartsYaml.text3;
  },
  methods: {
    async updateStatistics(value, period) {
      await axios.post('/.netlify/functions/update-user-preferences', {type: value, period: period})

      this.$emit('getStatistics', value, period)
    }
  }
};
