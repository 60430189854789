export const rolesEnumHelperMixin = {
  methods: {
    roleEnumToString(roleEnum) {
      switch (roleEnum) {
        case 'PLATFORM_ADMINISTRATOR':
          return 'Platform Administrator';
        case 'ORGANISATION_ADMINISTRATOR':
          return 'Organisation Administrator';
        case 'ORGANISATION_MEMBER':
          return 'Organisation Member';
      }
    },
    typeNumberToString(typeNumber) {
      switch (typeNumber) {
        case 0:
          return 'Live';
        case 1:
          return 'Acceptence';
        case 2:
          return 'Q&A';
      }
    },
    roleNumberToEnum(roleNumber) {
      switch (roleNumber) {
        case 0:
          return 'PLATFORM_ADMINISTRATOR';
        case 1:
          return 'ORGANISATION_ADMINISTRATOR';
        case 2:
          return 'ORGANISATION_MEMBER';
      }
    },
  }
}
