import axios from '../../../axios'
import ScenarioRectangle from '../scenarioRectangle/scenarioRectangle.vue'
import {useRoute} from 'vue-router'

export default {
  name: 'CurrentReportAccordion',
  components: {ScenarioRectangle},
  props: {
    reportDataFeatures: Array,
  },
  data: function () {
    return {
      route: undefined,
      accordionFeatures: [],
      accordionFeaturesWithEvenData: [],
      accordionFeaturesWithOddData: [],
      repositoryLocation: '',
      liveBranch: '',
      environmentName: this.$route.params['environment_name'],
      digitalProductName: this.$route.params['digital_product_name'],
      repositoryHostingService: '',
      hostingServiceImg: '',
      waitForResponse: false, // loader for features tabs
      waitForStepsResponse: false, // loader for steps
      currentFeatureIndex: '', // current feature array index
      currentFeatureId: '', // current feature id in DB
      showStepIndex: '', // on which step to show the loader
      scrolled: true,
      currentPage: 0,
    }
  },
  async created() {
    this.route = useRoute()
    await axios
      .post('/.netlify/functions/get-repository-location', {
        environmentName: this.environmentName,
        digitalProductName: this.digitalProductName
      })
      .then((data) => {
        this.repositoryLocation = data.data.repositoryLocation
        this.liveBranch = data.data.gitBranch
        this.repositoryHostingService = data.data.repositoryHostingService
      })
      .catch(() => {
      })
    for (let i = 0; i < this.reportDataFeatures.length; i++) {
      let o = ({
        name: this.reportDataFeatures[i].name,
        status: this.reportDataFeatures[i].status,
        uri: this.reportDataFeatures[i].uri,
        scenariosCount: this.reportDataFeatures[i].scenariosCount,
        stepsCount: this.reportDataFeatures[i].stepsCount,
        id: this.reportDataFeatures[i].featureRef,
        openedFeature: false,
        openedScenarios: [],
      })
      if (i % 2 == 0) {
        this.accordionFeaturesWithOddData.push(o)
      } else this.accordionFeaturesWithEvenData.push(o)
    }
  },
  methods: {
    navigate(name) {
      const newPath = `${this.route.path}/${name}`;
      if (event.button == 0) this.$router.push(newPath);
    }
  },
}
