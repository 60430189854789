import axios from '../../../axios';
import ProgressBar from '../../components/progressBar/progressBar.vue';
import PieChartRectangle from '../../components/pieChartRectangle/pieChartRectangle.vue';
import addDigitalProductDialog from '../../components/addDigitalProductDialog/addDigitalProductDialog.vue';
import BigPieCharts from '../../components/bigPieCharts/bigPieCharts.vue';
import digitalProductsPageYaml from 'js-yaml-loader!../../../../content/digitalProducts.yaml';
import AddEntityBox from '../../components/addEntityBox/addEntityBox.vue';
import { canShowParticipantsModalMixin } from '../../mixins/canShowParticipantsModalMixin';
import ParticipantsModal from '../../components/modals/participantsModal/participantsModal.vue';
import { toastHelperMixin } from '../../mixins/toastHelperMixin';
import EditDigitalProductModal from '../../components/modals/editDigitalProductModal/editDigitalProductModal.vue';
import { checkIfAdminMixin } from '../../mixins/checkIfAdminMixin';

export default {
  name: 'DigitalProducts',
  mixins: [canShowParticipantsModalMixin, toastHelperMixin, checkIfAdminMixin],
  components: {
    addDigitalProductDialog,
    PieChartRectangle,
    BigPieCharts,
    ProgressBar,
    AddEntityBox,
    ParticipantsModal,
    EditDigitalProductModal
  },
  data: function() {
    return {
      type: undefined,
      isAdmin: false,
      isAdminOrOAdmin: false,
      title: '',
      digitalProducts: [],
      digitalProductsPageYaml: digitalProductsPageYaml,
      isRightVisible: false,
      isLeftVisible: false,
      organizationName: String,
      digitalProductsWithDataOdd: [],
      digitalProductsWithDataEven: [],
      buckets: [],
      scenarios: [],
      steps: [],
      period: 'month',
      showAddDigitalProductDialog: false,
      organizationId: undefined,
      userCount: undefined,
      showEditDigitalProductModal: false,
      currentlyEditingDigitalProductID: undefined,
      didResponseCome: false,
      currentlyEditingDigitalProductName: undefined,
      currentlyEditingDigitalProductToken: undefined
    };
  },
  async created() {
    this.organizationName = this.$route.params['organization_name'];
    this.digitalProductName = this.$route.params['digital_product_name'];
    await axios.get('/.netlify/functions/get-user-preferences').then(data => {
      this.type = data.data.userPreference.type;
    });
    await this.updateProducts();
    try {
      if (this.isAdminOrOAdmin) {
        const projectIdResponse = (
          await axios.post('/.netlify/functions/get-organization-id', {
            organizationName: this.organizationName
          })
        ).data;
        this.organizationId = projectIdResponse.organizationId;
        this.userCount = (
          await axios.get(
            `/.netlify/functions/get-organization-users?organizationId=${this.organizationId}`
          )
        ).data.length;
      }
    } catch (error) {
      this.showGenericErrorToast();
    }

  },
  async mounted() {
    this.digitalProductsPageYaml = digitalProductsPageYaml;
  },
  methods: {
    async getEnviromentsData(period = Number(process.env.DEFAULT_PERIOD)) {
      let statistics = [];
      this.digitalProductsWithDataOdd = [];
      this.digitalProductsWithDataEven = [];
      for (const digitalProduct of this.digitalProducts) {
        statistics.push(await axios.post('/.netlify/functions/get-statistics-environments',
          {
            type: 4,
            digitalProductName: digitalProduct.name,
            period: period
          }));
      }
      await Promise.all(statistics);
      for (let i = 0; i < this.digitalProducts.length; i++) {
        const statistic = statistics[i];
        const data = {
          name: this.digitalProducts[i].name,
          id: this.digitalProducts[i].id,
          token: this.digitalProducts[i].token,
          buckets: [
            ['PASSED', statistic.data.passedBuckets],
            ['FAILED', statistic.data.failedBuckets]
          ],
          scenarios: [
            ['PASSED', statistic.data.passedScenarios],
            ['FAILED', statistic.data.failedScenarios]
          ],
          steps: [
            ['PASSED', statistic.data.passedSteps],
            ['FAILED', statistic.data.failedSteps],
            ['SKIPPED', statistic.data.skippedSteps || 0],
            ['SUGGESTED', statistic.data.suggestedSteps || 0],
            ['PERFORMANCE_FAILURE', statistic.data.performanceFailure || 0]
          ]
        };
        if (i % 2 === 0) {
          this.digitalProductsWithDataOdd.push(data);
        } else {
          this.digitalProductsWithDataEven.push(data);
        }
      }
    },
    updateStatistics(type, period) {
      this.buckets = [];
      this.steps = [];
      this.scenarios = [];
      axios
        .post('/.netlify/functions/get-statistics-digital-products', {
          type: type,
          period: period,
          organizationName: this.organizationName
        })
        .then((allDigitalProductsData) => {
          this.didResponseCome = true;
          this.buckets.push([
            'PASSED',
            allDigitalProductsData.data.passedBuckets || 0
          ]);
          this.buckets.push([
            'FAILED',
            allDigitalProductsData.data.failedBuckets || 0
          ]);
          this.scenarios.push([
            'PASSED',
            allDigitalProductsData.data.passedScenarios || 0
          ]);
          this.scenarios.push([
            'FAILED',
            allDigitalProductsData.data.failedScenarios || 0
          ]);
          this.steps.push([
            'PASSED',
            allDigitalProductsData.data.passedSteps || 0
          ]);
          this.steps.push([
            'FAILED',
            allDigitalProductsData.data.failedSteps || 0
          ]);
          this.steps.push([
            'SKIPPED',
            allDigitalProductsData.data.skippedSteps || 0
          ]);
          this.steps.push([
            'SUGGESTED',
            allDigitalProductsData.data.suggestedSteps || 0
          ]);
          this.steps.push([
            'PERFORMANCE_FAILURE',
            allDigitalProductsData.data.performanceFailure || 0]);

          this.getEnviromentsData(period);
        });
    },
    editDigProduct(id, name, token) {
      this.currentlyEditingDigitalProductID = id;
      this.currentlyEditingDigitalProductName = name;
      this.currentlyEditingDigitalProductToken = token;
      this.showEditDigitalProductModal = true;
    },
    async updateProducts() {
      this.isRightVisible = false;
      this.isLeftVisible = false;
      await axios.post('/.netlify/functions/get-digital-products', {
        organizationName: this.organizationName
      }).then(data => {
        this.digitalProducts = data.data;
      });
      axios.post('/.netlify/functions/get-statistics-digital-products', {
        type: this.type,
        organizationName: this.organizationName
      }).then((allDigitalProductsData) => {
        this.didResponseCome = true;
        this.buckets.push([
          'PASSED',
          allDigitalProductsData.data.passedBuckets || 0
        ]);
        this.buckets.push([
          'FAILED',
          allDigitalProductsData.data.failedBuckets || 0
        ]);
        this.scenarios.push([
          'PASSED',
          allDigitalProductsData.data.passedScenarios || 0
        ]);
        this.scenarios.push([
          'FAILED',
          allDigitalProductsData.data.failedScenarios || 0
        ]);
        this.steps.push([
          'PASSED',
          allDigitalProductsData.data.passedSteps || 0
        ]);
        this.steps.push([
          'FAILED',
          allDigitalProductsData.data.failedSteps || 0
        ]);
        this.steps.push([
          'SKIPPED',
          allDigitalProductsData.data.skippedSteps || 0
        ]);
        this.steps.push([
          'SUGGESTED',
          allDigitalProductsData.data.suggestedSteps || 0
        ]);
        this.steps.push([
          'PERFORMANCE_FAILURE',
          allDigitalProductsData.data.performanceFailure || 0]);
      });
      this.isAdmin = await this.isUserAdmin(axios, false);
      this.isAdminOrOAdmin = await this.isUserAdminOrOAdmin(axios, false);
      if (this.digitalProducts.length % 2 === 0 && this.isAdmin)
        this.isLeftVisible = true;
      else if (this.digitalProducts.length % 2 !== 0 && this.isAdmin)
        this.isRightVisible = true;
      await this.getEnviromentsData();
    },
    digProductUpdated(name, dpId) {
      let digProductIndex = this.digitalProductsWithDataOdd.findIndex(e => e.id == dpId);

      if (digProductIndex == -1) {
        digProductIndex = this.digitalProductsWithDataEven.findIndex(e => e.id == dpId);

        this.digitalProductsWithDataEven[digProductIndex]['name'] = name;
      } else {
        this.digitalProductsWithDataOdd[digProductIndex]['name'] = name;
      }
    },
    navigate(digitalProductName) {
      const route = `/organizations/${this.organizationName}/digital-products/${digitalProductName}/environments`;
      if (event.button == 0) this.$router.push(route);
      else if (event.button == 1) window.open(route);
    },
    async getUsersCount(entityId) {
      if (this.isAdmin) {
        this.userCount = (
          await axios.get(
            `/.netlify/functions/get-organization-users?organizationId=${entityId}`
          )
        ).data.length;
      }
    }
  }
};
