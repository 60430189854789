<template>
  <div class="page-wrapper">
    <div v-if="isAdminOrOAdmin" class="participants-bar">
      <a @click="openParticipantsDialog()"
      >{{ userCount !== undefined ? userCount : 'Loading' }} participants</a
      >
      <ParticipantsModal
        v-if="showParticipantsModal"
        :id="organizationId"
        :isAdmin="isAdminOrOAdmin"
        :name="organizationName"
        :users="userCount"
        viewType="organization"
        @userInteraction="getUsersCount"
        @on-close="showParticipantsModal = false"
      />
    </div>
    <div v-if="!isAdminOrOAdmin" class="separator"></div>
    <ProgressBar/>
    <h1 class="page-title">
      {{ digitalProductsPageYaml.title }}
    </h1>
    <div class="digitalProducts-big-pie-charts">
      <div v-if="!didResponseCome" class="skeletonCircles">
        <Skeleton shape="circle" size="250px"/>
        <Skeleton shape="circle" size="250px"/>
        <Skeleton shape="circle" size="250px"/>
      </div>
      <BigPieCharts
        v-if="didResponseCome"
        :data1="buckets"
        :data2="scenarios"
        :data3="steps"
        @getStatistics="updateStatistics"
      />
    </div>
    <div class="rectangles-container">
      <div class="rectangle-col-1">
        <!-- platfrom loader placeholders odd -->
        <div v-for="(digProduct) of digitalProducts" :key="digProduct.name" class="oddCompanyPlaceholder">
          <div v-if="!didResponseCome" class="custom-skeleton">
            <Skeleton height="39px"/>
            <div class="circles">
              <Skeleton shape="circle" size="150px"/>
              <Skeleton shape="circle" size="150px"/>
              <Skeleton shape="circle" size="150px"/>
            </div>
          </div>
        </div>
        <PieChartRectangle
          v-for="digitalProduct of digitalProductsWithDataOdd"
          :key="digitalProduct.id"
          :data1="digitalProduct.buckets"
          :data2="digitalProduct.scenarios"
          :data3="digitalProduct.steps"
          :isAdmin="isAdmin"
          :name="digitalProduct.name"
          :token="digitalProduct.token"
          @on-edit="editDigProduct(digitalProduct.id, digitalProduct.name, digitalProduct.token)"
          @on-navigate="navigate(digitalProduct.name)"
        />
        <AddEntityBox
          v-show="isLeftVisible"
          :text="'Add digital product'"
          @click="showAddDigitalProductDialog = true"
        />
      </div>
      <div class="rectangle-col-2">
        <!-- digitalProduct loader placeholder even -->
        <div v-for="(digProduct) of digitalProductsWithDataEven" :key="digProduct.name" class="EvenCompanyPlaceholder">
          <div v-if="!didResponseCome" class="custom-skeleton">
            <Skeleton height="39px"/>
            <div class="circles">
              <Skeleton shape="circle" size="150px"/>
              <Skeleton shape="circle" size="150px"/>
              <Skeleton shape="circle" size="150px"/>
            </div>
          </div>
        </div>
        <PieChartRectangle
          v-for="digitalProduct of digitalProductsWithDataEven"
          :key="digitalProduct.id"
          :data1="digitalProduct.buckets"
          :data2="digitalProduct.scenarios"
          :data3="digitalProduct.steps"
          :isAdmin="isAdmin"
          :name="digitalProduct.name"
          :token="digitalProduct.token"
          @on-edit="editDigProduct(digitalProduct.id, digitalProduct.name)"
          @on-navigate="navigate(digitalProduct.name)"
        />
        <AddEntityBox
          v-show="isRightVisible"
          :text="'Add digital product'"
          @click="showAddDigitalProductDialog = true"
        />
      </div>
      <addDigitalProductDialog
        v-if="showAddDigitalProductDialog"
        @on-close="showAddDigitalProductDialog = false"
        @update-products="updateProducts"
      />
      <EditDigitalProductModal
        v-if="showEditDigitalProductModal"
        :id="currentlyEditingDigitalProductID"
        :name="currentlyEditingDigitalProductName"
        :token="currentlyEditingDigitalProductToken"
        @digitalProductUpdate="digProductUpdated"
        @on-close="showEditDigitalProductModal = false"
      />
    </div>
  </div>
</template>

<script src="./digitalProducts.js"></script>

<style lang="scss" scoped src="./digitalProducts.scss"></style>
