import piechartRectangleYaml from 'js-yaml-loader!../../../../content/piechart-rectangle.yaml';
import {rolesEnumHelperMixin} from '../../mixins/rolesEnumHelperMixin';
import GooglePieChart from '@/app/components/googlePieChart/googlePieChart.vue';

export default {
  name: 'PieChartRectangle',
  components: {GooglePieChart},
  mixins: [
    rolesEnumHelperMixin
  ],
  props: {
    name: String,
    scenarios: Number,
    steps: Number,
    status: String,
    performanceFailure: Number,
    suggestedSteps: Number
  },
  data: function () {
    return {
      buildingImg: '',
      text1: '',
      text2: '',
      text3: '',
      stepsData: [],
    };
  },
  mounted() {
    this.buildingImg = piechartRectangleYaml.buildingImg;
    this.text1 = piechartRectangleYaml.text1;
    this.text2 = piechartRectangleYaml.text2;
    this.text3 = piechartRectangleYaml.text3;
    this.stepsData = [['PASSED', this.steps - 1], ['FAILED', this.status === 'passed' ? 0 : 1], ['SUGGESTED', this.suggestedSteps], ['PERFORMANCE_FAILURE', this.performanceFailure]]
  },
  methods: {},
};
