import axios from '../../../axios';
import ProgressBar from '../../components/progressBar/progressBar.vue';
import PieChartRectangle from '../../components/pieChartRectangle/pieChartRectangle.vue';
import AddBucketDialog from '../../components/addBucketDialogue/addBucketDialog.vue';
import BigPieCharts from '../../components/bigPieCharts/bigPieCharts.vue';
import bucketsYaml from 'js-yaml-loader!../../../../content/buckets.yaml';
import AddEntityBox from '../../components/addEntityBox/addEntityBox.vue';
import { canShowParticipantsModalMixin } from '../../mixins/canShowParticipantsModalMixin';
import ParticipantsModal from '../../components/modals/participantsModal/participantsModal.vue';
import { toastHelperMixin } from '../../mixins/toastHelperMixin';
import EditBucketModal from '../../components/modals/editBucketModal/editBucketModal.vue';
import { checkIfAdminMixin } from '../../mixins/checkIfAdminMixin';

export default {
  name: 'Bucket',
  mixins: [canShowParticipantsModalMixin, toastHelperMixin, checkIfAdminMixin],
  components: {
    AddBucketDialog,
    PieChartRectangle,
    BigPieCharts,
    ProgressBar,
    AddEntityBox,
    ParticipantsModal,
    EditBucketModal
  },
  data: function() {
    return {
      type: undefined,
      isAdmin: false,
      title: '',
      buckets: [],
      bucketsYaml: bucketsYaml,
      isRightVisible: false,
      isLeftVisible: false,
      organizationName: this.$route.params['organization_name'],
      digitalProductName: this.$route.params['digital_product_name'],
      environmentName: this.$route.params['environment_name'],
      bucketsWithDataOdd: [],
      bucketsWithDataEven: [],
      bucketFeatures: [],
      scenarios: [],
      steps: [],
      period: 'month',
      showAddBucketDialog: false,
      digitalProductId: undefined,
      organizationId: undefined,
      environmentId: undefined,
      userCount: undefined,
      showEditBucketModal: false,
      currentlyEditingBucketID: undefined,
      didResponseCome: false,
      currentlyEditingBucketName: undefined
    };
  },
  async created() {
    this.digitalProductId = await axios.post('/.netlify/functions/get-digital-product-id', {
      digitalProductName: this.digitalProductName
    }).then((res) => res.data.digitalProductId);
    this.environmentId = await axios.post('/.netlify/functions/get-environment-id', {
      digitalProductId: this.digitalProductId,
      environmentName: this.environmentName
    }).then((res) => res.data.environmentId);
    await axios.get('/.netlify/functions/get-user-preferences').then(data => {
      this.type = data.data.userPreference.type;
    });
    const bucketData = await axios.post('/.netlify/functions/get-buckets', {
      digitalProductId: this.digitalProductId,
      environmentName: this.environmentName
    });
    this.buckets = bucketData.data;
    for (const bucket of this.buckets) {
      axios.post('/.netlify/functions/get-statistics-buckets', {
        type: this.type,
        bucketName: bucket.name,
        environmentName: this.environmentName
      }).then((allBucketsData) => {
        this.didResponseCome = true;
        this.bucketFeatures.push([
          'PASSED',
          allBucketsData.data.passedBuckets || 0
        ]);
        this.bucketFeatures.push([
          'FAILED',
          allBucketsData.data.failedBuckets || 0
        ]);
        this.scenarios.push([
          'PASSED',
          allBucketsData.data.passedScenarios || 0
        ]);
        this.scenarios.push([
          'FAILED',
          allBucketsData.data.failedScenarios || 0
        ]);
        this.steps.push([
          'PASSED',
          allBucketsData.data.passedSteps || 0
        ]);
        this.steps.push([
          'FAILED',
          allBucketsData.data.failedSteps || 0
        ]);
        this.steps.push([
          'SKIPPED',
          allBucketsData.data.skippedSteps || 0
        ]);
        this.steps.push([
          'SUGGESTED',
          allBucketsData.data.suggestedSteps || 0
        ]);
        this.steps.push([
          'PERFORMANCE_FAILURE',
          allBucketsData.data.performanceFailure || 0
        ]);
      });
    }
    this.isAdmin = await this.isUserAdmin(axios, false);
    if (this.buckets.length % 2 === 0 && this.isAdmin)
      this.isLeftVisible = true;
    else if (this.buckets.length % 2 !== 0 && this.isAdmin)
      this.isRightVisible = true;
    this.getCurrentReportsData();
    try {
      if (this.isAdmin) {
        const projectIdResponse = (
          await axios.post('/.netlify/functions/get-organization-id', {
            organizationName: this.organizationName
          })
        ).data;
        this.organizationId = projectIdResponse.organizationId;
        this.userCount = (
          await axios.get(
            `/.netlify/functions/get-organization-users?organizationId=${this.organizationId}`
          )
        ).data.length;
      }
    } catch (error) {
      this.showGenericErrorToast();
    }
  },
  async mounted() {
    this.bucketsYaml = bucketsYaml;
  },
  methods: {

    async getCurrentReportsData(period = Number(process.env.DEFAULT_PERIOD)) {
      this.bucketsWithDataOdd = [];
      this.bucketsWithDataEven = [];
      const requests = [];
      for (let i = 0; i < this.buckets.length; i++) {
        requests.push(axios.post('/.netlify/functions/get-statistics-reports', {
          bucketId: this.buckets[i].id,
          period: period
        }));
      }
      Promise.all(requests).then(results => {
        for (let i = 0; i < results.length; i++) {
          const data = results[i].data;
          const o = {
            name: this.buckets[i].name,
            id: this.buckets[i].id,
            type: this.buckets[i].type,
            features: [
              ['PASSED', data.passedBuckets],
              ['FAILED', data.failedBuckets]
            ],
            scenarios: [
              ['PASSED', data.passedScenarios],
              ['FAILED', data.failedScenarios]
            ],
            steps: [
              ['PASSED', data.passedSteps],
              ['FAILED', data.failedSteps],
              ['SKIPPED', data.skippedSteps],
              ['SUGGESTED', data.suggestedSteps],
              ['PERFORMANCE_FAILURE', data.performanceFailure]
            ]
          };
          if (i % 2 === 0) {
            this.bucketsWithDataOdd.push(o);
          } else {
            this.bucketsWithDataEven.push(o);
          }
        }
      });
    },

    updateStatistics(type, period) {
      this.bucketFeatures = [];
      this.scenarios = [];
      this.steps = [];
      axios.post('/.netlify/functions/get-statistics-environments', {
        type: type,
        period: period,
        digitalProductName: this.digitalProductName
      }).then((allEnvironmentsData) => {
        this.didResponseCome = true;
        this.bucketFeatures.push([
          'PASSED',
          allEnvironmentsData.data.passedBuckets || 0
        ]);
        this.bucketFeatures.push([
          'FAILED',
          allEnvironmentsData.data.failedBuckets || 0
        ]);
        this.scenarios.push([
          'PASSED',
          allEnvironmentsData.data.passedScenarios || 0
        ]);
        this.scenarios.push([
          'FAILED',
          allEnvironmentsData.data.failedScenarios || 0
        ]);
        this.steps.push([
          'PASSED',
          allEnvironmentsData.data.passedSteps || 0
        ]);
        this.steps.push([
          'FAILED',
          allEnvironmentsData.data.failedSteps || 0
        ]);
        this.steps.push([
          'SKIPPED',
          allEnvironmentsData.data.skippedSteps || 0
        ]);
        this.steps.push([
          'SUGGESTED',
          allEnvironmentsData.data.suggestedSteps || 0
        ]);
        this.steps.push([
          'PERFORMANCE_FAILURE',
          allEnvironmentsData.data.performanceFailure || 0
        ]);

        this.getCurrentReportsData(period);


      }).catch(() => this.didResponseCome = true);
    },
    editBucket(id, name) {
      this.currentlyEditingBucketID = this.environmentId;
      this.currentlyEditingBucketName = name;
      this.showEditBucketModal = true;
    },
    async updateBuckets() {
      this.bucketsWithDataEven = [];
      this.bucketsWithDataOdd = [];
      await axios
        .post('/.netlify/functions/get-buckets', {
          organizationName: this.organizationName,
          environmentName: this.environmentName
        })
        .then(data => {
          this.buckets = data.data;
        });
      if (this.buckets.length % 2 === 0 && this.isAdmin) {
        this.isLeftVisible = true;
        this.isRightVisible = false;
      } else if (this.buckets.length % 2 !== 0 && this.isAdmin) {
        this.isRightVisible = true;
        this.isLeftVisible = false;
      }

      const requests = [];
      let statistics = [];
      for (const digitalProduct of this.buckets) {
        requests.push(await axios.post('/.netlify/functions/get-statistics-environments',
          { digitalProductName: digitalProduct.name }));
      }
      Promise.all(requests).then(results => {
        for (let i = 0; i < results.length; i++) {
          const statistic = results[i];
          statistics.push(statistic);
        }
      });
      for (let i = 0; i < this.buckets.length; i++) {
        const statistic = statistics[i];
        const data = {
          name: this.buckets[i].name,
          id: this.buckets[i].id,
          features: [
            ['PASSED', statistic.data.passedBuckets],
            ['FAILED', statistic.data.failedBuckets]
          ],
          scenarios: [
            ['PASSED', statistic.data.passedScenarios],
            ['FAILED', statistic.data.failedScenarios]
          ],
          steps: [
            ['PASSED', statistic.data.passedSteps],
            ['FAILED', statistic.data.failedSteps],
            ['SKIPPED', statistic.data.skippedSteps],
            ['SUGGESTED', statistic.data.suggestedSteps],
            ['PERFORMANCE_FAILURE', statistic.data.performanceFailure || 0]
          ]
        };
        if (i % 2 === 0) {
          this.bucketsWithDataOdd.push(data);
        } else {
          this.bucketsWithDataEven.push(data);
        }
      }
    },
    async updateEnvironments() {
      this.bucketsWithDataEven = [];
      this.bucketsWithDataOdd = [];
      await axios
        .post('/.netlify/functions/get-environments', {
          digitalProductName: this.digitalProductName
        })
        .then(res => {
          this.buckets = res.data;
        });
      if (this.buckets.length % 2 == 0 && this.isAdmin) {
        this.isLeftVisible = true;
        this.isRightVisible = false;
      } else if (this.buckets.length % 2 != 0 && this.isAdmin) {
        this.isRightVisible = true;
        this.isLeftVisible = false;
      }

      const requests = [];
      for (let i = 0; i < this.buckets.length; i++) {
        requests.push(axios.post('/.netlify/functions/get-statistics-reports', {
          bucketId: this.buckets[i].id,
          environmentName: this.environmentName
        }));
      }
      Promise.all(requests).then(results => {
        for (let i = 0; i < results.length; i++) {
          const data = results[i];
          const o = {
            name: this.buckets[i].name,
            id: this.buckets[i].id,
            type: this.buckets[i].type,
            features: [
              ['PASSED', data.passedBuckets],
              ['FAILED', data.failedBuckets]
            ],
            scenarios: [
              ['PASSED', data.passedScenarios],
              ['FAILED', data.failedScenarios]
            ],
            steps: [
              ['PASSED', data.passedSteps],
              ['FAILED', data.failedSteps],
              ['SKIPPED', data.skippedSteps],
              ['SUGGESTED', data.suggestedSteps],
              ['PERFORMANCE_FAILURE', data.data.performanceFailure || 0]

            ]
          };
          if (i % 2 === 0) {
            this.bucketsWithDataOdd.push(o);
          } else {
            this.bucketsWithDataEven.push(o);
          }
        }
      });
    },
    bucketUpdated(name, envId) {
      let bucketIndex = this.bucketsWithDataOdd.findIndex(e => e.id == envId);

      if (bucketIndex == -1) {
        bucketIndex = this.bucketsWithDataEven.findIndex(e => e.id == envId);
        this.bucketsWithDataEven[bucketIndex]['name'] = name;
      } else {
        this.bucketsWithDataOdd[bucketIndex]['name'] = name;
      }
    },
    navigate(environmentName) {
      // eslint-disable-next-line
      const route = `/organizations/${this.organizationName}/digital-products/${this.digitalProductName}/environments/${this.environmentName}/buckets/${environmentName}/scenarios`;
      if (event.button == 0) this.$router.push(route);
      else if (event.button == 1) window.open(route);
    },
    async getUsersCount(entityId) {
      if (this.isAdmin) {
        this.userCount = (
          await axios.get(
            `/.netlify/functions/get-organization-users?organizationId=${entityId}`
          )
        ).data.length;
      }
    }
  }
};
