<template>
  <p-dialog :aria-modal="null"
            :breakpoints="{'2400px': '40vw', '1060px': '50vw', '640px': '100vw'}"
            :modal=true
            :visible="show"
            @update:visible="$emit('on-close')">
    <template #header>
      <h3>Add new digital product</h3>
    </template>
    <div class="modal-card">
      <section class="modal-card-body">
        <!-- ------------------------------------------------------- -->
        <VeeForm v-slot="{handleSubmit}" :validation-schema="schema" as="div">
          <form id="a-form" @submit="handleSubmit($event,inviteUserToDigitalProduct())">
            <Field v-slot="{ field, errorMessage }" name="digitalName">
              <div class="input-field">
                <label>Digital Product Name:</label>
                <span class="p-input-icon-left">
                  <i class="pi pi-building"/>
                  <p-input v-model="digitalProductName"
                           :class="{ 'p-invalid': errorMessage }"
                           placeholder="Digital Product Name"
                           v-bind="field"
                  />
                </span>
                <small class="p-error">{{ errorMessage }}</small>
              </div>
            </Field>
          </form>
        </VeeForm>
      </section>
    </div>
    <template #footer>
      <div class="submit-btn">
        <p-button
          :loading="disableButton"
          form="a-form"
          type="submit"
        >
          <span>Done</span></p-button
        >
      </div>
    </template>
  </p-dialog>
</template>

<script src="./addDigitalProductDialog.js"></script>

<style lang="scss" scoped src="./addDigitalProductDialog.scss"></style>
