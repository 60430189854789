import navbarsYaml from 'js-yaml-loader!../../../../content/navbars.yaml';

export default {
  name: 'TopNavigationSmall',
  data: function () {
    return {
      topSmallNavbarLinks: [],
    };
  },
  mounted() {
    this.topSmallNavbarLinks = navbarsYaml.topSmallNavbarLinks;
  },
  methods: {
    navigate(index) {
      if (index === 0) {
        this.$router.push('/');
      }
    },
  },
};
