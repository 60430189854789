export const dateHelperMixin = {
  methods: {
    // DD:MM:YYYY HH:mm
    formatDateTime(date) {
      if (!date) return 'Unknown'
      const moment = require('moment-timezone');
      const dateMoment = moment(date).tz('Europe/Brussels');
      if (dateMoment.minute() < 10) dateMoment.set({minute:0})
      return dateMoment.format('DD-MM-YYYY HH:mm');
    },
    // DD:MM:YYYY HH:mm:ss
    formatDateTimeFull(date) {
      if (!date) return 'Unknown'
      const moment = require('moment-timezone');
      const dateMoment = moment(date).tz('Europe/Brussels');
      if (dateMoment.minute() < 10) dateMoment.set({minute:0})
      return dateMoment.format('DD-MM-YYYY HH:mm:ss');
    },
    // On MMM DDst
    formatDateArticle(date) {
      if (!date) return 'Unknown'
      date = new Date(date);
      let day;
      const month = new Intl.DateTimeFormat('en', {month: 'long'}).format(date);
      switch (date.getDate()) {
        case 1:
        case 21:
        case 31:
          day = date.getDate() + 'st';
          break;
        case 2:
        case 22:
          day = date.getDate() + 'nd';
          break;
        case 3:
        case 23:
          day = date.getDate() + 'rd';
          break;
        default:
          day = date.getDate() + 'th';
          break;
      }
      return `On ${month} ${day}`;
    }
  }
}
