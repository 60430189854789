<template>
  <div class="current-report-dropdowns-container">
    <!-- Loading placeholders -->
    <div v-for="(n, index) in 6" :key="index">
      <Skeleton
        v-if="!accordionScenariosWithOddData.length"
      >
      </Skeleton>
    </div>
    <div class="rectangles-container">
      <div class="rectangle-col-1">
        <div
          v-for="(scenario, scenarioIndex) of accordionScenariosWithOddData"
          :key="scenarioIndex.toString() + scenario.name"
        >
          <ScenarioRectangle :name="scenario.name"
                             :status="scenario.status ? 'passed' : 'failed'"
                             :steps="scenario.stepsCount"
                             :performanceFailure = "scenario.performanceFailure"
                             :suggestedSteps = "scenario.suggestedSteps"
                             @on-navigate="navigate(scenario.name)">

          </ScenarioRectangle>
        </div>
      </div>
      <div class="rectangle-col-2">
        <div
          v-for="(scenario, scenarioIndex) of accordionScenariosWithEvenData"
          :key="scenarioIndex.toString() + scenario.name"
        >
          <ScenarioRectangle :name="scenario.name"
                             :status="scenario.status ? 'passed' : 'failed'"
                             :steps="scenario.stepsCount"
                             :performanceFailure = "scenario.performanceFailure"
                             :suggestedSteps = "scenario.suggestedSteps"
                             @on-navigate="navigate(scenario.name)">


          </ScenarioRectangle>
        </div>

      </div>
    </div>
  </div>
</template>

<script src='./scenarioList.js'></script>

<style lang="scss" scoped src='./scenarioList.scss'></style>
