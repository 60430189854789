import axios from '../../../axios';

export default {
  name: 'CurrentScenarioAccordion',
  components: {},
  props: {
    reportDataFeatures: Array
  },
  data: function() {
    return {
      accordionScenarios: [],
      environmentName: this.$route.params['environment_name'],
      digitalProductName: this.$route.params['digital_product_name'],
      organizationName: this.$route.params['organization_name'],
      repositoryLocation: '',
      liveBranch: '',
      repositoryHostingService: '',
      hostingServiceImg: '',
      waitForResponse: false, // loader for features tabs
      waitForStepsResponse: false, // loader for steps
      currentFeatureIndex: '', // current feature array index
      currentFeatureId: '', // current feature id in DB
      showStepIndex: '', // on which step to show the loader
      scrolled: true,
      currentPage: 0,
      isKPMG: false
    };
  },
  async created() {
    if (this.organizationName.includes('KPMG')) {
      this.isKPMG = true;
    }
    for (let reportDataFeature of this.reportDataFeatures) {
      this.accordionScenarios.push({
        name: reportDataFeature.name,
        status: reportDataFeature.status,
        stepsCount: reportDataFeature.stepsCount,
        id: reportDataFeature.stepRef,
        openedSteps: []
      });
      await this.openStepsDropdown(0, this.accordionScenarios[0].id);
    }
  },
  methods: {

    async openStepsDropdown(scenarioIndex, scenarioId) {
      if (
        this.accordionScenarios[scenarioIndex]
          .openedSteps.length === 0
      ) {
        this.waitForStepsResponse = true;

        await axios
          .post('/.netlify/functions/get-steps', {
            scenarioId: scenarioId
          })
          .then(({ data }) => {
            for (const dataStep of data.steps) {
              this.accordionScenarios[scenarioIndex].openedSteps.push({
                ...dataStep,
                openedEmbedding: dataStep.embeddingId ? false : undefined,
                openedConsoleError: false,
                openedHTTPSError: false,
                image: undefined
              });
            }
          });
        this.waitForStepsResponse = false;
      }
      this.accordionScenarios[scenarioIndex].openedScenario = !this.accordionScenarios[scenarioIndex].openedScenario;
    },
    async openScreenshot(scenarioIndex, stepIndex, embeddingId) {
      const step = this.accordionScenarios[scenarioIndex].openedSteps[stepIndex];
      if (!step.image) {
        step['waitForScreenshot'] = true;
        if (step.imageCompareKey) {
          const screenshotData = (await axios.post('/.netlify/functions/get-compared-screenshot', {
            embeddingId: embeddingId,
            imageCompareKey: step.imageCompareKey,
            organization: this.organizationName
          })).data;
          step.expected = screenshotData.expected;
          step.actual = screenshotData.actual;
          step.difference = screenshotData.difference;
        } else {
          await axios.post('/.netlify/functions/get-embedding', {
            embeddingId: embeddingId
          })
            .then(async (data) => {
              step.image = data.data;
            });
        }

        step['waitForScreenshot'] = false;
      }
      step.openedEmbedding = !step.openedEmbedding;
    },

    async replaceExpectedScreenshot(scenarioIndex, stepIndex, embeddingId) {
      const step = this.accordionScenarios[scenarioIndex].openedSteps[stepIndex];
      step['waitForReplaceScreenshot'] = true;
      const screenshotData = (await axios.post('/.netlify/functions/change-compared-screenshot', {
        embeddingId: embeddingId,
        imageCompareKey: step.imageCompareKey,
        organization: this.organizationName
      })).data;
      step.expected = screenshotData.expected;
      step.actual = screenshotData.actual;
      step.difference = screenshotData.difference;
      step['waitForReplaceScreenshot'] = false;
    },

    async openConsoleError(scenarioIndex, stepIndex) {
      this.accordionScenarios[scenarioIndex].openedSteps[stepIndex].openedConsoleError = !this.accordionScenarios[
        scenarioIndex].openedSteps[stepIndex].openedConsoleError;
    },

    async openHTTPError(scenarioIndex, stepIndex) {
      this.accordionScenarios[scenarioIndex].openedSteps[stepIndex].openedHTTPSError = !this.accordionScenarios[
        scenarioIndex].openedSteps[stepIndex].openedHTTPSError;
    },

    async openLatestReportUrl(scenarioIndex, stepIndex) {
      const errorMessage = JSON.parse(this.accordionScenarios[scenarioIndex].openedSteps[stepIndex].errorMessage);
      const httpsMessage = errorMessage.https.reverse();
      let latestReportUrl;
      for (const httpsMessageElement of httpsMessage) {
        if (httpsMessageElement.includes('/api/reports/')) {
          const httpsMessageElementFormatted = httpsMessageElement.split(' ')[1];
          latestReportUrl = httpsMessageElementFormatted.substring(0, httpsMessageElementFormatted.split('/', 6).join('/').length).replace('api/', '');
          break;
        }
      }
      window.open(latestReportUrl);
    },

    formatErrorMessage(errorMessageRaw, type) {
      const result = [];
      const errorMessage = JSON.parse(errorMessageRaw);
      if (errorMessage[type]) {
        const arrayFromData = Array.from(errorMessage[type]);
        const parts = arrayFromData.join('\n');
        result.push(parts.toString());
        return result.join('\n');
      }
    },
    calculateMilliseconds(duration) {
      if (!duration) return;
      return `${Math.floor(duration / 1000)}s ${duration % 1000}ms`;
    }
  }
};
