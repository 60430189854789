
import { createRouter, createWebHistory } from 'vue-router'
import Login from '../app/pages/login/login.vue'
import Organizations from '../app/pages/organizations/organizations.vue'
import DigitalProducts from '../app/pages/digitalProducts/digitalProducts.vue'
import Reports from '../app/pages/reports/reports.vue'
import Scenarios from '../app/pages/scenarios/scenarios.vue'
import CurrentReport from '../app/pages/currentReport/currentReport.vue'
import Home from '../app/pages/home/home.vue'
import Registration from '../app/pages/registration/registration.vue'
import Article from '../app/pages/article/article.vue'
import forgotPassword from '../app/pages/forgotPassword/forgotPassword.vue';
import changePassword from '../app/pages/changePassword/changePassword.vue';
import Environments from '../app/pages/environments/environments.vue'
import currentScenario from '../app/pages/currentScenario/currentScenario.vue';

// Layouts
import SidebarLayout from '../app/layouts/sidebarLayout/sidebarLayout.vue'
import Buckets from '../app/pages/buckets/buckets.vue';
import reportsOverview from '../app/pages/reportsOverview/reportsOverview.vue';
import manualReports from '../app/pages/manualReports/manualReports.vue';
import reportsCharts from '../app/pages/reportsCharts/reportsCharts.vue'


const routes = [
  {
    path: '',
    redirect: '',
    name: 'Home',
    component: Home
  },
  {
    path: '/:inviteKey',
    name: 'InvitedUserRegistration',
    component: Home
  },
  {
    path: '/forgot-password',
    name: 'forgotPassword',
    component: forgotPassword
  },
  {
    path: '/reset/password/:key',
    name: 'changePassword',
    component: changePassword
  },
  {
    path: '/registration',
    name: 'Registration',
    component: Registration
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/organizations',
    component: SidebarLayout,
    children: [
      {
        path: '',
        name: 'organizations',
        component: Organizations
      },
      {
        path: '/reports-overview',
        name: 'ReportsOverview',
        component: reportsOverview
      },
      {
        path: '/manual-reports',
        name: 'ManualReports',
        component: manualReports
      },
      {
        path:'/reports/:organization/:digitalProduct/:name/:periodicalId',
        name: 'report',
        component: reportsCharts,
        props: true
      },
      {
        path: ':organization_name/digital-products',
        name: 'digitalProducts',
        component: DigitalProducts
      },
      {
        path: ':organization_name/digital-products/:digital_product_name/environments',
        name: 'Environments',
        component: Environments
      },
      {
        path: ':organization_name/digital-products/:digital_product_name/environments/:environment_name/buckets',
        name: 'Buckets',
        component: Buckets
      },
      {
        // eslint-disable-next-line max-len
        path: ':organization_name/digital-products/:digital_product_name/environments/:environment_name/buckets/:bucket_name/scenarios',
        name: 'scenarios',
        component: Scenarios
      },
      {
        // eslint-disable-next-line max-len
        path: ':organization_name/digital-products/:digital_product_name/environments/:environment_name/buckets/:bucket_name/scenarios/:scenario_name/reports',
        name: 'reports',
        component: Reports
      },
      {
        // eslint-disable-next-line max-len
        path: ':organization_name/digital-products/:digital_product_name/environments/:environment_name/buckets/:bucket_name/scenarios/:scenario_name/reports/:report_id',
        name: 'currentReport',
        component: CurrentReport
      },
      {
        // eslint-disable-next-line max-len
        path: ':organization_name/digital-products/:digital_product_name/environments/:environment_name/buckets/:bucket_name/scenarios/:scenario_name/reports/:report_id/steps',
        name: 'currentScenario',
        component: currentScenario
      },
    ]
  },
  {
    path: '/:template/:content',
    name: 'article',
    component: Article
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return {
        selector: to.hash
        // , offset: { x: 0, y: 10 }
      }
    } else {
      return {
        x: 0,
        y: 0
      };
    }
  }
})


export default router
