import HeaderLogo from '../headerLogo/headerLogo.vue';
import navbarsYaml from 'js-yaml-loader!../../../../content/navbars.yaml';
import {navigateAfterLoginMixin} from '../../mixins/navigateAfterLoginMixin';

export default {
  name: 'TopNavigationBar',
  components: {
    HeaderLogo,
  },
  props: {
    logoColor: String,
  },
  mixins: [
    navigateAfterLoginMixin,
  ],
  data: function () {
    return {
      listItems: [],
      listItemsBurger: [],
      buttonLogin: '',
      buttonRegister: '',
      buttonText: '',
      openedBurgerMenu: false,
    };
  },
  mounted() {
    this.listItems = navbarsYaml.articlesNavbar.articleNavbarLinks;
    this.listItemsBurger = navbarsYaml.articlesNavbarBurger.articleNavbarLinks;
    this.buttonText = navbarsYaml.articlesNavbar.buttonText;
    this.buttonLogin = navbarsYaml.articlesNavbarBurger.buttonLogin;
    this.buttonRegister = navbarsYaml.articlesNavbarBurger.buttonRegister;
  },
  methods: {
    async navigate(index) {
      if (index === 0) {
        this.$router.push('/');
      }
      if (index === 1) {
        if (localStorage.token)
          await this.navigateAfterLogin();
        else
          this.$router.push('/login');
      }
    },
    getStarted() {
      this.$router.push('/registration');
    },
    async toLogin() {
      if (localStorage.token)
        await this.navigateAfterLogin();
      else
        this.$router.push('/login');
    },
  },
};
