import * as d3 from 'd3';
import VueDatePicker from '@vuepic/vue-datepicker';
import axios from '@/axios';
import TextArea from 'primevue/textarea';

export default {
  name: 'ReportsCharts',
  components: {
    'vue-date-picker': VueDatePicker,
    TextArea: TextArea,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    organization: {
      type: String,
      required: true,
    },
    digitalProduct: {
      type: String,
      required: true,
    },
    periodicalId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showAddReport: false,
      showReport: {},
      reports: [],
      reportValues: [],
      dateFrom: '',
      dateTo: '',
      totalTimeTestRun: [],
      costOfBugs: [],
      allReports: [],
      fieldColors: {
        completedItems: 'Maroon',
        automatableItems: 'DodgerBlue',
        automatedItems: 'ForestGreen',
        bugsByDevelopers: 'Purple',
        bugsByTA: 'DarkCyan',
        bugsByBusiness: 'Orange',
        bugsByEndUser: 'Pink',
        hoursForDevelopment: 'PaleTurquoise',
        hoursForTA: 'MediumVioletRed',
      },
    };
  },
  mounted() {
    this.getManualReports();
    this.getTAreports();
  },
  watch: {
    name() {
      this.resetData();
    },
  },
  methods: {
    resetData() {
      this.showAddReport = false;
      this.showReport = {};
      this.reports = [];
      this.reportValues = [];
      this.createChart();
    },
    toggleReport(report) {
      this.showReport[report.key] = !this.showReport[report.key];
      this.getTotalTime(report);
      this.saveReportValues(report);
    },
    updateStartDate(dateFrom) {
      this.dateFrom = dateFrom;
    },
    updateEndDate(dateTo) {
      this.dateTo = dateTo;
    },
    formatDate(date) {
      let dateObject = new Date(date);
      let month = dateObject.getMonth() + 1;
      let day = dateObject.getDate();
      let year = dateObject.getFullYear();
      let formattedDate = `${month.toString().padStart(2, '0')}/${day
        .toString()
        .padStart(2, '0')}/${year}`;

      return formattedDate;
    },
    async getManualReports() {
      await axios
        .get(
          `/.netlify/functions/get-manual-reports?periodicalId=${this.periodicalId}`,
          {}
        )
        .then((res) => {
          res.data.forEach((r) => {
            this.showReport[r.name] = false;
            this.totalTimeTestRun[r.key] = r.totalTimeTestRun;
            this.reports.push(r);
            this.saveReportValues(r);
          });
        });
    },
    async getLastReport() {
      await axios
        .get(
          `/.netlify/functions/get-manual-reports?periodicalId=${this.periodicalId}`,
          {}
        )
        .then((res) => {
          this.reports.push(res.data[res.data.length - 1]);
        });
    },
    async addReport() {
      const name = document.getElementById('reportName').value;
      let reportDescription = '';
      if (name != '' && this.dateFrom != '' && this.dateTo != '') {
        const totalSeconds = await this.getTotalTimeOnCreation(this.dateFrom, this.dateTo);
        await axios.post(
          `/.netlify/functions/add-report-to-periodical?periodicalId=${this.periodicalId}`,
          {
            name: name,
            key: name,
            dateFrom: this.dateFrom,
            dateTo: this.dateTo,
            description: reportDescription,
            totalTimeTestRun: totalSeconds ?? null,
            costOfBugs: null,
            values: {
              completedItems: null,
              automatableItems: null,
              automatedItems: null,
              notAutomatedItems: null,
              completedItemsUntilThisPeriod: null,
              automatableItemsUntilThisPeriod: null,
              automatedItemsUntilThisPeriod: null,
              notAutomatedItemsUntilThisPeriod: null,
              bugsByDevelopers: null,
              bugsByTA: null,
              bugsByBusiness: null,
              bugsByEndUser: null,
              hoursForDevelopment: null,
              hoursForTA: null,
              hoursForManualTesting: null,
            },
          }
        );
        this.showAddReport = false;
      }
      this.getLastReport();
      this.dateFrom = '';
      this.dateTo = '';
      this.sortAndComputeDynamicReportValues();
    },
    async getTAreports() {
      let response = await axios.post('/.netlify/functions/get-reports', {});
      this.allReports = JSON.parse(JSON.stringify(response.data));
    },
    async getTotalTimeOnCreation(dateFrom, dateTo){
      let totalTimeSeconds = 0;
      const response = await axios.get(`/.netlify/functions/get-archived-reports`);
      for(let r of response.data){
        if(this.formatDate(dateFrom) <= this.formatDate(r.start) && this.formatDate(dateTo) >= this.formatDate(r.end)){
          totalTimeSeconds += parseInt(r.duration)
        }
      }
      return totalTimeSeconds;
    },
    async getTotalTime(report) {
      let totalTimeSeconds = 0;
      const response = await axios.get(`/.netlify/functions/get-archived-reports`);
      for(let r of response.data){
        if(this.formatDate(report.dateFrom) <= this.formatDate(r.start) && this.formatDate(report.dateTo) >= this.formatDate(r.end)){
          totalTimeSeconds += parseInt(r.duration)
        }
      }
      this.totalTimeTestRun[report.key] = totalTimeSeconds;
      this.saveReportValues(report)
    },

    convertSecondsToTime(seconds) {
      const h = Math.floor(seconds / 3600);
      const m = Math.floor((seconds % 3600) / 60);
      const s = seconds % 60;

      return `${h}h, ${m}m, ${s}s`;
    },
    calculateCostOfBugs(report) {
      let devMultiplier = 2;
      let auxTaMultiplier = 4;
      let businessTeamMultiplier = 6;
      let endUserMultiplier = 10;

      this.costOfBugs[report.key] =
        report.values.bugsByDevelopers * devMultiplier +
        report.values.bugsByTA * auxTaMultiplier +
        report.values.bugsByBusiness * businessTeamMultiplier +
        report.values.bugsByEndUser * endUserMultiplier;
    },
    async saveReportValues(report) {
      const {
        completedItems,
        automatableItems,
        automatedItems,
        bugsByDevelopers,
        bugsByTA,
        bugsByBusiness,
        bugsByEndUser,
        hoursForDevelopment,
        hoursForManualTesting,
        hoursForTA,
      } = report.values;

      if (
        !isNaN(completedItems) &&
        !isNaN(automatableItems) &&
        !isNaN(automatedItems)
      ) {
        const includedFields = [
          'completedItems',
          'automatableItems',
          'automatedItems',
        ];
        this.createChart(
          includedFields,
          'Speed and Quantity',
          'chart1',
          this.fieldColors
        );
      }
      if (
        !isNaN(bugsByDevelopers) &&
        !isNaN(bugsByTA) &&
        !isNaN(bugsByBusiness) &&
        !isNaN(bugsByEndUser)
      ) {
        const includedFields = [
          'bugsByDevelopers',
          'bugsByTA',
          'bugsByBusiness',
          'bugsByEndUser',
        ];
        this.createChart(includedFields, 'Quality', 'chart2', this.fieldColors);
      }
      if (
        !isNaN(hoursForDevelopment) &&
        !isNaN(hoursForTA) &&
        !isNaN(hoursForManualTesting)
      ) {
        const includedFields = hoursForManualTesting
          ? ['hoursForDevelopment', 'hoursForTA', 'hoursForManualTesting']
          : ['hoursForDevelopment', 'hoursForTA'];
        this.createChart(
          includedFields,
          'Time and Efficiency',
          'chart3',
          this.fieldColors
        );
      }
      this.sortAndComputeDynamicReportValues();
      this.calculateCostOfBugs(report);
      await axios.post(
        `/.netlify/functions/add-values-to-report?periodicalId=${this.periodicalId}&reportId=${report._id}`,
        {
          description: report.description,
          costOfBugs: this.costOfBugs[report.key],
          totalTimeTestRun: this.totalTimeTestRun[report.key],
          values: report.values,
        }
      );
    },

    sortAndComputeDynamicReportValues() {
      this.reports.forEach((report) => {
        report.dateFrom = new Date(report.dateFrom);
        report.dateTo = new Date(report.dateTo);
      });
      this.reports.sort((a, b) => a.dateTo.getTime() - b.dateTo.getTime());

      for (let i = 0; i < this.reports.length; i++) {
        const last = this.reports[i - 1];
        const current = this.reports[i];

        const accumulatedAllCompeltedItems =
          last?.values.completedItemsUntilThisPeriod ?? 0;
        current.values.completedItemsUntilThisPeriod =
          accumulatedAllCompeltedItems + (current.values.completedItems ?? 0);

        const accumulatedAllAutomatableItems =
          last?.values.automatableItemsUntilThisPeriod ?? 0;
        current.values.automatableItemsUntilThisPeriod =
          accumulatedAllAutomatableItems +
          (current.values.automatableItems ?? 0);

        const accumulatedAllAutomatedItems =
          last?.values.automatedItemsUntilThisPeriod ?? 0;
        current.values.automatedItemsUntilThisPeriod =
          accumulatedAllAutomatedItems + (current.values.automatedItems ?? 0);

        const notAutomatedItems =
          current.values.automatableItems > 0 &&
          current.values.automatedItems > 0
            ? current.values.automatableItems - current.values.automatedItems
            : 0;
        current.values.notAutomatedItems = notAutomatedItems;

        const accumulatedAllNotAutomatedItems =
          last?.values.notAutomatedItemsUntilThisPeriod ?? 0;
        current.values.notAutomatedItemsUntilThisPeriod =
          accumulatedAllNotAutomatedItems +
          (current.values.notAutomatedItems ?? 0);
      }
    },
    createChart(includedFields, title, chart, fieldColors) {
      if (this.reports.length === 0) return;

      const margin = { top: 20, right: 30, bottom: 40, left: 40 };
      const width = 500;
      const height = 400;

      const chartContainer = this.$refs[chart];
      d3.select(chartContainer).selectAll('*').remove();

      const flatData1 = [];
      this.reports.forEach((report, reportIndex) => {
        Object.entries(report.values).forEach(([fieldName, value]) => {
          if (
            includedFields.includes(fieldName) &&
            value !== null &&
            value !== undefined &&
            value !== ''
          ) {
            flatData1.push({
              reportIndex: reportIndex,
              reportName: report.name,
              fieldIndex: includedFields.indexOf(fieldName),
              fieldName: fieldName,
              value: value,
            });
          }
        });
      });

      function legend() {
        const legendContainer = d3
          .select(chartContainer)
          .append('div')
          .attr('class', 'legend')
          .style('display', 'flex')
          .style('flex-wrap', 'wrap')
          .style('justify-content', 'center');

        includedFields.forEach((fieldName) => {
          const legendItem = legendContainer
            .append('div')
            .attr('class', 'legend-item')
            .style('display', 'flex')
            .style('align-items', 'center')
            .style('margin-right', '15px')
            .style('margin-bottom', '10px');

          legendItem
            .append('div')
            .attr('class', 'legend-color')
            .style('width', '20px')
            .style('height', '20px')
            .style('background-color', fieldColors[fieldName])
            .style('border', '1px solid black')
            .style('margin-right', '5px');

          legendItem
            .append('div')
            .attr('class', 'legend-label')
            .style('font-size', '14px')
            .text(fieldName);
        });
      }

      const setBarOpacity = (selection, reportName) => {
        const isActive = this.showReport[reportName];
        const allActive = Object.values(this.showReport).every((val) => val);
        const allInactive = Object.values(this.showReport).every((val) => !val);
        selection.style(
          'opacity',
          allActive || allInactive || isActive ? 1 : 0.2
        );
      };

      if (title === 'Speed and Quantity') {
        const nestedData = this.reports.map((report, reportIndex) => {
          const nestedItems = [
            {
              fieldName: 'automatableItems',
              value: report.values.automatableItems,
            },
            {
              fieldName: 'automatedItems',
              value: report.values.automatedItems,
            },
          ].filter(
            (item) =>
              item.value !== null &&
              item.value !== undefined &&
              item.value !== ''
          );

          return {
            reportName: report.name,
            completedItems: report.values.completedItems,
            nestedItems: nestedItems,
            reportIndex: reportIndex,
          };
        });

        const x = d3
          .scaleBand()
          .domain(nestedData.map((d) => d.reportName))
          .range([0, width])
          .padding(0.1);

        const y = d3
          .scaleLinear()
          .domain([0, d3.max(nestedData, (d) => d.completedItems)])
          .nice()
          .range([height, 0]);

        const tooltip = d3
          .select(chartContainer)
          .append('div')
          .attr('class', 'tooltip')
          .style('opacity', 0)
          .style('position', 'absolute')
          .style('pointer-events', 'none')
          .style('background-color', 'white')
          .style('border', '1px solid #ddd')
          .style('padding', '8px')
          .style('border-radius', '5px')
          .style('box-shadow', '0 0 10px rgba(0,0,0,0.1)')
          .style('text-align', 'center');

        const svg = d3
          .select(chartContainer)
          .append('svg')
          .attr('width', width + margin.left + margin.right)
          .attr('height', height + margin.top + margin.bottom)
          .append('g')
          .attr('transform', `translate(${margin.left},${margin.top})`);

        svg
          .append('text')
          .attr('x', width / 2)
          .attr('y', 0 - margin.top / 2)
          .attr('text-anchor', 'middle')
          .style('font-size', '14px')
          .style('font-weight', 'bold')
          .text(title);

        legend();

        svg
          .selectAll('.bar-group')
          .data(nestedData)
          .enter()
          .append('g')
          .attr('class', 'bar-group')
          .attr(
            'transform',
            (d) => `translate(${x(d.reportName) + x.bandwidth() / 4}, 0)`
          )
          .each(function (d) {
            const barGroup = d3.select(this);

            barGroup
              .append('rect')
              .attr('class', 'bar completedItems')
              .attr('x', 0)
              .attr('y', y(d.completedItems))
              .attr('width', x.bandwidth() / 2)
              .attr('height', height - y(d.completedItems))
              .attr('fill', fieldColors.completedItems)
              .attr('stroke', 'black')
              .attr('stroke-width', 1)
              .each(function () {
                setBarOpacity(d3.select(this), d.reportName);
              })
              .on('mouseover', function (event) {
                tooltip.transition().duration(200).style('opacity', 0.9);
                tooltip
                  .html(
                    `<strong>${d.reportName}</strong><br>${d.completedItems} completed items`
                  )
                  .style('left', `${event.pageX}px`)
                  .style('top', `${event.pageY - 40}px`);
              })
              .on('mouseout', function () {
                tooltip.transition().duration(500).style('opacity', 0);
              });

            const nestedX = d3
              .scaleBand()
              .domain(d.nestedItems.map((item) => item.fieldName))
              .range([0, x.bandwidth() / 2])
              .padding(0.1);

            barGroup
              .selectAll('.nested-bar')
              .data(d.nestedItems)
              .enter()
              .append('rect')
              .attr('class', 'bar nested-bar')
              .attr('x', (item) => nestedX(item.fieldName))
              .attr('y', (item) => y(item.value))
              .attr('width', nestedX.bandwidth())
              .attr('height', (item) => height - y(item.value))
              .attr('fill', (item) => fieldColors[item.fieldName])
              .attr('stroke', 'black')
              .attr('stroke-width', 1)
              .each(function () {
                setBarOpacity(d3.select(this), d.reportName);
              })
              .on('mouseover', function (event, item) {
                let percentage;
                if (item.fieldName === 'automatableItems') {
                  percentage = ((100 * item.value) / d.completedItems).toFixed(
                    1
                  );
                  tooltip.transition().duration(200).style('opacity', 0.9);
                  tooltip
                    .html(
                      `<strong>${d.reportName}</strong><br>${percentage}% of completed items <br> are automatable (${item.value})`
                    )
                    .style('left', `${event.pageX}px`)
                    .style('top', `${event.pageY - 40}px`);
                } else if (item.fieldName === 'automatedItems') {
                  percentage = (
                    (100 * item.value) /
                    d.nestedItems.find(
                      (nestedItem) =>
                        nestedItem.fieldName === 'automatableItems'
                    ).value
                  ).toFixed(1);
                  tooltip.transition().duration(200).style('opacity', 0.9);
                  tooltip
                    .html(
                      `<strong>${d.reportName}</strong><br>${percentage}% of automatable items <br> are automated (${item.value})`
                    )
                    .style('left', `${event.pageX}px`)
                    .style('top', `${event.pageY - 40}px`);
                }
              })
              .on('mouseout', function () {
                tooltip.transition().duration(500).style('opacity', 0);
              });
          });

        svg
          .append('g')
          .attr('class', 'x-axis')
          .attr('transform', `translate(0,${height})`)
          .call(d3.axisBottom(x));

        svg
          .append('g')
          .attr('class', 'y-axis')
          .call(d3.axisLeft(y).ticks(10).tickFormat(d3.format('~s')));
      }

      if (title != 'Speed and Quantity') {
        const x1 = d3
          .scaleBand()
          .domain(d3.range(this.reports.length))
          .range([0, width])
          .padding(0.1);

        const y1 = d3
          .scaleLinear()
          .domain([0, d3.max(flatData1, (d) => d.value)])
          .nice()
          .range([height / 2, 0]);

        const tooltip1 = d3
          .select(chartContainer)
          .append('div')
          .attr('class', 'tooltip')
          .style('opacity', 0)
          .style('position', 'absolute')
          .style('pointer-events', 'none')
          .style('background-color', 'white')
          .style('border', '1px solid #ddd')
          .style('padding', '8px')
          .style('border-radius', '5px')
          .style('box-shadow', '0 0 10px rgba(0,0,0,0.1)')
          .style('text-align', 'center');

        const svg1 = d3
          .select(chartContainer)
          .append('svg')
          .attr('width', width + margin.left + margin.right)
          .attr('height', height / 2 + margin.top + margin.bottom)
          .append('g')
          .attr('transform', `translate(${margin.left},${margin.top})`);

        svg1
          .append('text')
          .attr('x', width / 2)
          .attr('y', 0 - margin.top / 2)
          .attr('text-anchor', 'middle')
          .style('font-size', '14px')
          .style('font-weight', 'bold')
          .text(title);

        legend();

        svg1
          .selectAll('.bar')
          .data(flatData1)
          .enter()
          .append('rect')
          .attr('class', 'bar')
          .attr(
            'x',
            (d) =>
              x1(d.reportIndex) +
              (d.fieldIndex * x1.bandwidth()) / includedFields.length
          )
          .attr('y', (d) => y1(d.value))
          .attr('width', x1.bandwidth() / includedFields.length)
          .attr('height', (d) => height / 2 - y1(d.value))
          .attr('fill', (d) => fieldColors[d.fieldName])
          .attr('stroke', 'black')
          .attr('stroke-width', 1)
          .each(function (d) {
            setBarOpacity(d3.select(this), d.reportName);
          })
          .on('mouseover', function (event, d) {
            tooltip1.transition().duration(200).style('opacity', 0.9);
            tooltip1
              .html(`<strong>${d.fieldName}</strong>: ${d.value}`)
              .style('left', `${event.pageX}px`)
              .style('top', `${event.pageY - 40}px`);
          })
          .on('mouseout', function () {
            tooltip1.transition().duration(500).style('opacity', 0);
          });

        svg1
          .append('g')
          .attr('class', 'x-axis')
          .attr('transform', `translate(0,${height / 2})`)
          .call(d3.axisBottom(x1).tickFormat((d, i) => this.reports[i].name));

        svg1.append('g').attr('class', 'y-axis').call(d3.axisLeft(y1));
        svg1.selectAll('.bar, .x-axis .tick text').style('font-size', '12px');
      }

      const x2 = d3
        .scaleBand()
        .domain(d3.range(includedFields.length))
        .range([0, width])
        .padding(0.1);

      const y2 = d3
        .scaleLinear()
        .domain([0, d3.max(flatData1, (d) => d.value)])
        .nice()
        .range([height / 2, 0]);

      const tooltip2 = d3
        .select(chartContainer)
        .append('div')
        .attr('class', 'tooltip')
        .style('opacity', 0)
        .style('position', 'absolute')
        .style('pointer-events', 'none')
        .style('background-color', 'white')
        .style('border', '1px solid #ddd')
        .style('padding', '8px')
        .style('border-radius', '5px')
        .style('box-shadow', '0 0 10px rgba(0,0,0,0.1)')
        .style('text-align', 'center');

      const svg2 = d3
        .select(chartContainer)
        .append('svg')
        .attr('width', width + margin.left + margin.right)
        .attr('height', height / 2 + margin.top + margin.bottom)
        .style('display', 'block')
        .append('g')
        .attr('transform', `translate(${margin.left},${margin.top})`);

      svg2
        .selectAll('.bar')
        .data(flatData1)
        .enter()
        .append('rect')
        .attr('class', 'bar')
        .attr(
          'x',
          (d) =>
            x2(d.fieldIndex) +
            (d.reportIndex * x2.bandwidth()) / this.reports.length
        )
        .attr('y', (d) => y2(d.value))
        .attr('width', x2.bandwidth() / this.reports.length)
        .attr('height', (d) => height / 2 - y2(d.value))
        .attr('fill', (d) => fieldColors[d.fieldName])
        .attr('stroke', 'black')
        .attr('stroke-width', 1)
        .each(function (d) {
          setBarOpacity(d3.select(this), d.reportName);
        })
        .on('mouseover', function (event, d) {
          tooltip2.transition().duration(200).style('opacity', 0.9);
          tooltip2
            .html(
              `<strong>${d.fieldName}</strong>: ${d.value}<br>Report: ${d.reportName}`
            )
            .style('left', `${event.pageX}px`)
            .style('top', `${event.pageY - 40}px`);
        })
        .on('mouseout', function () {
          tooltip2.transition().duration(500).style('opacity', 0);
        });

      svg2
        .append('g')
        .attr('class', 'x-axis')
        .attr('transform', `translate(0,${height / 2})`)
        .call(d3.axisBottom(x2).tickFormat((i) => includedFields[i]));

      svg2.append('g').attr('class', 'y-axis').call(d3.axisLeft(y2));

      svg2.selectAll('.bar, .x-axis .tick text').style('font-size', '12px');
    },
  },
};
