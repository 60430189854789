<template>
  <div class="changepass-page-container">
    <div class="changepass-page-content">
      <div
        class="changepass-page-left"
      >
        <div class="auxta-logo">
          <HeaderLogo :logo-color="'white'"/>
        </div>
        <div class="title">
          {{ title }}
        </div>
        <div class="text page-white-text">
          {{ text }}
        </div>
        <div class="phone page-white-text">
          <img :src="phoneIcon" class="data-icon"/>{{ phoneNumber }}
        </div>
        <div class="mail page-white-text">
          <img :src="mailIcon" class="data-icon"/>{{ mail }}
        </div>
        <div class="bottom-suggestion">
          <div class="suggestion-text">
            {{ alreadyHaveAnAccountQuestion }}
          </div>
          <a class="suggestion-link" @click="toLogin()">{{
              alreadyHaveAnAccountLink
            }}</a>
        </div>
      </div>
      <div class="changepass-page-right">
        <TopNavigationSmall/>

        <div class="changepass-form-container">
          <form class="changepass-form" @submit.prevent="resetPassword()">
            <div class="formTextHeader">{{ changePasswordYaml.header }}</div>
            <!-- password -->
            <div class="w-100 password-input">
              <div class="changepass-form-helper-text helper-text-password">
                <div>
                  {{ changePasswordYaml.passwordText }}
                </div>
              </div>
              <input
                v-model="password"
                :type="showPassword ? 'text' : 'password'"
                class="input-password"
                v-on:input="validatePassword()"
              />
              <img
                :src="
                  !showPassword
                    ? changePasswordYaml.eyeIcon
                    : changePasswordYaml.eyeSlashed
                "
                class="eye"
                type="button"
                @click="showPassword = !showPassword"
              />
            </div>

            <span class="passHeaderText">{{
                changePasswordYaml.passReqsHeader
              }}</span>
            <div class="password-requirements">
              <ul>
                <li :class="{ valid: isValidLength }">
                  {{ 'at least 12 characters long' }}
                </li>
                <li :class="{ valid: hasUpperAndLower }">
                  {{ 'at least one uppercase and lowercase letter' }}
                </li>
                <li :class="{ valid: hasNumber }">
                  {{ 'at least one number' }}
                </li>
              </ul>
            </div>

            <!-- confirm password -->
            <div class="w-100 password-input">
              <div class="changepass-form-helper-text helper-text-password">
                <div>
                  {{ changePasswordYaml.confirmPassword }}
                </div>
              </div>
              <input
                v-model="verifyPassword"
                :type="showVerifyPassword ? 'text' : 'password'"
                class="input-password"
                v-on:input="checkIfPasswordsMatch()"
              />
              <img
                :src="
                  !showVerifyPassword
                    ? changePasswordYaml.eyeIcon
                    : changePasswordYaml.eyeSlashed
                "
                class="eye"
                type="button"
                @click="showVerifyPassword = !showVerifyPassword"
              />
            </div>
            <!-- error Message -->
            <div
              v-if="validatePassword"
              class="alert alert-danger customize-alert"
              role="alert"
              style="color: red"
            >
              {{ errorText }}
            </div>
            <div class="button-and-spinner">
              <p-button
                :disabled="waitForResponse"
                :loading="waitForResponse"
                class="send-invite-btn"
                @click="resetPassword"
              >
            <span>{{
                changePasswordYaml.confirmBtn
              }}</span></p-button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <FooterComponent/>
  </div>
</template>

<script src="./changePassword.js"></script>

<style lang="scss" scoped src="./changePassword.scss"></style>
