import ProgressBar from '../../components/progressBar/progressBar.vue';
import PieChartRectangle from '../../components/pieChartRectangle/pieChartRectangle.vue';
import addDigitalProductDialog from '../../components/addDigitalProductDialog/addDigitalProductDialog.vue';
import BigPieCharts from '../../components/bigPieCharts/bigPieCharts.vue';
import reportsOverviewPageYaml from 'js-yaml-loader!../../../../content/reportsOverview.yaml';
import AddEntityBox from '../../components/addEntityBox/addEntityBox.vue';
import { canShowParticipantsModalMixin } from '../../mixins/canShowParticipantsModalMixin';
import ParticipantsModal from '../../components/modals/participantsModal/participantsModal.vue';
import { toastHelperMixin } from '../../mixins/toastHelperMixin';
import EditDigitalProductModal from '../../components/modals/editDigitalProductModal/editDigitalProductModal.vue';
import { checkIfAdminMixin } from '../../mixins/checkIfAdminMixin';
import { dateHelperMixin } from '@/app/mixins/dateHelperMixin';
import axios from '@/axios';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import { ref } from 'vue';

export default {
  name: 'ReportsOverview',
  mixins: [canShowParticipantsModalMixin, toastHelperMixin, checkIfAdminMixin, dateHelperMixin],
  components: {
    'vue-date-picker': VueDatePicker,
    addDigitalProductDialog,
    PieChartRectangle,
    BigPieCharts,
    ProgressBar,
    AddEntityBox,
    ParticipantsModal,
    EditDigitalProductModal
  },
  data: function() {
    return {
      organizations: [],
      organizationId: undefined,
      organizationName: undefined,
      digitalProducts: [],
      digitalProductId: undefined,
      digitalProductName: undefined,
      environments: [],
      environmentId: undefined,
      environmentName: undefined,
      buckets: [],
      bucketId: undefined,
      bucketName: undefined,
      scenarios: [],
      scenarioName: undefined,
      scenarioId: undefined,
      responses: [false, false, false, false, false],
      status: undefined,
      statusArray: [{ value: '-' }, { value: 'passed' }, { value: 'failed' }],
      reportsOverviewPageYaml: reportsOverviewPageYaml,
      reports: [],
      didResponseCome: false,
      currentPage: 0,
      dateFrom: undefined,
      dateTo: undefined,
      lastTag: undefined,
      startTimeFrom: ref({ hours: 0, minutes: 0, seconds: 0 }),
      endTimeTo: ref({ hours: 0, minutes: 0, seconds: 0 }),
      totalScenarios: [],
      totalScenariosSelected: undefined,
      areTotalScenariosFailed: false
    };
  },
  async created() {
    await axios.get('/.netlify/functions/get-organizations').then(data => {
      this.organizations = data.data;
    });
    await axios.get('/.netlify/functions/get-filter').then(async data => {
      if (data.data.filterData) {
        if (data.data.filterData.organizationId) {
          this.organizationId = data.data.filterData.organizationId;
          this.organizationName = data.data.filterData.organizationName;
          await this.updateOrganization(this.organizationName, this.organizationId, false);
        }
        if (data.data.filterData.digitalProductId) {
          this.digitalProductId = data.data.filterData.digitalProductId;
          this.digitalProductName = data.data.filterData.digitalProductName;
          await this.updateDigitalProduct(this.digitalProductName, this.digitalProductId, false);
        }
        if (data.data.filterData.environmentId) {
          this.environmentId = data.data.filterData.environmentId;
          this.environmentName = data.data.filterData.environmentName;
          await this.updateEnvironment(this.environmentName, this.environmentId, false);
        }
        if (data.data.filterData.bucketId) {
          this.bucketId = data.data.filterData.bucketId;
          this.bucketName = data.data.filterData.bucketName;
          await this.updateBucket(this.bucketName, this.bucketId, false);
        }
        if (data.data.filterData.scenarioName) {
          this.scenarioName = data.data.filterData.scenarioName;
          await this.updateScenario(this.scenarioName, false);
        }
        if (data.data.filterData.dateFrom) {
          this.dateFrom = data.data.filterData.dateFrom;
        }
        if (data.data.filterData.dateTo) {
          this.dateTo = data.data.filterData.dateTo;
        }
        if (data.data.filterData.status) {
          this.status = data.data.filterData.status;
        }
        await this.getTableData();
      }

    });
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.onScroll);
  },
  async mounted() {
    this.reportsOverviewPageYaml = reportsOverviewPageYaml;
    this.$nextTick(function() {
      window.addEventListener('scroll', this.onScroll);
      this.onScroll();
    });
  },

  methods: {
    async getTableData() {
      if (this.organizationId || this.dateFrom || this.dateTo || this.status) {
        axios.post('/.netlify/functions/get-reports-overview-list', {
          organizationId: this.organizationId,
          digitalProductId: this.digitalProductId,
          environmentId: this.environmentId,
          bucketId: this.bucketId,
          scenarioName: this.scenarioName,
          dateFrom: this.dateFrom,
          dateTo: this.dateTo,
          status: this.status,
          page: this.currentPage
        }).then(data => {
          if (data.data.totalScenarios) {
            this.totalScenarios = data.data.totalScenarios;
          } else {
            this.totalScenarios = [];
          }
          if (data.data.areTotalScenariosFailed) {
            this.areTotalScenariosFailed = data.data.areTotalScenariosFailed;
          } else {
            this.areTotalScenariosFailed = false;
          }
          for (let dataEntry of data.data.page) {
            this.reports.push(dataEntry);
          }
          if (data.data.length !== 20) {
            this.maxPageReached = true;
          } else {
            this.currentPage++;
          }
          setTimeout(() => {
            this.scrolled = false;
          }, 1000);
        });
      }
    },
    async updateOrganization(organizationName, organizationId = undefined, getData = true) {
      let saveFilter = true;
      if (getData) {
        await this.clearFilter(0);
      }
      if (organizationName) {
        this.organizationName = organizationName;
      } else {
        this.organizationName = this.organizations.find((element) => element.id === organizationId).name;
        organizationName = this.organizationName;
      }
      if (!this.organizationId) {
        this.organizationId = this.organizations.find((element) => element.name === organizationName).id;
      }
      await axios.post('/.netlify/functions/get-digital-products', {
        organizationName: organizationName
      }).then(async data => {
        this.digitalProducts = data.data;
        this.responses[1] = true;
        if (this.digitalProducts.length === 1) {
          saveFilter = false;
          await this.updateDigitalProduct(this.digitalProducts[0].name);
        }
      });
      if (getData) {
        await axios.post('/.netlify/functions/get-reports-overview-list', {
          organizationId: this.organizationId, status: this.status, dateFrom: this.dateFrom,
          dateTo: this.dateTo
        }).then(data => {
          if (data.data.totalScenarios) {
            this.totalScenarios = data.data.totalScenarios;
          } else {
            this.totalScenarios = [];
          }
          if (data.data.areTotalScenariosFailed) {
            this.areTotalScenariosFailed = data.data.areTotalScenariosFailed;
          } else {
            this.areTotalScenariosFailed = false;
          }
          this.reports = data.data.page;
        });
        if (saveFilter) {
          await this.saveSearchFilter();
        }
      }
    },
    async updateDigitalProduct(digitalProductName, digitalProductId = undefined, getData = true) {
      let saveFilter = true;
      if (getData) {
        await this.clearFilter(1);
      }
      if (digitalProductName) {
        this.digitalProductName = digitalProductName;
      } else {
        this.digitalProductName = this.digitalProducts.find((element) => element.id === digitalProductId).name;
        digitalProductName = this.digitalProductName;
      }
      if (!this.digitalProductId) {
        this.digitalProductId = this.digitalProducts.find((element) => element.name === digitalProductName).id;
      }
      await axios.post('/.netlify/functions/get-environments', {
        digitalProductName: digitalProductName
      }).then(data => {
        this.environments = data.data;
        this.responses[2] = true;
        if (this.environments.length === 1) {
          this.updateEnvironment(this.environments[0].name);
          saveFilter = false;
        }
      });
      if (getData) {
        await axios.post('/.netlify/functions/get-reports-overview-list', {
          organizationId: this.organizationId,
          digitalProductId: this.digitalProductId,
          dateFrom: this.dateFrom,
          dateTo: this.dateTo,
          status: this.status
        }).then(data => {
          if (data.data.totalScenarios) {
            this.totalScenarios = data.data.totalScenarios;
          } else {
            this.totalScenarios = [];
          }
          if (data.data.areTotalScenariosFailed) {
            this.areTotalScenariosFailed = data.data.areTotalScenariosFailed;
          } else {
            this.areTotalScenariosFailed = false;
          }
          this.reports = data.data.page;
        });
        if (saveFilter) {
          await this.saveSearchFilter();
        }
      }
    },
    async updateEnvironment(environmentName, environmentId = undefined, getData = true) {
      let saveFilter = true;
      if (getData) {
        await this.clearFilter(2);
      }
      if (environmentName) {
        this.environmentName = environmentName;
      } else {
        this.environmentName = this.environments.find((element) => element.id === environmentId).name;
        environmentName = this.environmentName;
      }
      if (!this.environmentId) {
        this.environmentId = this.environments.find((element) => element.name === environmentName).id;
      }
      await axios.post('/.netlify/functions/get-buckets', {
        digitalProductId: this.digitalProductId,
        environmentName: environmentName
      }).then(data => {
        this.buckets = [];
        this.buckets.push({ name: '-' });
        this.buckets.push(...data.data);
        this.responses[3] = true;
        if (this.buckets.length === 1) {
          this.updateBucket(this.buckets[0].name);
          saveFilter = false;
        }
      });
      if (getData) {
        await axios.post('/.netlify/functions/get-reports-overview-list', {
          organizationId: this.organizationId,
          digitalProductId: this.digitalProductId,
          environmentId: this.environmentId,
          dateFrom: this.dateFrom,
          dateTo: this.dateTo,
          status: this.status
        }).then(data => {
          if (data.data.totalScenarios) {
            this.totalScenarios = data.data.totalScenarios;
          } else {
            this.totalScenarios = [];
          }
          if (data.data.areTotalScenariosFailed) {
            this.areTotalScenariosFailed = data.data.areTotalScenariosFailed;
          } else {
            this.areTotalScenariosFailed = false;
          }
          this.reports = data.data.page;
        });
        if (saveFilter) {
          await this.saveSearchFilter();
        }
      }
    },

    async updateBucket(bucketName, bucketId = undefined, getData = true) {
      let saveFilter = true;
      if (getData) {
        await this.clearFilter(3);
      }
      if (bucketName !== '-') {
        if (bucketName) {
          this.bucketName = bucketName;
        } else {
          this.bucketName = this.buckets.find((element) => element.id === bucketId).name;
          bucketName = this.bucketName;
        }
        if (!this.bucketId) {
          this.bucketId = this.buckets.find((element) => element.name === bucketName).id;
        }
        await axios.post('/.netlify/functions/get-scenarios-list-names', {
          bucketName: bucketName,
          environmentName: this.environmentName
        }).then(data => {
          this.scenarios = [];
          this.scenarios.push({ name: '-' });
          this.scenarios.push(...data.data);
          this.responses[4] = true;
          if (this.scenarios.length === 1) {
            this.updateScenario(this.scenarios[0].name);
            saveFilter = false;
          }
        });
      } else {
        this.bucketId = undefined;
        this.bucketName = undefined;
        this.responses[4] = false;
        this.scenarioName = undefined;
      }

      if (getData) {
        await axios.post('/.netlify/functions/get-reports-overview-list', {
          organizationId: this.organizationId,
          digitalProductId: this.digitalProductId,
          environmentId: this.environmentId,
          bucketId: this.bucketId,
          dateFrom: this.dateFrom,
          dateTo: this.dateTo,
          status: this.status
        }).then(data => {
          if (data.data.totalScenarios) {
            this.totalScenarios = data.data.totalScenarios;
          } else {
            this.totalScenarios = [];
          }
          if (data.data.areTotalScenariosFailed) {
            this.areTotalScenariosFailed = data.data.areTotalScenariosFailed;
          } else {
            this.areTotalScenariosFailed = false;
          }
          this.reports = data.data.page;
        });
        if (saveFilter) {
          await this.saveSearchFilter();
        }
      }
    },

    async updateScenario(scenarioName, getData = true) {
      if (getData) {
        await this.clearFilter(4);
      }
      if (scenarioName === '-') {
        this.scenarioName = undefined;
      } else {
        this.scenarioName = scenarioName;
      }
      if (getData) {
        await axios.post('/.netlify/functions/get-reports-overview-list', {
          organizationId: this.organizationId,
          digitalProductId: this.digitalProductId,
          environmentId: this.environmentId,
          scenarioName: this.scenarioName,
          bucketId: this.bucketId,
          dateFrom: this.dateFrom,
          dateTo: this.dateTo,
          status: this.status
        }).then(data => {
          if (data.data.totalScenarios) {
            this.totalScenarios = data.data.totalScenarios;
          } else {
            this.totalScenarios = [];
          }
          if (data.data.areTotalScenariosFailed) {
            this.areTotalScenariosFailed = data.data.areTotalScenariosFailed;
          } else {
            this.areTotalScenariosFailed = false;
          }
          this.reports = data.data.page;
        });
        await this.saveSearchFilter();
      }
    },
    async filterHash(lastTag) {
      if (lastTag) {
        this.reports = this.reports.filter(element => {
          if (element.lastTag === lastTag) {
            return element;
          }
        });
      } else {
        await axios.post('/.netlify/functions/get-reports-overview-list', {
          organizationId: this.organizationId,
          digitalProductId: this.digitalProductId,
          environmentId: this.environmentId,
          bucketId: this.bucketId,
          scenarioName: this.scenarioName,
          dateFrom: this.dateFrom,
          dateTo: this.dateTo,
          status: this.status
        }).then(data => {
          if (data.data.totalScenarios) {
            this.totalScenarios = data.data.totalScenarios;
          } else {
            this.totalScenarios = [];
          }
          if (data.data.areTotalScenariosFailed) {
            this.areTotalScenariosFailed = data.data.areTotalScenariosFailed;
          } else {
            this.areTotalScenariosFailed = false;
          }
          this.reports = data.data.page;
        });
      }
      await this.saveSearchFilter();
    },
    async updateStatus(status) {
      if (status !== '-') {
        this.status = status;
      } else {
        this.status = undefined;
        status = undefined
      }

      await axios.post('/.netlify/functions/get-reports-overview-list', {
        organizationId: this.organizationId,
        digitalProductId: this.digitalProductId,
        environmentId: this.environmentId,
        bucketId: this.bucketId,
        scenarioName: this.scenarioName,
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
        status: status
      }).then(data => {
        if (data.data.totalScenarios) {
          this.totalScenarios = data.data.totalScenarios;
        } else {
          this.totalScenarios = [];
        }
        if (data.data.areTotalScenariosFailed) {
          this.areTotalScenariosFailed = data.data.areTotalScenariosFailed;
        } else {
          this.areTotalScenariosFailed = false;
        }
        this.reports = data.data.page;
      });
      await this.saveSearchFilter();
    },
    async clearFilter(type = -1) {
      this.totalScenarios = [];
      switch (type) {
        case 0:
          this.organizationId = undefined;
          this.digitalProductId = undefined;
          this.environmentId = undefined;
          this.bucketId = undefined;
          this.organizationName = undefined;
          this.digitalProductName = undefined;
          this.environmentName = undefined;
          this.bucketName = undefined;
          this.reports = [];
          break;
        case 1:
          this.scenarioName = undefined;
          this.digitalProductId = undefined;
          this.environmentId = undefined;
          this.bucketId = undefined;
          this.digitalProductName = undefined;
          this.environmentName = undefined;
          this.bucketName = undefined;
          this.reports = [];
          break;
        case 2:
          this.scenarioName = undefined;
          this.environmentId = undefined;
          this.bucketId = undefined;
          this.environmentName = undefined;
          this.bucketName = undefined;
          this.reports = [];
          break;
        case 3:
          this.scenarioName = undefined;
          this.bucketId = undefined;
          this.bucketName = undefined;
          this.reports = [];
          break;
        case 4:
          this.scenarioName = undefined;
          this.reports = [];
          break;
        default:
          this.status = undefined;
          this.dateFrom = undefined;
          this.dateTo = undefined;
          this.organizationId = undefined;
          this.digitalProductId = undefined;
          this.environmentId = undefined;
          this.bucketId = undefined;
          this.organizationName = undefined;
          this.digitalProductName = undefined;
          this.environmentName = undefined;
          this.bucketName = undefined;
          this.reports = [];
          this.responses = [false, false, false, false, false];
          await this.saveSearchFilter(true);
          break;
      }
    },
    async updateStartDate(dateFrom) {
      this.dateFrom = dateFrom;
      await axios.post('/.netlify/functions/get-reports-overview-list', {
        organizationId: this.organizationId,
        digitalProductId: this.digitalProductId,
        environmentId: this.environmentId,
        bucketId: this.bucketId,
        scenarioName: this.scenarioName,
        dateFrom: dateFrom,
        dateTo: this.dateTo,
        status: this.status
      }).then(data => {
        if (data.data.totalScenarios) {
          this.totalScenarios = data.data.totalScenarios;
        }
        if (data.data.areTotalScenariosFailed) {
          this.areTotalScenariosFailed = data.data.areTotalScenariosFailed;
        }
        this.reports = data.data.page;
      });
      await this.saveSearchFilter();
    },
    async updateEndDate(dateTo) {
      this.dateTo = dateTo;
      await axios.post('/.netlify/functions/get-reports-overview-list', {
        organizationId: this.organizationId,
        digitalProductId: this.digitalProductId,
        environmentId: this.environmentId,
        bucketId: this.bucketId,
        scenarioName: this.scenarioName,
        dateFrom: this.dateFrom,
        dateTo: dateTo,
        status: this.status
      }).then(data => {
        if (data.data.totalScenarios) {
          this.totalScenarios = data.data.totalScenarios;
        }
        if (data.data.areTotalScenariosFailed) {
          this.areTotalScenariosFailed = data.data.areTotalScenariosFailed;
        }
        this.reports = data.data.page;
      });
      await this.saveSearchFilter();
    },
    async onScroll() {
      if (this.reports.length > 0) {
        if (window.innerHeight + window.scrollY >= document.body.scrollHeight - 1 &&
          !this.scrolled &&
          !this.maxPageReached
        ) {
          this.scrolled = true;
          await this.getTableData();
        }
      }

    },
    async saveSearchFilter(toDelete = false) {
      await axios.post('/.netlify/functions/post-update-filter', {
        organizationId: this.organizationId,
        digitalProductId: this.digitalProductId,
        environmentId: this.environmentId,
        bucketId: this.bucketId,
        scenarioName: this.scenarioName,
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
        status: this.status,
        toDelete: toDelete
      });
    },
    async navigateToCurrentReport(reportId) {
      let url;
      let href;
      await axios.post('/.netlify/functions/get-reports-overview-url', {
        reportId: reportId
      }).then(data => {
        url = data.data.url;
        href = data.data.href;
      });
      window.open(href + url);

    },
    async totalScenariosUpdate() {
      this.totalScenariosSelected = this.totalScenarios[0].value;
    }
  }
};
