<template>
  <div class="current-report-dropdowns-container">
    <!-- Loading placeholders -->
    <div v-for="(n, index) in 6" :key="index">
      <Skeleton
        v-if="!accordionScenarios.length"
      >
      </Skeleton>
    </div>
    <div class="scenarios-container">
      <div
        v-for="(scenario, scenarioIndex) of accordionScenarios"
        :key="scenarioIndex.toString() + scenario.name"
      >
        <p-progress
          v-if="waitForStepsResponse"
          mode="indeterminate"
        ></p-progress>
        <div
          v-if="this.accordionScenarios[scenarioIndex].openedScenario"
          class="steps-container"
        >
          <div
            v-for="(step, stepsIndex) of scenario.openedSteps"
            :key="stepsIndex.toString() + step.name"
          >
            <div class="current-report-dropdowns-steps">
              <div class="left-side">
                <div
                  :class="`${step.status}`"
                  class="keyword"
                >
                  Step:
                </div>
                <div class="name" :id='stepsIndex + 1'> <span class='tag' v-if='step.tag'>{{step.tag}}</span> {{ step.keyword }} {{ step.name }}</div>
                <div v-if="step.embeddingId">
                  <button @click="openScreenshot(scenarioIndex,stepsIndex,step.embeddingId)">
                    Open Screenshot
                  </button>
                  <i v-if="accordionScenarios[scenarioIndex]
                          .openedSteps[stepsIndex].waitForScreenshot"
                     class="pi pi-spin pi-spinner"
                  ></i>

                  <button class='replace-expected-screenshot' v-if="accordionScenarios[scenarioIndex].openedSteps[stepsIndex].openedEmbedding &&
            this.accordionScenarios[scenarioIndex].openedSteps[stepsIndex].imageCompareKey" @click="replaceExpectedScreenshot(scenarioIndex,stepsIndex, step.embeddingId)">
                    Replace 'expected' with 'actual'
                  </button>
                  <i v-if="accordionScenarios[scenarioIndex]
                          .openedSteps[stepsIndex].waitForReplaceScreenshot"
                     class="pi pi-spin pi-spinner"
                  ></i>
                </div>
                <div class="error-message-button" v-if="step.errorMessage">
                  <button @click="openConsoleError(scenarioIndex,stepsIndex,step.embeddingId)">
                    Open Console Error Message
                  </button>
                  <button @click="openHTTPError(scenarioIndex,stepsIndex,step.embeddingId)">
                    Open HTTP Error Message
                  </button>
                  <button v-if='isKPMG' @click="openLatestReportUrl(scenarioIndex,stepsIndex)">
                    Open Latest Report Url
                  </button>
                </div>
              </div>
              <div class="right-side">
                {{ calculateMilliseconds(step.duration) }}
              </div>
            </div>
            <div v-if="accordionScenarios[scenarioIndex].openedSteps[stepsIndex].openedEmbedding &&
            !this.accordionScenarios[scenarioIndex].openedSteps[stepsIndex].imageCompareKey"
                 class="current-report-dropdowns-steps-embeddings">
              <embed :src="`data:image/png;base64,${step.image}`"/>
            </div>
            <div v-if="accordionScenarios[scenarioIndex].openedSteps[stepsIndex].openedEmbedding &&
            this.accordionScenarios[scenarioIndex].openedSteps[stepsIndex].imageCompareKey"
                 class="current-report-dropdowns-steps-embeddings">
              <div class='current-report-dropdowns-steps-embeddings-compare'>
                <span>Expected</span>
                <embed :src="`data:image/png;base64,${step.expected}`"/>
                <span>Actual</span>
                <embed :src="`data:image/png;base64,${step.actual}`"/>
                <span>Difference</span>
                <embed :src="`data:image/png;base64,${step.difference}`"/>
              </div>
            </div>
            <div v-if="step.openedConsoleError"
                 class="current-report-dropdowns-steps-error-messages">
              {{ formatErrorMessage(step.errorMessage,'console') }}
            </div>
            <div v-if="step.openedHTTPSError"
                 class="current-report-dropdowns-steps-error-messages">
              {{ formatErrorMessage(step.errorMessage,'https') }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src='./currentScenarioAccordion.js'></script>

<style lang="scss" scoped src='./currentScenarioAccordion.scss'></style>
