import { createApp } from 'vue'
import VueAxios from 'vue-axios'
import App from './App.vue'
import router from './router'
import axios from './axios'
import tabTextYaml from 'js-yaml-loader!../content/tab-text.yaml';
import PrimeVue from 'primevue/config'
import Button  from 'primevue/button'
import Dialog from 'primevue/dialog'
import Tag from 'primevue/tag'
import Dropdown from 'primevue/dropdown';
import ProgressBar from 'primevue/progressbar';
import ToastService from 'primevue/toastservice';
import InputText from 'primevue/inputtext';
import Skeleton from 'primevue/skeleton';
import {Field, Form} from 'vee-validate'
import 'primevue/resources/themes/saga-blue/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'


document.title = tabTextYaml.tabText;
if (localStorage.getItem('token') !== null) {
  const token = JSON.parse(localStorage.getItem('token'))
  const now = new Date()
  if (now.getTime() > token.expiryToken) {
    localStorage.removeItem('token');
  }
}
if (localStorage.getItem('rememberMeToken') !== null) {
  const rememberMeToken = JSON.parse(localStorage.getItem('rememberMeToken'))
  const now = new Date()
  if (now.getTime() > rememberMeToken.expiryToken) {
    localStorage.removeItem('rememberMeToken');
    localStorage.removeItem('token');
  } else {
    localStorage.setItem('token', JSON.stringify(rememberMeToken));
  }
}

axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    return Promise.reject(error);
  }
);


 const app = createApp(App)
 app.use(VueAxios,axios).use(router).use(ToastService).use(PrimeVue).mount('#app')
 app.component('p-button', Button)
 app.component('p-dialog', Dialog)
 app.component('p-input', InputText)
 app.component('p-tag', Tag)
app.component('Skeleton',Skeleton)
app.component('p-select', Dropdown)
app.component('VeeForm', Form)
app.component('Field',Field)
app.component('p-progress', ProgressBar)


