<template>
  <p-dialog :aria-modal="null"
            :modal="true"
            :visible="show"
            class="dark-dialog"
            @update:visible="$emit('on-close')">
    <template #header>
      <h3>Manage Participants</h3>
    </template>
    <div :class="{ loading: loading }" class="modal-card">
      <section class="modal-card-body">
        <div v-if="isAdmin" class="invite-section">
          <div class="input-field">
          <span class="p-input-icon-left">
            <i class="pi pi-building"/>
            <p-input v-model="inviteUserEmail" placeholder="Invite participants" type="text"/>
        </span>
          </div>
          <p-select v-model="inviteUserDomain"
                    :options="allowedDomains"
                    placeholder="Domain"
          >
          </p-select>
          <p-select
            v-model="inviteUserRole"
            :options="userRoles"
            class="invite-role-select"
            optionLabel="label"
            optionValue="value"
            placeholder="Select role"
          >
          </p-select>
          <p-button
            :disabled="
              !inviteUserEmail ||
              !inviteUserDomain ||
              inviteUserRole === undefined ||
              invitationRequestRunning
            "
            :loading="invitationRequestRunning"
            class="send-invite-btn"
            @click="sendInvitation"
          >
            <span>{{
                invitationRequestRunning ? 'Sending' : 'Send invite'
              }}</span></p-button
          >
        </div>
        <div :class="{ 'is-admin': isAdmin }" class="invitations-section">
          <table>
            <!-- user placeholders -->
            <tr v-if="!invitations.length">
              <Skeleton height="40px" width="35vw"></Skeleton>
            </tr>
            <template v-for="invitation of invitations" :key="invitation.email">
              <tr
                :class="{ invited: !invitation.accepted }"
              >
                <td class="invitation-col-icon">
                  <i class="pi pi-user"></i>
                </td>
                <td class="invitation-col-user">
                  <div class="invitation-field-name">{{ invitation.name }}</div>
                  <div class="invitation-field-email">
                    {{ invitation.email }}
                  </div>
                </td>
                <td class="invitation-col-status">
                  <label>{{ roleEnumToString(invitation.role) }}</label>
                  <em v-if="!invitation.accepted">Invited</em>
                </td>
                <td v-if="isAdmin" class="invitation-col-actions">
                  <div v-if="!invitation.accepted" class="invitation-links">
                    <a
                      :class="{
                        disabled: invitation.resendInvitationRequestRunning,
                      }"
                      class="resend-link"
                      @click="resendInvitation(invitation.email)"
                    ><i
                      v-if="invitation.resendInvitationRequestRunning"
                      class="pi pi-spin pi-spinner"
                    ></i>
                      <span>{{
                          invitation.resendInvitationRequestRunning
                            ? 'Wait...'
                            : 'Resend?'
                        }}</span></a
                    >
                    <a
                      :class="{ disabled: invitation.removeUserRequestRunning }"
                      class="remove-link"
                      @click="removeUser(invitation.email)"
                    ><i
                      v-if="invitation.removeUserRequestRunning"
                      class="pi pi-spin pi-spinner"
                    ></i>
                      <span>{{
                          invitation.removeUserRequestRunning ? 'Wait' : 'Delete'
                        }}</span></a
                    >
                  </div>
                  <p-button
                    v-if="invitation.accepted"
                    :disabled="invitation.removeUserRequestRunning"
                    :loading="invitation.removeUserRequestRunning"
                    class="p-button-danger"
                    @click="removeUser(invitation.email)"
                  >
                    <i
                      v-if="!invitation.removeUserRequestRunning"
                      class="pi pi-trash"
                    ></i>
                    <span style="margin-left: 10px">Remove</span>
                  </p-button>
                </td>
              </tr>
            </template>
          </table>
          <!-- <p v-if="!invitations.length" class="no-members-notice">
            {{ loading ? 'Loading...' : 'No members are added.' }}
          </p> -->
        </div>
      </section>
    </div>
    <template #footer>
      <p class="you-are-viewing">
        You are viewing participants for:<br/>
        <strong>{{ name }} ({{ viewTypeField }})</strong>
      </p>
      <p-button class="done-btn" @click="$emit('on-close')"
      >Done
      </p-button
      >
    </template>
  </p-dialog>
</template>

<script src="./participantsModal.js"></script>

<style lang="scss" scoped src="./participantsModal.scss"></style>
