<template>
  <div class="forgotPass-page-container">
    <div class="forgotPass-page-content">
      <div class="forgotPass-page-left">
        <div class="auxta-logo">
          <HeaderLogo :logo-color="'white'"/>
        </div>
        <div class="title">
          {{ frgpassYaml.title }}
        </div>
        <div class="text page-white-text">
          {{ frgpassYaml.text }}
        </div>
        <div class="phone page-white-text">
          <img :src="phoneIcon" class="data-icon"/>{{
            frgpassYaml.phoneNumber
          }}
        </div>
        <div class="mail page-white-text">
          <img :src="mailIcon" class="data-icon"/>{{ frgpassYaml.mail }}
        </div>
        <div class="bottom-suggestion">
          <a class="suggestion-link" @click="toLogin()">{{
              frgpassYaml.alreadyHaveAnAccountLink
            }}</a>
        </div>
      </div>
      <div class="forgotPass-page-right">
        <TopNavigationSmall/>
        <form class="forgot-pass-form" @submit.prevent="sendEmail()">
          <div class="forgotPassHeader">
            {{ frgpassYaml.forgotPassHeader }}
          </div>
          <div class="forgotPassExplanation">
            {{ frgpassYaml.forgotPassExplanation }}
          </div>
          <!-- email input -->
          <div class="email-input-container">
            <input
              v-model="email"
              :class="{ error: validEmail == false }"
              :placeholder="frgpassYaml.emailPlaceholder"
              type="email"
              v-on:input="checkMail()"
            />
            <img :src="grayMailIcon" class="input-mail-icon"/>
          </div>
          <div>
            <p-button
              :disabled="waitForResponse"
              :loading="waitForResponse"
              class="forgotPassButton"
              native-type="submit"
              @click="sendEmail"
            >
              <span>{{ frgpassYaml.button }}</span></p-button
            >
          </div>
        </form>
      </div>
    </div>
    <FooterComponent/>
  </div>
</template>

<script src="./forgotPassword.js"></script>

<style lang="scss" scoped src="./forgotPassword.scss"></style>
