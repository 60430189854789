<template>
  <div class="page-wrapper">
    <ProgressBar/>
    <h1 class="page-title">
      {{ reportsOverviewPageYaml.title }}
    </h1>
    <div class='table-wrapper-overview'>
      <p-select
        v-model="organizationName"
        :options="organizations"
        class="type-select"
        optionLabel="name"
        optionValue="name"
        placeholder="Organization"
        @change="updateOrganization(organizationName)"
      >
      </p-select>
      <div class="separator"></div>
      <p-select
        v-model="digitalProductName"
        :disabled="!responses[1]"
        :options="digitalProducts"
        class="type-select"
        optionLabel="name"
        optionValue="name"
        placeholder="DigitalProduct"
        @change="updateDigitalProduct(digitalProductName)"
      >
      </p-select>
      <div class="separator"></div>
      <p-select
        v-model="environmentName"
        :disabled="!responses[2]"
        :options="environments"
        class="type-select"
        optionLabel="name"
        optionValue="name"
        placeholder="Environment"
        @change="updateEnvironment(environmentName)"
      >
      </p-select>
      <div class="separator"></div>
      <p-select
        v-model="bucketName"
        :disabled="!responses[3]"
        :options="buckets"
        class="type-select"
        optionLabel="name"
        optionValue="name"
        placeholder="Bucket"
        @change="updateBucket(bucketName)"
      >
      </p-select>
      <div class="separator"></div>
      <p-select
        v-model="scenarioName"
        :disabled="!responses[4]"
        :options="scenarios"
        class="type-select"
        optionLabel="name"
        optionValue="name"
        placeholder="Scenario"
        @change="updateScenario(scenarioName)"
      >
      </p-select>
      <br>
      <div class="p-dropdown date-picker">
        <vue-date-picker v-model="dateFrom" :clearable="true" :max-date="new Date()" :placeholder="reportsOverviewPageYaml.dateFrom"
                         :start-time="startTimeFrom"
                         @update:model-value="updateStartDate(dateFrom)"></vue-date-picker>
      </div>
      <div class="separator"></div>
      <div class="p-dropdown date-picker">
        <vue-date-picker v-model="dateTo" :clearable="true" :max-date="new Date()" :placeholder="reportsOverviewPageYaml.dateTo"
                         :start-time="endTimeTo"
                         @update:model-value="updateEndDate(dateTo)"></vue-date-picker>
      </div>
      <div class="separator"></div>
      <p-select
        v-model="status"
        :options="statusArray"
        class="type-select"
        optionLabel="value"
        optionValue="value"
        placeholder="Status"
        @change="updateStatus(status)"
      >
      </p-select>
      <div class="separator"></div>
      <p-input
        v-model="lastTag"
        v-on:keyup.enter="filterHash(lastTag)"
        class="type-select input-tag"
        placeholder="Last Tag"
      >
      </p-input>
      <div class="separator"></div>
      <p-select v-if='totalScenarios.length >= 1'
        id='totalScenarios'
        v-model="totalScenariosSelected"
        :options="totalScenarios"
        :disabled='totalScenarios.length === 1'
        class="type-select total-scenarios-position"
        optionLabel="value"
        optionValue="value"
        :placeholder="totalScenarios[0].value"
        @change="totalScenariosUpdate()"
      >
      </p-select>
      <div class="separator"></div>
      <p-button
        class="p-button button-clear"
        icon="pi pi-times"
        label="Clear"
        @click.stop="clearFilter()">
      </p-button>

    </div>
    <div class="table-wrapper">
      <table>
        <tr>
          <th v-for="(header, i) of reportsOverviewPageYaml.tableHeaders" :key="i">{{ header }}</th>
        </tr>
        <tr
          v-for="report in reports"
          :key="report.id"
          @click="navigateToCurrentReport(report.reportId)"
          @click.middle="navigateToCurrentReport(report.reportId)"
        >
          <td>{{ report.scenario}}</td>
          <td>{{ formatDateTime(report.start) }} EET</td>
          <td>{{ formatDateTime(report.end) }} EET</td>
          <td>{{ report.duration }}</td>
          <td>{{ report.streak }}</td>
          <td>{{ report.passedScenarios }}</td>
          <td>{{ report.failedScenarios }}</td>
          <td>{{ report.stepsCount }}</td>
          <td class='disable-click'>{{ report.lastTag }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script src="./reportsOverview.js"></script>

<style lang="scss" scoped src="./reportsOverview.scss"></style>
