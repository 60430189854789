<template>
  <div class="page-wrapper">
    <div v-if="isAdmin" class="participants-bar">
      <a @click="openParticipantsDialog()"
      >{{ userCount !== undefined ? userCount : 'Loading' }} participants</a
      >
      <ParticipantsModal
        v-if="showParticipantsModal"
        :id="organizationId"
        :isAdmin="isAdmin"
        :name="organizationName"
        :users="userCount"
        viewType="organization"
        @userInteraction="getUsersCount"
        @on-close="showParticipantsModal = false"
      />
    </div>
    <div v-if="!isAdmin" class="separator"></div>
    <ProgressBar/>
    <h1 class="page-title">
      {{ environmentsYaml.title }}
    </h1>
    <div class="environments-big-pie-charts">
      <div v-if="!didResponseCome" class="skeletonCircles">
        <Skeleton shape="circle" size="250px"/>
        <Skeleton shape="circle" size="250px"/>
        <Skeleton shape="circle" size="250px"/>
      </div>
      <BigPieCharts
        v-if="didResponseCome"
        :data1="buckets"
        :data2="scenarios"
        :data3="steps"
        @getStatistics="updateStatistics"
      />
    </div>
    <div class="rectangles-container">
      <div class="rectangle-col-1">
        <!-- platfrom loader placeholders odd -->
        <div v-for="(environment) of environments" :key="environment.name" class="oddCompanyPlaceholder">
          <div v-if="environmentsWithDataOdd.length <= 0" class="custom-skeleton">
            <Skeleton height="39px"/>
            <div class="circles">
              <Skeleton shape="circle" size="150px"/>
              <Skeleton shape="circle" size="150px"/>
              <Skeleton shape="circle" size="150px"/>
            </div>
          </div>
        </div>
        <PieChartRectangle
          v-for="environment of environmentsWithDataOdd"
          :key="environment.id"
          :data1="environment.buckets"
          :data2="environment.scenarios"
          :data3="environment.steps"
          :isAdmin="isAdmin"
          :name="environment.name"
          :type="environment.type"
          @on-edit="editEnvironment(environment.id, environment.name)"
          @on-navigate="navigate(environment.name)"
        />
      </div>
      <div class="rectangle-col-2">
        <!-- Platform loader placeholder even -->
        <div v-for="(environment) of environments" :key="environment.name" class="oddCompanyPlaceholder">
          <div v-if="environmentsWithDataEven.length <= 0 && environments.length > 1" class="custom-skeleton">
            <Skeleton height="39px"/>
            <div class="circles">
              <Skeleton shape="circle" size="150px"/>
              <Skeleton shape="circle" size="150px"/>
              <Skeleton shape="circle" size="150px"/>
            </div>
          </div>
        </div>
        <PieChartRectangle
          v-for="environment of environmentsWithDataEven"
          :key="environment.id"
          :data1="environment.buckets"
          :data2="environment.scenarios"
          :data3="environment.steps"
          :isAdmin="isAdmin"
          :name="environment.name"
          :type="environment.type"
          @on-edit="editEnvironment(environment.id, environment.name)"
          @on-navigate="navigate(environment.name)"
        />
      </div>
      <AddEnvironmentDialog
        v-if="showAddEnvironmentDialog"
        @on-close="showAddEnvironmentDialog = false"
        @update-environments="updateEnvironments"
      />
      <EditEnvironmentModal
        v-if="showEditEnvironmentModal"
        :id="currentlyEditingEnvironmentID"
        :name="currentlyEditingEnvironmentName"
        @environmentUpdated="environmentUpdated"
        @on-close="showEditEnvironmentModal = false"
      />
    </div>
  </div>
</template>

<script src="./environments.js"></script>

<style lang="scss" scoped src="./environments.scss"></style>
