<template>
  <div class="page-wrapper">
    <div v-if="isAdmin" class="participants-bar">
      <a @click="openParticipantsDialog()"
      >{{ userCount !== undefined ? userCount : 'Loading' }} participants</a
      >
      <ParticipantsModal
        v-if="showParticipantsModal"
        :id="environmentId"
        :isAdmin="isAdmin"
        :name="digitalProductName"
        :users="userCount"
        viewType="environment"
        @userInteraction="getUsersCount"
        @on-close="showParticipantsModal = false"
      />
    </div>
    <div v-if="!isAdmin" class="separator"></div>
    <ProgressBar/>
    <h1 class="page-title">
      {{ reportsPageYaml.title }}
    </h1>
    <div v-if="!didResponseCome" class="skeletonCircles">
      <Skeleton shape="circle" size="250px"/>
      <Skeleton shape="circle" size="250px"/>
      <Skeleton shape="circle" size="250px"/>
    </div>
    <BigPieCharts
      v-if="didResponseCome"
      :data1="undefined"
      :data2="undefined"
      :data3="steps"
      @getStatistics="updateStatistics"
    />
    <div class="table-wrapper">
      <table>
        <tr>
          <th v-for="(header, i) of reportsPageYaml.tableHeaders" :key="i">{{ header }}</th>
        </tr>
        <tr
          v-for="report in reports"
          :key="report.id"
          @click="navigateToCurrentReport(report.reportId)"
          @click.middle="navigateToCurrentReport(report.reportId)"
        >
          <td>{{ formatDateTime(report.start) }} EET</td>
          <td>{{ formatDateTime(report.end) }} EET</td>
          <td>{{ report.duration }}</td>
          <td>{{ report.streak }}</td>
          <td>{{ report.passedScenarios }}</td>
          <td>{{ report.failedScenarios }}</td>
          <td>{{ report.stepsCount }}</td>
          <td>{{ report.lastFiveStepsHash }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script src="./reports.js"></script>

<style lang="scss" scoped src="./reports.scss"></style>
