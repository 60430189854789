import crumBarYaml from 'js-yaml-loader!../../../../content/crum-bar.yaml';
import {localStorageHelperMixin} from '../../mixins/localStorageHelperMixin';

export default {
  name: 'ProgressBar',
  props: {
    result: String,
    url: String
  },
  mixins: [localStorageHelperMixin],
  data: function () {
    return {
      crumBarPassed: '',
      crumBarFailed: '',
      crumBarLink: '',
      organizationName: this.$route.params['organization_name'],
      digitalProductName: this.$route.params['digital_product_name'],
      environmentName: this.$route.params['environment_name'],
      bucketName: this.$route.params['bucket_name'],
      reportId: this.$route.params['report_id'],
      scenarioName: this.$route.params['scenario_name'],
      featureId: this.$route.params['feature_id'],
      title: '',
      userHasOneOrganization: this.localStorageGetItem(
        'userHasOneOrganization'
      ),
      userHasOneDigitalProduct: this.localStorageGetItem(
        'userHasOneDigitalProduct'
      ),
      userHasOneEnvironment: this.localStorageGetItem(
        'userHasOneEnvironment'
      ),
      userHasOneBucket: this.localStorageGetItem(
        'userHasOneBucket'
      )
    };
  },
  mounted() {
    this.title = crumBarYaml.crumbar.title;
    this.crumBarPassed = crumBarYaml.crumbar.passed;
    this.crumBarFailed = crumBarYaml.crumbar.failed;
    this.crumBarLink = crumBarYaml.crumbar.link;
  },
  methods: {
    navigate(to) {
      switch (to) {
        case 'organizations':
          this.$router.push('/organizations');
          break;
        case 'digitalProducts':
          this.$router.push(`/organizations/${this.organizationName}/digital-products`);
          break;
        case 'environments':
          // eslint-disable-next-line max-len
          this.$router.push(`/organizations/${this.organizationName}/digital-products/${this.digitalProductName}/environments`);
          break;
        case 'buckets':
          // eslint-disable-next-line max-len
          this.$router.push(`/organizations/${this.organizationName}/digital-products/${this.digitalProductName}/environments/${this.environmentName}/buckets`);
          break;
        case 'scenarios':
          // eslint-disable-next-line max-len
          this.$router.push(`/organizations/${this.organizationName}/digital-products/${this.digitalProductName}/environments/${this.environmentName}/buckets/${this.bucketName}/scenarios`);
          break;
        case 'reports':
          this.$router.push(
            // eslint-disable-next-line max-len
            `/organizations/${this.organizationName}/digital-products/${this.digitalProductName}/environments/${this.environmentName}/buckets/${this.bucketName}/scenarios/${this.scenarioName}/reports`
          );
          break;
      }
    },
  }
}
