<template>
  <div class="rectangle-container" @click="$emit('on-navigate')" @click.middle="$emit('on-navigate')">
    <div class="rectangle-text-container">
      <div class="rectangle-top">
        <div>
          <i class="pi pi-cog" style="color:#10348d"></i>
          <div class="feature-name">
            {{ name }}
          </div>
        </div>

      </div>
      <div class="feature-status">
        Status: <span :class=" [status== 'passed' ?  'text-green': 'text-red']">
            {{ status }}
        </span>
      </div>
      <div v-if="scenarios" class="feature-scenarios">
        Sceanarios Count: {{ scenarios }}
      </div>
      <div v-if="steps" class="feature-steps">
        Steps Count: {{ steps }}
      </div>
    </div>
    <div v-if="steps" class="rectangle-pie-charts">
      <div>
        <GooglePieChart
          :background-color="'transparent'"
          :d="100"
          :data="stepsData"
        />
      </div>
    </div>
  </div>
</template>

<script src="./scenarioRectangle.js"></script>
<style lang="scss" scoped src="./scenarioRectangle.scss"></style>
