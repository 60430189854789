<template>
  <div class="pie-chart">
    <div :id="`id-${id}`"/>
    <div :id="`tooltip-${id}`"></div>
  </div>
</template>

<script src="./googlePieChart.js"></script>

<style lang="scss" src="./googlePieChart.scss"></style>
