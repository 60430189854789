export const canShowParticipantsModalMixin = {
  data: function () {
    return {
      showParticipantsModal: false,
    }
  },
  methods: {
    openParticipantsDialog() {
      this.showParticipantsModal = true;
    },
  },
}
