import FooterComponent from '../../components/footer/footer.vue';
import HeaderLogo from '../../components/headerLogo/headerLogo.vue';
import TopNavigationSmall from '../../components/topNavigationSmall/topNavigationSmall.vue';
import changePasswordYaml from 'js-yaml-loader!../../../../content/forgot-password/change-password.yaml';
import axios from '../../../axios';
import {toastHelperMixin} from '../../mixins/toastHelperMixin';
import {navigateAfterLoginMixin} from '../../mixins/navigateAfterLoginMixin';

export default {
  name: 'changePassword',
  mixins: [toastHelperMixin, navigateAfterLoginMixin],
  components: {
    // eslint-disable-next-line vue/no-reserved-component-names
    FooterComponent,
    HeaderLogo,
    TopNavigationSmall,
  },
  data: function () {
    return {
      title: '',
      leftSideBackgroundImage: '',
      text: '',
      phoneIcon: '',
      mailIcon: '',
      phoneNumber: '',
      mail: '',
      headerHome: '',
      headerAbout: '',
      headerFAQ: '',
      mobileNumber: '',
      alreadyHaveAnAccountQuestion: '',
      alreadyHaveAnAccountLink: '',
      acceptTermsOfUse: '',
      usernameText: '',
      changePasswordYaml: changePasswordYaml,
      // inputs
      username: '',
      password: '',
      verifyPassword: '',
      errorText: '',
      isValidLength: false,
      hasUpperAndLower: false,
      hasNumber: false,
      isUserName: false,
      showPassword: false,
      showVerifyPassword: false,
      waitForResponse: false,
    };
  },
  mounted() {
    // page text
    this.changePasswordYaml = changePasswordYaml;
    this.title = changePasswordYaml.title;
    this.leftSideBackgroundImage = changePasswordYaml.leftSideBackgroundImage;
    this.text = changePasswordYaml.text;
    this.phoneIcon = changePasswordYaml.phoneIcon;
    this.mailIcon = changePasswordYaml.mailIcon;
    this.phoneNumber = changePasswordYaml.phoneNumber;
    this.mail = changePasswordYaml.mail;
    this.headerHome = changePasswordYaml.headerHome;
    this.headerAbout = changePasswordYaml.headerAbout;
    this.headerFAQ = changePasswordYaml.headerFAQ;
    this.alreadyHaveAnAccountQuestion = changePasswordYaml.alreadyHaveAnAccountQuestion;
    this.alreadyHaveAnAccountLink = changePasswordYaml.alreadyHaveAnAccountLink;
  },
  methods: {
    toLogin() {
      this.$router.push('/login');
    },
    async resetPassword() {
      if (this.validatePassword()) {
        this.waitForResponse = true;
        await axios
          .post('/.netlify/functions/post-change-password', {
            password: this.password,
            key: this.$route.params.key,
          })
          .then(() => {
            this.$router.push('/login');
            this.showToast('Password changed successfully', 'success');
          })
          .catch(() => {
            this.showToast('Email has expired. Please try again by requesting a new email.', 'error');
          });
      } else {
        return (this.errorText = changePasswordYaml.errorText);
      }
      this.waitForResponse = false;
    },
    checkForLowerAndUpper() {
      let result = false;

      if (this.password.match(/[A-Z]/g) && this.password.match(/[a-z]/g))
        result = true;
      this.hasUpperAndLower = result;

      return result;
    },
    checkForNumbers() {
      let result = false;
      if (this.password.match(/[0-9]/g)) result = true;
      this.hasNumber = result;

      return result;
    },
    checkForLength() {
      let result = false;
      if (this.password.length >= 12) result = true;
      this.isValidLength = result;

      return result;
    },
    validatePassword() {
      let islengthCorrect = this.checkForLength();
      let lowerAndUpper = this.checkForLowerAndUpper();
      let containsNums = this.checkForNumbers();
      let passwordsMatch = this.checkIfPasswordsMatch();

      return (
        islengthCorrect &&
        lowerAndUpper &&
        containsNums &&
        this.password &&
        passwordsMatch
      );
    },
    checkIfPasswordsMatch() {
      if (
        this.password &&
        this.verifyPassword &&
        this.password == this.verifyPassword
      ) {
        this.errorText = '';

        return true;
      } else if (this.verifyPassword) this.errorText = "Password don't match";

      return false;
    },
  },
};
