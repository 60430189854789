<template>
  <p-dialog :aria-modal="null"
            :breakpoints="{'2400px': '40vw', '1060px': '50vw', '640px': '100vw'}"
            :modal=true
            :visible="show"
            @update:visible="$emit('on-close')">
    <template #header>
      <h3>Add new bucket</h3>
    </template>
    <div class="modal-card">
      <section class="modal-card-body">
        <!-- ------------------------------------------------------- -->
        <VeeForm v-slot="{handleSubmit}" :validation-schema="schema" as="div">
          <form id="a-form" @submit="handleSubmit($event,inviteUserToBucket())">
            <Field v-slot="{ field, errorMessage }" name="bucketName">
              <div class="input-field">
                <label>Bucket Name:</label>
                <span class="p-input-icon-left">
                  <i class="pi pi-building"/>
                  <p-input v-model="bucketName"
                           :class="{ 'p-invalid': errorMessage }"
                           placeholder="Bucket Name"
                           v-bind="field"
                  />
                </span>
                <small class="p-error">{{ errorMessage }}</small>
              </div>
            </Field>
            <!-- ------------------------------------------------------- -->
            <Field v-slot="{ field, errorMessage }" name="adminName">
              <div class="input-field">
                <label>Administrator name:</label>
                <span class="p-input-icon-left">
            <i class="pi pi-building"/>
            <p-input v-model="bucketAdminName"
                     :class="{ 'p-invalid': errorMessage }"
                     placeholder="Administrator name"
                     v-bind="field"
            />
          </span>
                <small class="p-error">{{ errorMessage }}</small>
              </div>
            </Field>
            <!-- ------------------------------------------------------- -->
            <Field v-slot="{ field, errorMessage }" name="adminEmail">
              <div class="input-field">
                <label>Administrator email:</label>
                <span class="p-input-icon-left">
          <i class="pi pi-building"/>
            <p-input v-model="bucketAdminEmail"
                     :class="{ 'p-invalid': errorMessage }"
                     placeholder="Administrator email"
                     v-bind="field"
            />
          </span>
                <small class="p-error">{{ errorMessage }}</small>

              </div>
            </Field>
            <!-- ------------------------------------------------------- -->
          </form>
        </VeeForm>
      </section>
    </div>
    <template #footer>
      <div class="submit-btn">
        <p-button
          :loading="disableButton"
          form="a-form"
          type="submit"
        >
          <span>Done</span></p-button
        >
      </div>
    </template>
  </p-dialog>
</template>

<script src="./addBucketDialogue.js"></script>

<style lang="scss" scoped src="./addBucketDialogue.scss"></style>
