<template>
  <div class="footer-container">
    <div class="footer-content">
      <div class="logo">
        <img :src="footerLogo"/>
      </div>
      <div class="section-links">
        <div class="link">
          {{ footerLinks.link1 }}
        </div>
        <div class="contact-row">
          <img :src="link1Content.img1"/>
          <div class="contact-row-text">
            {{ link1Content.text1 }}
          </div>
        </div>
        <div class="contact-row footer-email">
          <img :src="link1Content.img2"/>
          <div class="contact-row-text">
            {{ link1Content.text2 }}
          </div>
        </div>
        <div class="contact-row">
          <img :src="link1Content.img3"/>
          <div class="contact-row-text">
            {{ link1Content.text3 }}
          </div>
        </div>
      </div>
      <div class="section-links">
        <div class="link">
          {{ footerLinks.link2 }}
        </div>
        <div class="social-links">
          <img :src="link2Content.img1"/>
          <img :src="link2Content.img2"/>
        </div>
      </div>
      <div class="section-links">
        <div class="link">
          {{ footerLinks.link3 }}
        </div>
      </div>
      <div class="footer-end">
        <div class="rights-reserved">
          {{ footerRightsReserved }}
        </div>
        <div class="place">
          {{ place }}
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./footer.js"></script>

<style lang="scss" scoped src="./footer.scss"></style>
