import FooterComponent from '../../components/footer/footer.vue';
import HeaderLogo from '../../components/headerLogo/headerLogo.vue';
import TopNavigationSmall from '../../components/topNavigationSmall/topNavigationSmall.vue';
import forgotPasswordYaml from 'js-yaml-loader!../../../../content/forgot-password/forgot-password.yaml';
import {toastHelperMixin} from '../../mixins/toastHelperMixin';
import {emailsMixin} from '../../mixins/emailsMixin';
import axios from '../../../axios';

export default {
  name: 'forgotPassword',
  components: {
    FooterComponent,
    HeaderLogo,
    TopNavigationSmall,
  },
  mixins: [toastHelperMixin, emailsMixin],
  data: function () {
    return {
      phoneIcon: '',
      mailIcon: '',
      grayMailIcon: '',
      alreadyHaveAnAccountLink: '',
      frgpassYaml: '',
      email: '',
      validEmail: '',
      waitForResponse: false,
    };
  },
  mounted() {
    this.phoneIcon = forgotPasswordYaml.phoneIcon;
    this.mailIcon = forgotPasswordYaml.mailIcon;
    this.grayMailIcon = forgotPasswordYaml.grayMailIcon;
    this.frgpassYaml = forgotPasswordYaml;
    this.email = localStorage.getItem('loginEmail')
      ? localStorage.getItem('loginEmail')
      : '';
  },
  methods: {
    toLogin() {
      this.$router.push('/login');
    },
    checkMail() {
      // eslint-disable-next-line
      let regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (this.email && this.email.match(regex))
        return (this.validEmail = true);
      return (this.validEmail = false);
    },
    async sendEmail() {
      this.checkMail();
      if (this.validEmail == true) {
        this.waitForResponse = true;

        let emailTemplates = await this.getResetPasswordEmailTemplates();
        await axios
          .post('/.netlify/functions/post-forgot-password', {
            email: this.email,
            emailYaml: emailTemplates.emailYaml,
            emailHTML: emailTemplates.emailHTML,
          })
          .then(() => {
            this.$router.push('/login');
            this.showToast(
              'We have sent you an email with instructions!',
              'success'
            );
          })
          .catch(() => {
            return this.showGenericErrorToast();
          });
      } else if (this.validEmail == false)
        return this.showToast('Please input a valid email', 'error');

      this.waitForResponse = false;
    },
  },
};
