<template>
  <div v-if="report" class="page-wrapper">
    <ProgressBar :result="feature.status" />
    <CurrentScenarioHeaderBox :report="reportHeaderBoxes" />
    <h1 class="page-title">Scenarios</h1>
    <div class="current-reports-big-pie-charts">
      <Skeleton v-if="!didResponseCome"></Skeleton>
    </div>
    <div class="component" 
      :style="{ 
        width: 
        this.failedSteps && this.performanceFailure ? '87.5%' : (this.failedSteps || this.performanceFailure ? '92.5%' : '97.5%') }">
      <CurrentScenarioAccordion
        v-if="didResponseCome"
        :report-data-features="scenarios"
        @on-navigate="navigate()"
      />
    </div>
    <div class="step-buttons">
      <div class="failure-buttons" v-if="this.failedSteps">
        <p-button
          class="p-button btn-up"
          icon="pi pi-angle-up"
          @click.stop="scrollToTestEnd('failed', 'up')"
        >
        </p-button>
        <p-button
          class="p-button btn-middle"
          id="bt-middle"
          :label="this.failedSteps"
        >
        </p-button>
        <p-button
          class="p-button btn-down"
          icon="pi pi-angle-down"
          @click.stop="scrollToTestEnd('failed', 'down')"
        >
        </p-button>
      </div>
      <div class="performanceFailure-buttons" v-if="this.performanceFailure">
        <p-button
          class="p-button bt-up"
          icon="pi pi-angle-up"
          @click.stop="scrollToTestEnd('performanceFailed', 'up')"
        >
        </p-button>
        <p-button
          class="p-button bt-middle"
          id="bt-middle"
          :label="this.performanceFailure"
        >
        </p-button>
        <p-button
          class="p-button bt-down"
          icon="pi pi-angle-down"
          @click.stop="scrollToTestEnd('performanceFailed', 'down')"
        >
        </p-button>
      </div>
      <div class="up-down-buttons">
        <p-button
          class="p-button button-up"
          icon="pi pi-angle-up"
          @click.stop="scrollUp()"
        >
        </p-button>
        <p-button
          class="p-button button-middle"
          icon="pi pi-minus"
          v-if="this.errorStepIndex > 0"
          @click.stop="scrollToTestEnd('error', 'mid')"
        >
        </p-button>
        <p-button
          class="p-button button-down"
          icon="pi pi-angle-down"
          @click.stop="scrollDown()"
        >
        </p-button>
      </div>
    </div>
  </div>
</template>

<script src="./currentScenario.js"></script>

<style lang="scss" scoped src="./currentScenario.scss"></style>
