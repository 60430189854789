import axios from '../../../axios';
import {emailsMixin} from '../../mixins/emailsMixin';
import {toastHelperMixin} from '../../mixins/toastHelperMixin';
import * as yup from 'yup'

export default {
  name: 'AddEnvironmentDialog',
  mixins: [emailsMixin, toastHelperMixin],
  data: function () {
    const schema = yup.object({
      envName: yup.string().required().label('Environment Name'),
      envUrl: yup.string().url().required().label('Environment URL'),
      envLiveBranch: yup.string().required().label('Environment Live Branch'),
      adminName: yup.string().required().label('Administrator Name'),
      adminEmail: yup.string().required().email().label('Administrator Email'),
      environmentType: yup.number().required().label('Environment Flag')
    });
    return {
      schema,
      disableButton: false,
      userId: undefined,
      organizationId: undefined,
      environmentId: undefined,
      environmenType: undefined,
      organizationName: this.$route.params['organization_name'],
      digitalProductName: this.$route.params['digital_product_name'],
      generalErrMessage: '',
      isGeneralErrorVisible: false,
      isEnvironmentNameErrorVisible: false,
      environmentName: null,
      isDataInvalid: false,
      environmentAdminName: undefined,
      isEnvironmentAdminNameErrorVisible: false,
      environmentAdminEmail: null,
      isEnvironmentAdminEmailErrorVisible: false,
      environmentUrl: null,
      isEnvironmentUrlErrorVisible: false,
      environmentLiveBranch: null,
      isEnvironmentLiveBranchErrorVisible: false,
      createUserResponse: null,
      digitalProductId: null,
      show: true,
      typeRoles: [{label: 'Live', value: 0},
        {label: 'Acceptence', value: 1},
        {label: 'Q&A', value: 2},],
    };
  },
  methods: {
    async inviteUserToEnvironment() {
      this.disableButton = true;
      this.isDataInvalid = false;
      this.isGeneralErrorVisible = false;
      this.isEnvironmentNameErrorVisible = false;
      this.isEnvironmentNameErrorVisible = false;
      this.isEnvironmentAdminNameErrorVisible = false;
      this.isEnvironmentAdminEmailErrorVisible = false;

      let isDataInvalid = this.inputDataHasErrors();
      if (isDataInvalid) return;

      let createEnvironmentResponse = await this.createEnvironment();
      if (createEnvironmentResponse.status === 200) {
        this.environmentId = createEnvironmentResponse.data.environmentId;
      } else {
        this.generalErrMessage =
          createEnvironmentResponse.data.message;
        this.isGeneralErrorVisible = true;
        return;
      }
      this.createUserResponse = await this.createUser();
      await this.getUserId();
      await this.getOrganizationId();
      await this.getDigitalProductId();
      await this.addDomainToOrganization();
      await this.addUserToOrganization();
      await this.addUserToDigitalProduct();
      await this.addUserToEnvironment();
      try {
        if (this.createUserResponse.data.isNewUser === false)
          await this.sendInviteExistingEmail();
        else await this.sendInviteNewEmail();
      } catch {
        this.generalErrMessage =
          'Internal server error. Please try again later!';
        this.isGeneralErrorVisible = true;
        return;
      }
      this.disableButton = false;
      this.$emit('on-close');
      this.$emit('update-environments')
    },
    inputDataHasErrors() {
      this.isEnvironmentLiveBranchErrorVisible = !this.environmentLiveBranch;
      this.isEnvironmenUrlErrorVisible = !this.environmenUrl;
      this.isEnvironmentNameErrorVisible = !this.environmentName;
      this.isEnvironmentAdminNameErrorVisible = !this.environmentAdminName;
      this.isEnvironmentAdminEmailErrorVisible =
        !this.environmentAdminEmail ||
        !this.environmentAdminEmail.includes('@');

      return (
        this.isEnvironmentNameErrorVisible ||
        this.isEnvironmentAdminNameErrorVisible ||
        this.isEnvironmentAdminEmailErrorVisible
      );
    },
    async getOrganizationId() {
      await axios
        .post('/.netlify/functions/get-organization-id', {
          organizationName: this.organizationName,
        })
        .then((r) => {
          this.organizationId = r.data.organizationId;
        });
    },
    async getDigitalProductId() {
      await axios
        .post('/.netlify/functions/get-digital-product-id', {
          digitalProductName: this.digitalProductName,
        })
        .then((res) => {
          this.digitalProductId = res.data.digitalProductId;
        });
    },
    async createEnvironment() {
      return await axios.post('/.netlify/functions/create-environment', {
        organizationName: this.organizationName,
        environmentName: this.environmentName,
        digitalProductName: this.digitalProductName,
        url: this.environmentUrl,
        type: this.environmenType,
        liveBranch: this.environmentLiveBranch,
        userDomain: [`${this.environmentAdminEmail.split('@')[1]}`],
      });
    },
    async createUser() {
      return await axios.post('/.netlify/functions/create-admin-user', {
        name: this.environmentAdminName,
        email: this.environmentAdminEmail,
        entityId: this.environmentId,
        canSeeBitbucket: true,
      });
    },
    // todo instead of an additional request to get the user's id, simply code it to
    async getUserId() {
      await axios
        .post('/.netlify/functions/get-user-id', {
          email: this.environmentAdminEmail,
        })
        .then((r) => {
          this.userId = r.data.userId;
        });
    },
    async addDomainToOrganization() {
      // todo overall add /.netlify/functions/ to the configuration so it's not needed to write it everywhere
      await axios
        .post('/.netlify/functions/add-domain-to-organization', {
          organizationId: this.organizationId,
          domain: `@${this.environmentAdminEmail.split('@')[1]}`,
        })
        .catch(() =>
          this.showToast(
            'A problem occured while adding adding domain to organization. Please try again later!',
            'error'
          )
        );
    },
    async addUserToOrganization() {
      await axios
        .post('/.netlify/functions/add-user-to-organization', {
          userId: this.userId,
          organizationId: this.organizationId,
        })
        .catch(() =>
          this.showToast(
            'A problem occured while adding adding user to organization. Please try again later!',
            'error'
          )
        );
    },
    async addUserToDigitalProduct() {
      await axios
        .post('/.netlify/functions/add-user-to-digital-product', {
          userId: this.userId,
          digitalProductId: this.digitalProductId,
        })
        .catch(() =>
          this.showToast(
            'A problem occured while adding adding user to digital product. Please try again later!',
            'error'
          )
        );
    },
    async addUserToEnvironment() {
      await axios.post('/.netlify/functions/add-user-to-environment', {
        userId: this.userId,
        environmentId: this.environmentId,
      });
    },
    async sendInviteNewEmail() {
      let emailTemplates = await this.inviteUserToEntitiyEmailSend(
        false,
        'environment'
      );
      await axios.post(
        '/.netlify/functions/send-invite-new-user-to-environment',
        {
          email: this.environmentAdminEmail,
          environmentName: this.environmentName,
          emailYaml: emailTemplates.emailYaml,
          emailHTML: emailTemplates.emailHTML,
        }
      );
    },
    async sendInviteExistingEmail() {
      // todo email templates should be read in the backend ask mm for an easy implementation
      let emailTemplates = await this.inviteUserToEntitiyEmailSend(
        true,
        'environment'
      );
      await axios.post('/.netlify/functions/send-invite-to-environment', {
        email: this.environmentAdminEmail,
        environmentName: this.environmentName,
        emailYaml: emailTemplates.emailYaml,
        emailHTML: emailTemplates.emailHTML,
      });
    },
  },
};
