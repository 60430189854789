import axios from '../../../axios';
import {emailsMixin} from '../../mixins/emailsMixin';
import {toastHelperMixin} from '../../mixins/toastHelperMixin';
import * as yup from 'yup';

export default {
  name: 'addBucketDialog',
  mixins: [emailsMixin, toastHelperMixin],
  data: function () {
    const schema = yup.object({
      digitalName: yup.string().required().label('Digital Product Name'),
      digitalRepLocation: yup.string().url().required().label('Digital Repository Location'),
      adminName: yup.string().required().label('Administrator Name'),
      adminEmail: yup.string().required().email().label('Administrator Email')
    });
    return {
      schema,
      disableButton: false,
      userId: undefined,
      organizationId: undefined,
      bucketId: undefined,
      digitalProductId: undefined,
      organizationName: this.$route.params['organization_name'],
      digitalProductName: this.$route.params['digital_product_name'],
      environmentName: this.$route.params['environment_name'],
      generalErrMessage: '',
      isDataInvalid: false,
      isGeneralErrorVisible: false,
      bucketName: null,
      isBucketNameErrorVisible: false,
      bucketAdminName: undefined,
      isBucketAdminNameErrorVisible: false,
      bucketAdminEmail: null,
      isBucketAdminEmailErrorVisible: false,
      createUserResponse: null,
      show: true,
    };
  },
  methods: {
    async inviteUserToBucket() {
      this.disableButton = true;
      this.isDataInvalid = false;
      this.isGeneralErrorVisible = false;
      this.isBucketNameErrorVisible = false;
      this.isBucketAdminNameErrorVisible = false;
      this.isBucketAdminEmailErrorVisible = false;

      let isDataInvalid = this.inputDataHasErrors();
      if (isDataInvalid) return;
      let response = await axios.get('/.netlify/functions/is-user-admin');
      if (!response.data.isUserAdmin) {
        this.generalErrMessage =
          "You don't have the correct permissions to create a digital product.";
        this.isGeneralErrorVisible = true;
        return;
      }
      let createBucketResponse = await this.createBucket().then((res) => {
        return res
      }).catch((err) => {
        this.showToast(err.response.data.message, 'error')
        return err.response
      });
      if (createBucketResponse.status === 200) {
        this.bucketId =
          createBucketResponse.data.bucketId;
      } else {
        return;
      }
      this.createUserResponse = await this.createUser();
      // todo if something fails the whole process ends
      await this.getUserId();
      await this.getOrganizationId();
      await this.getDigitalProductId();
      await this.addDomainToOrganization();
      await this.addUserToOrganization();
      await this.addUserToDigitalProduct();
      await this.addUserToBucket();
      try {
        // await this.addDigitalProductToUser();
        if (this.createUserResponse.data.isNewUser === false)
          await this.sendInviteExistingEmail();
        else await this.sendInviteNewEmail();
      } catch {
        this.generalErrMessage =
          'Internal server error. Please try again later!';
        this.isGeneralErrorVisible = true;
        return;
      }
      this.disableButton = false;
      this.$emit('on-close');
      this.$emit('update-products');
    },
    inputDataHasErrors() {
      this.isBucketNameErrorVisible = !this.bucketName;
      this.isBucketAdminNameErrorVisible =
        !this.bucketAdminName;
      this.isBucketAdminEmailErrorVisible =
        !this.bucketAdminEmail ||
        !this.bucketAdminEmail.includes('@');
      return (
        this.isBucketNameErrorVisible ||
        this.isBucketAdminNameErrorVisible ||
        this.isBucketAdminEmailErrorVisible
      );
    },
    async getOrganizationId() {
      await axios
        .post('/.netlify/functions/get-organization-id', {
          organizationName: this.organizationName,
        })
        .then((r) => {
          this.organizationId = r.data.organizationId;
        });
    },
    async getDigitalProductId() {
      await axios
        .post('/.netlify/functions/get-digital-product-id', {
          digitalProductName: this.digitalProductName,
        })
        .then((res) => {
          this.digitalProductId = res.data.digitalProductId;
        });
    },
    async createBucket() {
      return await axios.post('/.netlify/functions/create-bucket', {
        organizationName: this.organizationName,
        bucketName: this.bucketName,
        digitalProductName: this.digitalProductName,
        environmentName: this.environmentName,
        userDomain: [`${this.bucketAdminEmail.split('@')[1]}`]
      });
    },
    async createUser() {
      return await axios.post('/.netlify/functions/create-admin-user', {
        name: this.bucketAdminName,
        email: this.bucketAdminEmail,
        entityId: this.bucketId,
        canSeeBitbucket: true,
      });
    },
    async getUserId() {
      await axios
        .post('/.netlify/functions/get-user-id', {
          email: this.bucketAdminEmail,
        })
        .then((r) => {
          this.userId = r.data.userId;
        });
    },
    async addDomainToOrganization() {
      await axios
        .post('/.netlify/functions/add-domain-to-organization', {
          organizationId: this.organizationId,
          domain: `@${this.bucketAdminEmail.split('@')[1]}`,
        })
        .catch(() =>
          this.showToast(
            'A problem occured while adding adding domain to organization. Please try again later!',
            'error'
          )
        );
    },
    async addUserToOrganization() {
      await axios
        .post('/.netlify/functions/add-user-to-organization', {
          userId: this.userId,
          organizationId: this.organizationId,
        })
        .catch(() =>
          this.showToast(
            'A problem occured while adding adding user to organization. Please try again later!',
            'error'
          )
        );
    },
    /**
     * This will be the main request for adding
     * Requests are merged into one
     */
    async addUserToDigitalProduct() {
      await axios
        .post('/.netlify/functions/add-user-to-digital-product', {
          userId: this.userId,
          digitalProductId: this.digitalProductId,
        })
        .catch(() =>
          this.showToast(
            'A problem occured while adding adding user to digital product. Please try again later!',
            'error'
          )
        );
    },
    async addUserToBucket() {
      await axios
        .post('/.netlify/functions/add-user-to-bucket', {
          userId: this.userId,
          bucketId: this.bucketId,
        })
        .catch(() =>
          this.showToast(
            'A problem occured while adding adding user to bucket. Please try again later!',
            'error'
          )
        );
    },
    async createEnvironment() {
      return await axios.post('/.netlify/functions/create-environment', {
        organizationName: this.organizationName,
        environmentName: this.environmentName,
        digitalProductName: this.digitalProductName,
        url: this.environmentUrl,
        type: this.environmenType,
        liveBranch: this.environmentLiveBranch,
        userDomain: [`${this.environmentAdminEmail.split('@')[1]}`],
      });
    },
    async sendInviteNewEmail() {
      let emailTemplates = await this.inviteUserToEntitiyEmailSend(
        false,
        'digital-product'
      );
      await axios.post(
        '/.netlify/functions/send-invite-new-user-to-digital-product',
        {
          email: this.bucketAdminEmail,
          digitalProductName: this.bucketName,
          emailYaml: emailTemplates.emailYaml,
          emailHTML: emailTemplates.emailHTML,
        }
      );
    },
    async sendInviteExistingEmail() {
      let emailTemplates = await this.inviteUserToEntitiyEmailSend(
        true,
        'digital-product'
      );
      await axios.post('/.netlify/functions/send-invite-to-digital-product', {
        email: this.bucketAdminEmail,
        digitalProductName: this.bucketName,
        emailYaml: emailTemplates.emailYaml,
        emailHTML: emailTemplates.emailHTML,
      });
    },
  },
};
