<template>
  <div class="domain-list">
    <p-tag
      v-for="domain of domains"
      :key="domain"
      class="domain-tag"
      icon="pi pi-globe"
      rounded
    >
      <span class="domain-name">{{ domain }}</span>
      <i class="pi pi-times"
         style="font-size: 1em"
         @click="$emit('on-remove', domain)"></i>
    </p-tag>
  </div>
</template>

<script src="./domainTagList.js"></script>

<style lang="scss" src="./domainTagList.scss"></style>
