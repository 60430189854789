<template>
  <div id="app">
    <Toast position="top-center" />
    <router-view />
  </div>
</template>

<script>
import Toast from 'primevue/toast'
export default {
  components: {Toast},
  beforeCreate() {
    if (
      !localStorage.getItem('currentReportPath') &&
      this.$route.params['organization_name'] &&
      this.$route.params['digital_product_name'] &&
      this.$route.params['report_id']
    ) {
      localStorage.setItem(
        'currentReportPath',
        this.$router.currentRoute.fullPath.toString()
      );
    }
  }
};
</script>

<style lang="scss">
@import '../public/assets/styles/main.scss';

// Override Bulma variables here:
$family-primary: $font-family-source-sans-pro, sans-serif;
$family-secondary: $font-family-source-sans-pro, sans-serif;
$primary: $color-primary;
$danger: $color-danger;

// Import Bulma and Buefy
@import '~bulma/sass/utilities/_all';
@import '~bulma';

// Global overrides below:
#app{
  height: 100%;
}
* {
  margin: 0;
}
html, body {
  height: 100%;
}

.modal-card {
  width: 100%;
  &.loading {
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 6px;
      background-color: rgba(white, 0.5);
      z-index: 10;
    }
  }
  .modal-card-head {
    .modal-card-title {
      font-family: $font-family-epilogue;
      font-size: 21px;
      line-height: 22px;
      font-weight: 600;
      position: relative;
      top: 3px;
    }
  }
  .modal-card-foot {
    justify-content: flex-end;
    .done-btn {
      width: 115px;
    }
  }
}
.field {
  label {
    color: $color-primary;
    font-size: 12px;
    line-height: 15px;
    font-weight: 600;
  }
}
.button {
  cursor: pointer;
  > span {
    display: flex;
  }
}
</style>
