import homePageYaml from 'js-yaml-loader!../../../../content/home.yaml';

export default {
  name: 'ForWho',
  data: function () {
    return {
      forWhom: homePageYaml.forWhom,
    };
  },
};
