import axios from '../../../axios';
import BigPieCharts from '../../components/bigPieCharts/bigPieCharts.vue';
import CurrentReportHeaderBoxes from '../../components/currentReportHeaderBoxes/currentReportHeaderBoxes.vue';
import CurrentReportAccordion from '../../components/currentReportAccordion/currentReportAccordion.vue';
import ProgressBar from '../../components/progressBar/progressBar.vue';

export default {
  name: 'CurrentReport',
  components: {
    BigPieCharts,
    CurrentReportHeaderBoxes,
    CurrentReportAccordion,
    ProgressBar,
  },
  data: function () {
    return {
      features: [],
      scenarios: [],
      steps: [],
      reportDropdownsFeature: [],
      // todo why the report is an array? - mm
      report: [],
      reportId: this.$route.params['report_id'],
      reportHeaderBoxes: {},
      featuresForAccordion: [],
      didResponseCome: false,
    };
  },
  async created() {
    if (localStorage.getItem('token')) {
      await axios
        .post('/.netlify/functions/get-report', {
          reportId: this.reportId,
        })
        .then(async (data) => {
          this.didResponseCome = true;
          this.report = data.data;
          this.features.push(['PASSED', this.report.passedBuckets]);
          this.features.push(['FAILED', this.report.failedBuckets]);
          this.scenarios.push(['PASSED', this.report.passedScenarios]);
          this.scenarios.push(['FAILED', this.report.failedScenarios]);
          this.steps.push(['PASSED', this.report.passedSteps]);
          this.steps.push(['FAILED', this.report.failedSteps]);
          this.steps.push(['SKIPPED', this.report.skippedSteps]);
          this.steps.push(['SUGGESTED', this.report.suggestedSteps]);
          this.reportHeaderBoxes = {
            ...this.report,
            stepsCount:
              this.report.passedSteps +
              this.report.failedSteps +
              this.report.skippedSteps +
              this.report.suggestedSteps,
          };
        });
      this.featuresForAccordion = this.report.features.sort(
        (f1, f2) => (f1.status === 'failed') ? -1 : (f2.status === 'failed') ? 1 : 0);
    }
  }
};
