import axios from '../../../axios';
import {emailsMixin} from '../../mixins/emailsMixin';
import {toastHelperMixin} from '../../mixins/toastHelperMixin';
import * as yup from 'yup';

export default {
  name: 'addDigitalProductDialog',
  mixins: [emailsMixin, toastHelperMixin],
  data: function () {
    const schema = yup.object({
      digitalName: yup.string().required().label('Digital Product Name'),
      digitalRepLocation: yup.string().url().required().label('Digital Repository Location'),
      adminName: yup.string().required().label('Administrator Name'),
      adminEmail: yup.string().required().email().label('Administrator Email')
    });
    return {
      schema,
      disableButton: false,
      userId: undefined,
      organizationId: undefined,
      digitalProductId: undefined,
      organizationName: this.$route.params['organization_name'],
      generalErrMessage: '',
      isDataInvalid: false,
      isGeneralErrorVisible: false,
      digitalProductName: null,
      isDigitalProductNameErrorVisible: false,
      show: true,
    };
  },
  methods: {
    async inviteUserToDigitalProduct() {
      this.disableButton = true;
      this.isDataInvalid = false;
      this.isGeneralErrorVisible = false;
      this.isDigitalProductNameErrorVisible = false;
      this.isDigitalProductAdminNameErrorVisible = false;
      this.isDigitalProductAdminEmailErrorVisible = false;

      let isDataInvalid = this.inputDataHasErrors();
      if (isDataInvalid) return;
      let response = await axios.get('/.netlify/functions/is-user-admin');
      if (!response.data.isUserAdmin) {
        this.generalErrMessage =
          "You don't have the correct permissions to create a digital product.";
        this.isGeneralErrorVisible = true;
        return;
      }
      let createDigitalProductResponse = await this.createDigitalProduct().then((res) => {
        return res
      }).catch((err) => {
        this.showToast(err.response.data.message, 'error')
        return err.response
      });
      if (createDigitalProductResponse.status === 200) {
        this.digitalProductId =
          createDigitalProductResponse.data.digitalProductId;
      } else {
        return;
      }
      this.disableButton = false;
      this.$emit('on-close');
      this.$emit('update-products');
    },
    inputDataHasErrors() {
      this.isDigitalProductNameErrorVisible = !this.digitalProductName;
      return (this.isDigitalProductNameErrorVisible);
    },
    async getOrganizationId() {
      await axios
        .post('/.netlify/functions/get-organization-id', {
          organizationName: this.organizationName,
        })
        .then((r) => {
          this.organizationId = r.data.organizationId;
        });
    },
    async createDigitalProduct() {
      return await axios.post('/.netlify/functions/create-digital-product', {
        organizationName: this.organizationName,
        digitalProductName: this.digitalProductName,
      });
    },
    async getUserId() {
      await axios
        .post('/.netlify/functions/get-user-id', {})
        .then((r) => {
          this.userId = r.data.userId;
        });
    },
    async addDomainToOrganization() {
      await axios
        .post('/.netlify/functions/add-domain-to-organization', {
          organizationId: this.organizationId,
          domain: '@auxcode.com',
        })
        .catch(() =>
          this.showToast(
            'A problem occured while adding adding domain to organization. Please try again later!',
            'error'
          )
        );
    },
    async addUserToOrganization() {
      await axios
        .post('/.netlify/functions/add-user-to-organization', {
          userId: this.userId,
          organizationId: this.organizationId,
        })
        .catch(() =>
          this.showToast(
            'A problem occured while adding adding user to organization. Please try again later!',
            'error'
          )
        );
    },
    /**
     * This will be the main request for adding
     * Requests are merged into one
     */
    async addUserToDigitalProduct() {
      await axios
        .post('/.netlify/functions/add-user-to-digital-product', {
          userId: this.userId,
          digitalProductId: this.digitalProductId,
        })
        .catch(() =>
          this.showToast(
            'A problem occured while adding adding user to digital product. Please try again later!',
            'error'
          )
        );
    },
    async sendInviteNewEmail() {
      let emailTemplates = await this.inviteUserToEntitiyEmailSend(
        false,
        'digital-product'
      );
      await axios.post(
        '/.netlify/functions/send-invite-new-user-to-digital-product',
        {
          email: this.digitalProductAdminEmail,
          digitalProductName: this.digitalProductName,
          emailYaml: emailTemplates.emailYaml,
          emailHTML: emailTemplates.emailHTML,
        }
      );
    },
    async sendInviteExistingEmail() {
      let emailTemplates = await this.inviteUserToEntitiyEmailSend(
        true,
        'digital-product'
      );
      await axios.post('/.netlify/functions/send-invite-to-digital-product', {
        email: this.digitalProductAdminEmail,
        digitalProductName: this.digitalProductName,
        emailYaml: emailTemplates.emailYaml,
        emailHTML: emailTemplates.emailHTML,
      });
    },
  },
};
