import TopNavigationBar from '../../components/topNavigationBar/topNavigationBar.vue';
import FooterComponent from '../../components/footer/footer.vue';
import homePageYaml from 'js-yaml-loader!../../../../content/home.yaml';
import ForWhom from '../../components/forWhom/forWhom.vue';
import ImplementationModel from '../../components/implementationModel/implementationModel.vue';
import Team from '../../components/team/team.vue';
import Flowchart from '../../components/flowChart/flowChart.vue'
import axios from '@/axios';
import {toastHelperMixin} from '../../mixins/toastHelperMixin';
import {navigateAfterLoginMixin} from '../../mixins/navigateAfterLoginMixin';

export default {
  name: 'Home',
  mixins: [toastHelperMixin, navigateAfterLoginMixin],
  components: {
    TopNavigationBar,
    FooterComponent,
    ForWhom,
    Flowchart,
    ImplementationModel,
    Team,
  },
  data: function () {
    return {
      title: '',
      subtitle: '',
      getStartedBtn: '',
      learnMorebtn: '',
      titleImage: '',
      mediaCompetenceLogo: '',
      thomasLloydLogo: '',
      smeFinanceLogo: '',
      whatWeOfferTitle: '',
      whatWeOfferBoxPicture1: '',
      whatWeOfferBoxPicture2: '',
      whatWeOfferBoxPicture3: '',
      whatWeOfferBoxTitle1: '',
      whatWeOfferBoxTitle2: '',
      whatWeOfferBoxTitle3: '',
      whatWeOfferBoxText1: '',
      whatWeOfferBoxText2: '',
      whatWeOfferBoxText3: '',
      whatWeOfferBoxes: [],
      testAutomationInsightsTitle: '',
      testAutomationInsightsText: '',
      testAutoBoxImg1: '',
      testAutoBoxImg2: '',
      testAutoBoxTitle1: '',
      testAutoBoxTitle2: '',
      testAutoBoxText1: '',
      testAutoBoxText2: '',
      testAutoBoxButtonText1: '',
      testAutoBoxButtonText2: '',
      testAutoBoxYamlContent1: '',
      testAutoBoxYamlContent2: '',
      bottomBlueContainerImage: '',
      bottomBlueContainerBackgroundImage: '',
      bottomBlueContainerTitle: '',
      bottomBlueContainerButtonText: '',
    };
  },
  async mounted() {
    if (this.$route.params.inviteKey) {
      await this.register();
    }
    const token = localStorage.getItem('token');
    if (token) {
      this.$router.push('/organizations');
    }
    this.title = homePageYaml.topSection.title;
    this.subtitle = homePageYaml.topSection.subtitle;
    this.getStartedBtn = homePageYaml.topSection.getStartedBtn;
    this.learnMorebtn = homePageYaml.topSection.learnMorebtn;
    this.titleImage = homePageYaml.topSection.titleImage;
    this.mediaCompetenceLogo = homePageYaml.logosSection.mediaCompetenceLogo;
    this.thomasLloydLogo = homePageYaml.logosSection.thomasLloydLogo;
    this.smeFinanceLogo = homePageYaml.logosSection.smeFinanceLogo;
    this.whatWeOfferTitle = homePageYaml.whatWeOfferSection.title;
    this.architecture = homePageYaml.architecture;
    this.whatWeOfferBoxPicture1 =
      homePageYaml.whatWeOfferSection.content[0].picture;
    this.whatWeOfferBoxPicture2 =
      homePageYaml.whatWeOfferSection.content[1].picture;
    this.whatWeOfferBoxPicture3 =
      homePageYaml.whatWeOfferSection.content[2].picture;
    this.whatWeOfferBoxTitle1 =
      homePageYaml.whatWeOfferSection.content[0].title;
    this.whatWeOfferBoxTitle2 =
      homePageYaml.whatWeOfferSection.content[1].title;
    this.whatWeOfferBoxTitle3 =
      homePageYaml.whatWeOfferSection.content[2].title;
    this.whatWeOfferBoxText1 = homePageYaml.whatWeOfferSection.content[0].text;
    this.whatWeOfferBoxText2 = homePageYaml.whatWeOfferSection.content[1].text;
    this.whatWeOfferBoxText3 = homePageYaml.whatWeOfferSection.content[2].text;
    this.testAutomationInsightsTitle = homePageYaml.articles.title;
    this.testAutomationInsightsText = homePageYaml.articles.text;
    this.testAutoBoxImg1 = homePageYaml.articles.boxes[0].image;
    this.testAutoBoxImg2 = homePageYaml.articles.boxes[1].image;
    this.testAutoBoxTitle1 = homePageYaml.articles.boxes[0].title;
    this.testAutoBoxTitle2 = homePageYaml.articles.boxes[1].title;
    this.testAutoBoxText1 = homePageYaml.articles.boxes[0].text;
    this.testAutoBoxText2 = homePageYaml.articles.boxes[1].text;
    this.testAutoBoxButtonText1 = homePageYaml.articles.boxes[0].button;
    this.testAutoBoxButtonText2 = homePageYaml.articles.boxes[1].button;
    this.testAutoBoxYamlTemplate1 = homePageYaml.articles.boxes[0].template;
    this.testAutoBoxYamlTemplate2 = homePageYaml.articles.boxes[1].template;
    this.testAutoBoxYamlContent1 = homePageYaml.articles.boxes[0].content;
    this.testAutoBoxYamlContent2 = homePageYaml.articles.boxes[1].content;
    this.bottomBlueContainerImage = homePageYaml.bottomBlueContainer.image;
    this.bottomBlueContainerBackgroundImage =
      homePageYaml.bottomBlueContainer.backgroundImage;
    this.bottomBlueContainerTitle = homePageYaml.bottomBlueContainer.title;
    this.bottomBlueContainerButtonText =
      homePageYaml.bottomBlueContainer.buttonText;
    this.whatWeOfferBoxes = [
      {
        icon: this.whatWeOfferBoxPicture1,
        title: this.whatWeOfferBoxTitle1,
        text: this.whatWeOfferBoxText1,
      },
      {
        icon: this.whatWeOfferBoxPicture2,
        title: this.whatWeOfferBoxTitle2,
        text: this.whatWeOfferBoxText2,
      },
      {
        icon: this.whatWeOfferBoxPicture3,
        title: this.whatWeOfferBoxTitle3,
        text: this.whatWeOfferBoxText3,
      },
    ];
  },
  methods: {
    async register() {
      await axios.post('/.netlify/functions/post-register-invited-user', {
        key: this.$route.params.inviteKey,
      }).then(async (res) => {
        this.errorText = '';

        var now = new Date();
        var afterSevenDays = now.setDate(now.getDate()+7);
        var token = {
          token: res.data.token,
          expiryToken: afterSevenDays,
        };
        localStorage.setItem('token', JSON.stringify(token));
        if (this.rememberMeValue) {
          localStorage.setItem('rememberMeToken', JSON.stringify(token));
        }
        if (localStorage.getItem('currentReportPath')) {
          await this.$router.push(
            localStorage.getItem('currentReportPath')
          );
        }
        this.navigateAfterLogin();
      })
        .catch((e) => {
          console.log(e)
          this.showToast('Invitation has expired!', 'error');
        });
    },
    getStarted() {
      this.$router.push('/registration');
    },
    toArticle(template, content) {
      this.$router.push(
        `/${template}/${content.substr(0, content.indexOf('.'))}`
      );
    },
    scrollTo(scrollToValue) {
      if (scrollToValue.elementId !== 'Login') {
        document
          .getElementById(scrollToValue.elementId)
          .scrollIntoView({behavior: 'smooth'});
      }
    },
  },
};
