import axios from '../../../axios'
import {emailsMixin} from '../../mixins/emailsMixin'
import {toastHelperMixin} from '../../mixins/toastHelperMixin'
import * as yup from 'yup'

export default {
  name: 'AddOrganizationDialog',
  mixins: [emailsMixin, toastHelperMixin],
  data: function () {
    const schema = yup.object({
      orgName: yup.string().required().label('Organization Name'),
      adminName: yup.string().required().label('Administrator Name'),
      adminEmail: yup.string().required().email().label('Administrator Email')
    });
    return {
      schema,
      disableButton: false,
      isGeneralErrorVisible: false,
      newOrganizationId: undefined,
      userId: undefined,
      isDataInvalid: false,
      organizationName: null,
      isOrganizationNameErrorVisible: false,
      organizationAdminEmail: null,
      isOrganizationAdminEmailErrorVisible: false,
      createUserResponse: null,
      show: true,
    }
  },
  methods: {
    async inviteUserToOrganization() {
      this.disableButton = true;
      this.isDataInvalid = false
      this.isGeneralErrorVisible = false
      this.isOrganizationNameErrorVisible = false
      this.isOrganizationAdminEmailErrorVisible = false
      let isDataInvalid = await this.inputDataHasErrors()
      if (isDataInvalid) return
      await this.getUser()
      let createOrganizationResponse = await this.createOrganization().then((res) => {
        return res
      }).catch((err) => {
        this.showToast(err.response.data.message, 'error')
        return err
      })
      if (createOrganizationResponse.status === 200) {
        this.newOrganizationId = createOrganizationResponse.data.organizationId
      } else {
        return
      }
      await this.addUserToOrganization()
      try {
        await this.sendInviteExistingEmail()
      } catch {
        this.generalErrMessage =
          'Internal server error. Please try again later!'
        this.isGeneralErrorVisible = true
        return
      }
      this.disableButton = false;
      this.$emit('on-close')
      this.$emit('update-organizations')
    },
    inputDataHasErrors() {
      this.isOrganizationNameErrorVisible = !this.organizationName
      return (this.isOrganizationNameErrorVisible)
    },
    async createOrganization() {
      return await axios.post('/.netlify/functions/create-organization', {
        name: this.organizationName,
        userDomain: [`${this.organizationAdminEmail.split('@')[1]}`],
      })
    },
    async getUser() {
      await axios
        .post('/.netlify/functions/get-user-id', {})
        .then((r) => {
          this.userId = r.data.userId
          this.organizationAdminEmail = r.data.email;
        })
    },
    async addUserToOrganization() {
      await axios
        .post('/.netlify/functions/add-user-to-organization', {
          userId: this.userId,
          organizationId: this.newOrganizationId,
        })
        .catch(() =>
          this.showToast(
            'A problem occured while adding adding user to organization. Please try again later!',
            'error'
          )
        )
    },
    async sendInviteNewEmail() {
      let emailTemplates = await this.inviteUserToEntitiyEmailSend(
        false,
        'organization'
      )
      await axios.post(
        '/.netlify/functions/send-invite-new-user-to-organization',
        {
          email: this.organizationAdminEmail,
          organizationName: this.organizationName,
          emailYaml: emailTemplates.emailYaml,
          emailHTML: emailTemplates.emailHTML,
        }
      )
    },
    async sendInviteExistingEmail() {
      let emailTemplates = await this.inviteUserToEntitiyEmailSend(
        true,
        'organization'
      )
      await axios.post('/.netlify/functions/send-invite-to-organization', {
        email: this.organizationAdminEmail,
        organizationName: this.organizationName,
        emailYaml: emailTemplates.emailYaml,
        emailHTML: emailTemplates.emailHTML,
      })
    },
  },
}
