import homePageYaml from 'js-yaml-loader!../../../../content/home.yaml';

export default {
  name: 'ImplementationModel',
  data: function () {
    return {
      implementation: homePageYaml.implementationModel,
      blueSections: homePageYaml.implementationModel.blue,
      graySections: homePageYaml.implementationModel.gray,
    };
  },
};
