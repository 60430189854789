import axios from '../../axios';

export const addUserToEntity = {
  methods: {
    /**
     * Add user to entity and add entity to user
     */
    async addUserToEntity(entity, userID, entityName, digitalProductName = '') {
      let entityRequestName = entity;
      let response;
      if (entity === 'environment') {
        const digitalProductId = await axios.post('/.netlify/functions/get-digital-product-id', {
          digitalProductName: digitalProductName
        }).then((res) => res.data.digitalProductId);
        response = await axios.post('/.netlify/functions/get-environment-id', {
          digitalProductId: digitalProductId,
          environmentName: entityName,
        });
      } else {
        if (entity == 'digital-product') entity = 'digitalProduct';
        response = await axios.post(
          `/.netlify/functions/get-${entityRequestName}-id`,
          {
            [`${entity}Name`]: entityName
          }
        );
      }

      let id = response.data[`${entity}Id`];
      await axios.post(
        `/.netlify/functions/add-user-to-${entityRequestName}`,
        {
          userId: userID,
          [`${entity}Id`]: id
        }
      );
    }
  }
};
