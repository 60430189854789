<template>
  <div>
    <img
      :src="
        logoColor === 'white'
          ? '/assets/images/auxta-logo.svg'
          : '/assets/images/auxta-logo-black.svg'
      "
      @click="navigateToHome()"
    />
  </div>
</template>

<script src="./headerLogo.js"></script>


<style lang="scss" scoped src="./headerLogo.scss"></style>
