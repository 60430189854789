<template>
  <div class="sidebar-layout">
    <div class="sidebar">
      <Navigation/>
    </div>
    <div class="page-content">
      <router-view/>
    </div>
  </div>
</template>

<script src="./sidebarLayout.js"></script>

<style lang="scss" scoped src="./sidebarLayout.scss"></style>
