<template>
  <div class="rectangle-container" @click="$emit('on-navigate')" @click.middle="$emit('on-navigate')">
    <div class="rectangle-top">
      <div>
        <img :src="buildingImg"/>
        <div v-if="!token" class="company-name">
          {{ name }}
        </div>
        <div v-if="token" class="company-name">
          {{ name }} / {{ token }}
        </div>
      </div>
      <p-button
        v-if="isAdmin"
        class="p-button"
        icon="pi pi-pencil"
        label="Edit"
        @click.stop="$emit('on-edit')">
      </p-button>
    </div>
    <div class="providers">
      {{ providers }}
    </div>
    <div class="flag">
      {{ typeNumberToString(type) }}
    </div>
    <div class="rectangle-pie-charts">
      <div>
        <GooglePieChart
          :background-color="'transparent'"
          :d="150"
          :data="data1"
          class="piechart"
        />
        <div class="description">
          {{ text1 }}
        </div>
      </div>
      <div>
        <GooglePieChart
          :background-color="'transparent'"
          :d="150"
          :data="data2"
          class="piechart"
        />
        <div class="description">
          {{ text2 }}
        </div>
      </div>
      <div>
        <GooglePieChart
          :background-color="'transparent'"
          :d="150"
          :data="data3"
          class="piechart"
        />
        <div class="description">
          {{ text3 }}
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./pieChartRectangle.js"></script>

<style lang="scss" scoped src="./pieChartRectangle.scss"></style>
