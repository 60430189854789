import FooterComponent from '../../components/footer/footer.vue';
import HeaderLogo from '../../components/headerLogo/headerLogo.vue';
import TopNavigationSmall from '../../components/topNavigationSmall/topNavigationSmall.vue';
import registrationPageYaml from 'js-yaml-loader!../../../../content/registration.yaml';
import {navigateAfterLoginMixin} from '../../mixins/navigateAfterLoginMixin';

export default {
  name: 'Registration',
  components: {
    FooterComponent,
    HeaderLogo,
    TopNavigationSmall,
  },
  mixins: [
    navigateAfterLoginMixin,
  ],
  data: function () {
    return {
      title: '',
      leftSideBackgroundImage: '',
      text: '',
      phoneIcon: '',
      mailIcon: '',
      phoneNumber: '',
      mail: '',
      headerHome: '',
      headerAbout: '',
      headerFAQ: '',
      fullName: '',
      email: '',
      mobileNumber: '',
      alreadyHaveAnAccountQuestion: '',
      alreadyHaveAnAccountLink: '',
    };
  },
  mounted() {
    this.title = registrationPageYaml.title;
    this.leftSideBackgroundImage = registrationPageYaml.leftSideBackgroundImage;
    this.text = registrationPageYaml.text;
    this.phoneIcon = registrationPageYaml.phoneIcon;
    this.mailIcon = registrationPageYaml.mailIcon;
    this.phoneNumber = registrationPageYaml.phoneNumber;
    this.mail = registrationPageYaml.mail;
    this.headerHome = registrationPageYaml.headerHome;
    this.headerAbout = registrationPageYaml.headerAbout;
    this.headerFAQ = registrationPageYaml.headerFAQ;
    this.alreadyHaveAnAccountQuestion =
      registrationPageYaml.alreadyHaveAnAccountQuestion;
    this.alreadyHaveAnAccountLink =
      registrationPageYaml.alreadyHaveAnAccountLink;
  },
  methods: {
    async toLogin() {
      if (localStorage.token)
        await this.navigateAfterLogin();
      else
        this.$router.push('/login');
    },
  },
};
