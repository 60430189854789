import {toastHelperMixin} from '../../../mixins/toastHelperMixin';
import {rolesEnumHelperMixin} from '../../../mixins/rolesEnumHelperMixin';
import {addUserToEntity} from '../../../mixins/addUserToEntity';
import axios from '../../../../axios';
import {emailsMixin} from '../../../mixins/emailsMixin';

export default {
  name: 'ParticipantsModal',
  components: {},
  mixins: [
    toastHelperMixin,
    rolesEnumHelperMixin,
    addUserToEntity,
    emailsMixin,
  ],
  props: {
    id: String,
    name: String,
    isAdmin: Boolean,
    users: Number,
    viewType: String, // Can be 'organization', 'digitalproduct' or 'environment'
  },
  data: function () {
    return {
      show: true,
      loading: true,
      inviteUserEmail: '',
      inviteUserDomain: undefined,
      inviteUserRole: undefined,
      invitationRequestRunning: false,
      invitations: [],
      allowedDomains: [],
      userRoles: [{label: 'Platform Administrator', value: 0},
        {label: 'Organisation Administrator', value: 1},
        {label: 'Organisation Member', value: 3},],
      organizationName: this.$route.params['organization_name'],
      digitalProductName: this.$route.params['digital_product_name'],
      environmentName: this.$route.params['environment_name'],
      viewTypeField: this.viewType,
    };
  },
  created: async function () {
    if (this.viewTypeField == 'digital product') this.viewTypeField = 'digital-product'
    try {
      this.allowedDomains = (
        await axios.get(
          `/.netlify/functions/get-${this.viewTypeField}-domains?${this.viewTypeField}Id=${this.id}`
        )
      ).data.map((d) => `@${d}`);
      const invitations = (
        await axios.get(
          `/.netlify/functions/get-${this.viewTypeField}-users?${this.viewTypeField}Id=${this.id}`
        )
      ).data;
      this.invitations = invitations
        .sort((a, b) => a.accepted - b.accepted)
        .map((invitation) => {
          return {
            accepted: invitation.accepted,
            name: invitation.name,
            email: invitation.email,
            role: invitation.role,
            removeUserRequestRunning: false,
            resendInvitationRequestRunning: false,
          };
        });
      this.loading = false;
    } catch (error) {
      this.showGenericErrorToast();
    }
  },
  methods: {
    async sendInvitation() {
      const email = `${this.inviteUserEmail}${this.inviteUserDomain}`;
      if (this.invitations.some((i) => i.email === email)) {
        this.showToast('This e-mail is already in the list!', 'error');
        return;
      }
      this.invitationRequestRunning = true;
      try {
        await this.inviteUser(email);
        this.invitations = [
          {
            accepted: false,
            name: '',
            email,
            role: this.roleNumberToEnum(this.inviteUserRole),
            removeUserRequestRunning: false,
            resendInvitationRequestRunning: false,
          },
          ...this.invitations,
        ];
        this.showToast('Invitation sent.', 'success');
        this.inviteUserEmail = '';

        this.$emit('userInteraction', this.id);
      } catch (error) {
        this.showGenericErrorToast();
      } finally {
        this.invitationRequestRunning = false;
      }
    },
    async removeUser(email) {
      this.invitations.find(
        (i) => i.email === email
      ).removeUserRequestRunning = true;
      try {
        await axios.post(
          `/.netlify/functions/remove-user-from-${this.viewTypeField}`,
          {
            userId: (
              await axios.post('/.netlify/functions/get-user-id', {
                email,
              })
            ).data.userId,
            [`${this.viewTypeField}Id`]: this.id,
          }
        );
        this.showToast('User removed.', 'error');
        this.invitations = this.invitations.filter((i) => i.email !== email);

        this.$emit('userInteraction', this.id);
      } catch (error) {
        this.invitations.find(
          (i) => i.email === email
        ).removeUserRequestRunning = false;
        this.showGenericErrorToast();
      }
    },
    async resendInvitation(email) {
      this.invitations.find(
        (i) => i.email === email
      ).resendInvitationRequestRunning = true;
      try {
        let isUserRegistered = await axios.get(
          `/.netlify/functions/is-invited-user-registered?userEmail=${email}`
        );

        let emailTemplates = await this.inviteUserToEntitiyEmailSend(
          isUserRegistered.data.isUserRegistered,
          this.viewTypeField
        );

        await axios.post(
          `/.netlify/functions/send-invite${
            isUserRegistered.data.isUserRegistered ? '' : '-new-user'
          }-to-${this.viewTypeField}`,
          {
            email,
            [`${this.viewTypeField}Name`]: this.name,
            emailYaml: emailTemplates.emailYaml,
            emailHTML: emailTemplates.emailHTML,
          }
        );
        this.showToast('Invitation re-sent.');
      } catch (error) {
        this.showGenericErrorToast();
      } finally {
        this.invitations.find(
          (i) => i.email === email
        ).resendInvitationRequestRunning = false;
      }
    },
    // Run the actual requests to the backend to invite a user:
    async inviteUser(email) {
      const isNewUser = (
        await axios.post('/.netlify/functions/create-temporary-user', {
          email,
          role: this.inviteUserRole,
          entityId: this.id,
        })
      ).data.isNewUser;

      let emailTemplates = await this.inviteUserToEntitiyEmailSend(
        isNewUser ? false : true,
        this.viewTypeField
      );

      await axios.post(
        `/.netlify/functions/send-invite${isNewUser ? '-new-user' : ''}-to-${this.viewTypeField}`,
        {
          email,
          [`${this.viewTypeField}Name`]: this.name,
          entityId: this.id,
          emailYaml: emailTemplates.emailYaml,
          emailHTML: emailTemplates.emailHTML,
        }
      );
      const userID = (
        await axios.post('/.netlify/functions/get-user-id', {
          email,
        })
      ).data.userId;
      let error;
      switch (this.viewTypeField) {
        case 'organization':
          try {
            await this.addUserToEntity(this.viewTypeField, userID, this.organizationName);
          } catch {
            this.showGenericErrorToast();
          }
          break;
        case 'digital-product':
          error = false;
          try {
            await this.addUserToEntity('organization', userID, this.organizationName);
          } catch {
            error = true;
          }
          try {
            await this.addUserToEntity('digital-product', userID, this.digitalProductName);
          } catch {
            error = true;
          }
          if (error) {
            this.showGenericErrorToast();
          }
          break;
        case 'environment':
          error = false
          try {
            await this.addUserToEntity('organization', userID, this.organizationName);
          } catch {
            error = true;
          }
          try {
            await this.addUserToEntity('digital-product', userID, this.digitalProductName);
          } catch {
            error = true;
          }
          try {
            await this.addUserToEntity('environment', userID, this.environmentName, this.digitalProductName);
          } catch {
            error = true;
          }
          if (error) {
            this.showGenericErrorToast();
          }
          break;
      }
    },
  },
};
