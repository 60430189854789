import homePageYaml from 'js-yaml-loader!../../../../content/home.yaml';

export default {
  name: 'Team',
  data: function () {
    return {
      team: homePageYaml.team,
    };
  },
};
