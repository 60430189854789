import axios from '../../axios';

export const navigateAfterLoginMixin = {
  methods: {
    /**
     * Navigate to the correct route after login
     * @returns
     */
    async navigateAfterLogin() {
      if (localStorage.getItem('currentReportPath')) return;
      await axios.get('/.netlify/functions/get-organizations').then((organizations) => {
        this.organizations = organizations.data;
      });
      if (this.organizations.length === 1) {
        localStorage.setItem('userHasOneOrganization', 'true');
        await axios
          .post('/.netlify/functions/get-digital-products', {
            organizationName: this.organizations[0].name,
          })
          .then(async (digitalProducts) => {
            this.digitalProducts = digitalProducts.data;
          });
        if (this.digitalProducts.length === 1) {
          localStorage.setItem('userHasOneDigitalProduct', 'true');
          await axios
            .post('/.netlify/functions/get-environments', {
              digitalProductName: this.digitalProducts[0].name,
            })
            .then((environments) => {
              if (environments.data.length === 1) {
                localStorage.setItem('userHasOneEnvironment', 'true');
                this.$router.push(
                  // eslint-disable-next-line
                  `/organizations/${this.organizations[0].name}/digital-products/${this.digitalProducts[0].name}/environments/${environments.data[0].name}/reports`
                );
              } else {
                this.$router.push(
                  // eslint-disable-next-line
                  `/organizations/${this.organizations[0].name}/digital-products/${this.digitalProducts[0].name}/environments`
                );
              }
            }).catch(() => {
              this.$router.push(
                `/organizations/${this.organizations[0].name}/digital-products/${this.digitalProducts[0].name}/environments`
              );
            });
        } else {
          await this.$router.push(
            `/organizations/${this.organizations[0].name}/digital-products`
          );
        }
      } else {
        await this.$router.push('/organizations');
      }
    },
  }
}
