<template>
  <p-dialog :aria-modal="null"
            :breakpoints="{'2400px': '40vw', '1060px': '50vw', '640px': '100vw'}"
            :modal="true"
            :visible="show"
            @update:visible="$emit('on-close')">
    <template #header>
      <h3>Edit digital product</h3>
    </template>
    <p-progress v-if="loading" mode="indeterminate" style="height: 6px"></p-progress>
    <div :class="{ loading: loading }" class="modal-card">
      <section class="modal-card-body">
        <div class="input-field">
          <h5>Digital product name:</h5>
          <span class="p-input-icon-left">
            <i class="pi pi-building"/>
            <p-input v-model="digitalProductName" placeholder="Organization name" type="text"/>
        </span>
        </div>
        <div class="input-field">
          <h5>Digital product token:</h5>
          <span class="p-input-icon-left">
            <i class="pi pi-building"/>
            <p-input v-model="token" disabled="true" placeholder="Token" type="text"/>
        </span>
        </div>
      </section>
    </div>
    <template #footer>
      <p-button
        :disabled="saveDataRequestRunning"
        class="done-btn"
        @click="saveData"
      >
        <i
          v-if="saveDataRequestRunning"
          class="pi pi-spin pi-spinner"
        ></i>
        <span>Done</span></p-button
      >
    </template>
  </p-dialog>
</template>

<script src="./editDigitalProductModal.js"></script>

<style lang="scss" scoped src="./editDigitalProductModal.scss"></style>
