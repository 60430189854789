<template>
  <ul class="top-navigation">
    <li
      v-for="(listItem, index) of topSmallNavbarLinks"
      :key="listItem[index]"
      @click="navigate(index)"
    >
      {{ listItem }}
    </li>
  </ul>
</template>

<script src="./topNavigationSmall.js"></script>

<style lang="scss" scoped src="./topNavigationSmall.scss"></style>
