<template>
  <div class="articles-container">
    <div
      :style="{
        background: `url(${pageHeadingContent.image}) no-repeat`,
      }"
      class="header-section"
    >
      <TopNavigationArticle :logo-color="pageHeadingContent.logoColor"/>
      <div class="content">
        <div class="left-side-container">
          <div class="category">
            {{ pageHeadingContent.category }}
          </div>
          <div class="title">
            {{ pageHeadingContent.title }}
          </div>
          <div class="profile">
            <img :src="authorContent.image"/>
            <div class="text">
              <div class="name">
                {{ authorContent.name }}
              </div>
              <div class="date">
                {{ formatDateArticle(pageHeadingContent.published) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="article">
      <div class="subheader">
        <div class="subtitle">
          {{ pageHeadingContent.subtitle }}
        </div>
        <div class="summary">
          {{ pageHeadingContent.summary }}
        </div>
      </div>
      <div
        v-for="(article, index) of articles"
        :key="index"
        :class="{
          'titleless-article': !article.title,
        }"
        class="article-section"
      >
        <div v-if="article.title" class="title">
          {{ article.title }}
        </div>
        <div v-if="article.image" class="image">
          <img
            :class="{
              'image-with-label': article.label,
              'image-without-label': !article.label,
            }"
            :src="article.image"
          />
          <div v-if="article.label" class="image-label">
            {{ article.label }}
          </div>
        </div>
        <div v-if="article.content" class="content">
          <div
            v-for="paragraph of article.content"
            :key="paragraph"
            class="paragraph"
          >
            {{ paragraph }}
          </div>
        </div>
      </div>
    </div>
    <div class="footer-container">
      <FooterComponent/>
    </div>
  </div>
</template>

<script src="./article.js"></script>

<style lang="scss" scoped src="./article.scss"></style>
