<template>
  <div class="page-wrapper">
    <h1 class="page-title">{{ manualReportsPageYaml.title }}</h1>
    <div class="filters">
        <p-select
            v-model="organizationName"
            :options="organizations"
            class="type-select"
            optionLabel="name"
            optionValue="name"
            placeholder="Organization"
            @change="updateOrganization(organizationName)"
          ></p-select>
          <p-select
            v-model="digitalProductName"
            :options="digitalProducts"
            :disabled="!responses[1]"
            class="type-select"
            optionLabel="name"
            optionValue="name"
            placeholder="DigitalProduct"
            @change="updateDigitalProduct(digitalProductName)"
          >
          </p-select>
    </div>
    <div class="Periodicals" v-if="digitalProductId">
      <button class="addPeriodical"  @click="showAddPeriodical = !showAddPeriodical">
        <span>+ Periodical</span>
      </button>
      <div class="addPeriodicalPopUp" v-if="showAddPeriodical">
        <input type="text" placeholder="Name" id="periodicalName" />
        <button class="add" @click="addPeriodical(organizationName, digitalProductName)">Add Periodical</button>
      </div>
      <div class="filters">
        <div class="periodical" v-for="periodical in Periodicals" :key="periodical.key">
          <button class="open" @click="open(periodical.organizationName, periodical.digitalProductName, periodical.name, periodical._id)">
            {{ periodical.name }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./manualReports.js"></script>

<style lang="scss" scoped src="./manualReports.scss"></style>
