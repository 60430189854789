import axios from '../../../axios';
import HeaderLogo from '../../components/headerLogo/headerLogo.vue';
import FooterComponent from '../../components/footer/footer.vue';
import TopNavigationSmall from '../../components/topNavigationSmall/topNavigationSmall.vue';
import login from 'js-yaml-loader!../../../../content/login.yaml';
import {toastHelperMixin} from '../../mixins/toastHelperMixin';
import {emailsMixin} from '@/app/mixins/emailsMixin';

export default {
  name: 'Login',
  components: {
    HeaderLogo,
    FooterComponent,
    TopNavigationSmall,
  },
  mixins: [toastHelperMixin, emailsMixin],
  data: function () {
    return {
      email: '',
      password: '',
      emailValidate: false,
      isCredentialsRight: true,
      image: '',
      logoColor: 'white',
      header: '',
      loginText: '',
      invalidLoginCredentials: '',
      title: '',
      leftSideBackgroundImage: '',
      text: '',
      formHelperText1: '',
      formHelperText2: '',
      formHelperText3: '',
      loginButton: '',
      fullName: '',
      rememberMe: '',
      dontHaveAnAccountQuestion: '',
      dontHaveAnAccountLink: '',
      forgotPasswordLink: '',
      rememberMeValue: false,
      phoneIcon: '',
      emailImage: '',
      mailIcon: '',
      passwordImage: '',
      phoneNumber: '',
      mail: '',
      eye: '',
      eyeSlash: '',
      isPasswordVisible: false,
      organizations: [],
      digitalProducts: [],
      loginRequest: false
    };
  },
  created() {
    if (localStorage.token) {
      this.navigateAfterLogin();
    }
  },
  mounted() {
    this.title = login.title;
    this.leftSideBackgroundImage = login.leftSideBackgroundImage;
    this.text = login.text;
    this.formHelperText1 = login.formHelperText1;
    this.formHelperText2 = login.formHelperText2;
    this.formHelperText3 = login.formHelperText3;
    this.loginButton = login.loginButton;
    this.invalidLoginCredentials = login.invalidLoginCredentials;
    this.rememberMe = login.rememberMe;
    this.dontHaveAnAccountQuestion = login.dontHaveAnAccountQuestion;
    this.dontHaveAnAccountLink = login.dontHaveAnAccountLink;
    this.forgotPasswordLink = login.forgotPasswordLink;
    this.phoneIcon = login.phoneIcon;
    this.mailIcon = login.mailIcon;
    this.phoneNumber = login.phoneNumber;
    this.mail = login.mail;
    this.emailImage = login.emailImage;
    this.passwordImage = login.passwordImage;
    this.eye = login.eye;
    this.eyeSlash = login.eyeSlash;
  },
  methods: {
    async requestLogin() {
      this.loginRequest = true;
      await axios.post('/.netlify/functions/login', {
        email: this.email,
        password: this.password,
      })
        .then(async (data) => {
          var now = new Date();
          var afterOneDay = now.getTime() + 1000 * 60 * 60 * 24;
          var token = {
            token: data.data.token,
            expiryToken: afterOneDay,
          };
          localStorage.setItem('token', JSON.stringify(token));
          if (this.rememberMeValue) {
            localStorage.setItem('rememberMeToken', JSON.stringify(token));
          }
          if (localStorage.getItem('currentReportPath')) {
            await this.$router.push(
              localStorage.getItem('currentReportPath')
            );
          }
          // navigateAfterLogin only after success
          this.navigateAfterLogin();

        })
        .catch(() => {
          this.isCredentialsRight = false;
          this.loginRequest = false;
          this.loginRequest = false;
        });
    },
    async validateEmail() {
      this.loginRequest = true;
      let emailTemplates = await this.getTokenEmailTemplates();
      await axios.post('/.netlify/functions/verify_email_send_token', {
        email: this.email,
        emailYaml: emailTemplates.emailYaml,
        emailHTML: emailTemplates.emailHTML,
      }).then(async () => {
        this.emailValidate = true;
        this.loginRequest = false;
      }).catch(() => {
        this.isCredentialsRight = false;
        this.loginRequest = false;
      });
    },
    async formSubmit() {
      if (!this.emailValidate) {
        await this.validateEmail();
      } else {
        await this.requestLogin();
      }
    },
    toRegistration() {
      this.$router.push('/registration');
    },
    async navigateAfterLogin() {
      if (localStorage.getItem('currentReportPath')) return;
      if (localStorage.getItem('pathAfterLogin')) {
        const path = localStorage.getItem('pathAfterLogin')
        localStorage.removeItem('pathAfterLogin')
        await this.$router.push(path);
        return;
      }
      await axios.get('/.netlify/functions/get-organizations').then((organizations) => {
        this.organizations = organizations.data;
      });
      if (this.organizations.length === 1) {
        localStorage.setItem('userHasOneOrganizationn', 'true');
        await axios
          .post('/.netlify/functions/get-digital-products', {
            organizationName: this.organizations[0].name,
          })
          .then(async (digitalProducts) => {
            this.digitalProducts = digitalProducts.data;
          });
        if (this.digitalProducts.length === 1) {
          localStorage.setItem('userHasOneDigitalProduct', 'true');
          await axios
            .post('/.netlify/functions/get-environments', {
              digitalProductName: this.digitalProducts[0].name,
            })
            .then((environments) => {
              if (environments.data.length === 1) {
                localStorage.setItem('userHasOneEnvironment', 'true');
                this.$router.push(
                  // eslint-disable-next-line
                  `/organizations/${this.organizations[0].name}/digital-products/${this.digitalProducts[0].name}/environments/${environments.data[0].name}/reports`
                );
              } else {
                this.$router.push(
                  `/organizations/${this.organizations[0].name}/digital-products/${this.digitalProducts[0].name}/environments`
                );
              }
            })
            .catch(() => {
              this.$router.push(
                `/organizations/${this.organizations[0].name}/digital-products/${this.digitalProducts[0].name}/environments`
              );
            });
        } else {
          await this.$router.push(
            `/organizations/${this.organizations[0].name}/digital-products`
          );
        }
      }
      if (localStorage.getItem('currentReportPath')) {
        await this.$router.push(localStorage.getItem('currentReportPath'));
      } else if (
        localStorage.getItem('userHasOneOrganization') &&
        localStorage.getItem('userHasOneDigitalProduct')
      ) {
        this.$router.push(
          `/organizations/${this.organizations[0].name}/digital-products/${this.digitalProducts[0].name}/reports`
        );
      } else if (localStorage.getItem('userHasOneOrganization')) {
        this.$router.push(
          `/organizations/${this.organizations[0].name}/digital-products`
        );
      } else {
        this.$router.push('/organizations');
      }
    },
    navigateToForgotPass() {
      // autofill email in forgotpass if it's valid
      // eslint-disable-next-line
      let regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (this.email.match(regex))
        localStorage.setItem('loginEmail', this.email ? this.email : '');

      this.$router.push('/forgot-password');
    },
  },
};
