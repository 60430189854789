import axios from '../../../axios';
import ProgressBar from '../../components/progressBar/progressBar.vue';
import BigPieCharts from '../../components/bigPieCharts/bigPieCharts.vue';
import reportsPageYaml from 'js-yaml-loader!../../../../content/reports.yaml';
import {dateHelperMixin} from '../../mixins/dateHelperMixin';
import {canShowParticipantsModalMixin} from '../../mixins/canShowParticipantsModalMixin';
import ParticipantsModal from '../../components/modals/participantsModal/participantsModal.vue';
import {toastHelperMixin} from '../../mixins/toastHelperMixin';
import {checkIfAdminMixin} from '../../mixins/checkIfAdminMixin';

export default {
  name: 'Reports',
  components: {
    BigPieCharts,
    ProgressBar,
    ParticipantsModal,
  },
  mixins: [
    dateHelperMixin,
    canShowParticipantsModalMixin,
    toastHelperMixin,
    checkIfAdminMixin
  ],
  data: function () {
    return {
      isAdmin: false,
      organizationName: this.$route.params['organization_name'],
      digitalProductName: this.$route.params['digital_product_name'],
      digitalProductId: undefined,
      environmentName: this.$route.params['environment_name'],
      bucketName: this.$route.params['bucket_name'],
      scenarioName: this.$route.params['scenario_name'],
      environmentId: undefined,
      reportsPageYaml: reportsPageYaml,
      reports: [],
      features: [],
      scenarios: [],
      steps: [],
      period: 'month',
      crumBarCompanies: '',
      currentPage: 0,
      maxPageReached: false,
      scrolled: true,
      userCount: undefined,
      didResponseCome: false,
    };
  },
  async created() {
    this.digitalProductId = await axios.post('/.netlify/functions/get-digital-product-id', {
      digitalProductName: this.digitalProductName,
    }).then((res) => res.data.digitalProductId);
    this.environmentId = await axios.post('/.netlify/functions/get-environment-id', {
      digitalProductId: this.digitalProductId,
      environmentName: this.environmentName,
    }).then((res) => res.data.environmentId);
    this.getTableData();
    this.getStatisticRepotsData();
    this.isAdmin = await this.isUserAdmin(axios, false);
    try {
      if (this.isAdmin) {
        this.userCount = (
          await axios.get(
            `/.netlify/functions/get-environment-users?environmentId=${this.environmentId}`
          )
        ).data.length;
      }
    } catch (error) {
      this.showGenericErrorToast();
    }
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.onScroll);
  },
  async mounted() {
    this.$nextTick(function () {
      window.addEventListener('scroll', this.onScroll);
      this.onScroll();
    });
    this.reportsPageYaml = reportsPageYaml;
  },
  methods: {
    updateStatistics(type, period) {
      this.features = []
      this.scenarios = []
      this.steps = []
      this.getStatisticRepotsData(type, period)


    },

    async getStatisticRepotsData(type, period = Number(process.env.DEFAULT_PERIOD)) {
      const bucket = await axios.post('/.netlify/functions/get-bucket', {
        bucketName: this.bucketName,
        environmentId: this.environmentId
      });
      axios.post('/.netlify/functions/get-statistics-reports', {
        bucketId: bucket.data.id,
        scenarioName: this.scenarioName,
        period: period,
        type: type,
      })
        .then(({data}) => {
          this.didResponseCome = true;

          this.features.push(['PASSED', data.passedBuckets || 0]);
          this.features.push(['FAILED', data.failedBuckets || 0]);
          this.scenarios.push(['PASSED', data.passedScenarios || 0]);
          this.scenarios.push(['FAILED', data.failedScenarios || 0]);
          this.steps.push(['PASSED', data.passedSteps || 0]);
          this.steps.push(['FAILED', data.failedSteps || 0]);
          this.steps.push(['SKIPPED', data.skippedSteps || 0]);
          this.steps.push(['SUGGESTED', data.suggestedSteps || 0]);
          this.steps.push(['PERFORMANCE_FAILURE', data.performanceFailure || 0]);
        });
    },
    navigateToCurrentReport: function (reportId) {
      // eslint-disable-next-line
      let route = `/organizations/${this.organizationName}/digital-products/${this.digitalProductName}/environments/${this.environmentName}/buckets/${this.bucketName}/scenarios/${this.scenarioName}/reports/${reportId}/steps`;
      if (event.button == 0) this.$router.push(route);
      else if (event.button == 1) window.open(route);
    },
    async getTableData() {
      const bucket = await axios.post('/.netlify/functions/get-bucket', {
        bucketName: this.bucketName,
        environmentId: this.environmentId
      });
      axios.post('/.netlify/functions/get-reports-list', {
        environmentId: this.environmentId,
        scenarioName: this.scenarioName,
        bucketId: bucket.data.id,
        page: this.currentPage
      })
        .then(data => {
          for (let dataEntry of data.data) {
            this.reports.push(dataEntry);
          }
          if (data.data.length !== 20) {
            this.maxPageReached = true;
          } else {
            this.currentPage++;
          }
          setTimeout(() => {
            this.scrolled = false;
          }, 1000);
        });
    },
    async onScroll() {
      if (
        window.innerHeight + window.scrollY >= document.body.scrollHeight - 1 &&
        !this.scrolled &&
        !this.maxPageReached
      ) {
        this.scrolled = true;
        this.getTableData();
      }
    },
    async getUsersCount(entityId) {
      if (this.isAdmin) {
        this.userCount = (
          await axios.get(
            `/.netlify/functions/get-environment-users?environmentId=${entityId}`
          )
        ).data.length;
      }
    }
  },
};
