<template>
  <div class="current-report-dropdowns-container">
    <!-- Loading placeholders -->
    <div v-for="(n, index) in 6" :key="index">
      <Skeleton
        v-if="!accordionFeaturesWithOddData.length"
      >
      </Skeleton>
    </div>
    <div class="rectangles-container">
      <div class="rectangle-col-1">
        <div
          v-for="(feature, featuresIndex) of accordionFeaturesWithOddData"
          :key="featuresIndex.toString() + feature.name"
        >
          <ScenarioRectangle :name="feature.name"
                             :scenarios="feature.scenariosCount"
                             :status="feature.status"
                             :steps="feature.stepsCount"
                             @on-navigate="navigate(feature.id)">

          </ScenarioRectangle>
        </div>
      </div>
      <div class="rectangle-col-2">
        <div
          v-for="(feature, featuresIndex) of accordionFeaturesWithEvenData"
          :key="featuresIndex.toString() + feature.name"
        >
          <ScenarioRectangle :name="feature.name"
                             :scenarios="feature.scenariosCount"
                             :status="feature.status"
                             :steps="feature.stepsCount"
                             @on-navigate="navigate(feature.id)">


          </ScenarioRectangle>
        </div>

      </div>
    </div>
  </div>
</template>

<script src="./currentReportAccordion.js"></script>

<style lang="scss" scoped src="./currentReportAccordion.scss"></style>
