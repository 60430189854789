import HeaderLogo from '../headerLogo/headerLogo.vue';
import navbarsYaml from 'js-yaml-loader!../../../../content/navbars.yaml';
import {navigateAfterLoginMixin} from '../../mixins/navigateAfterLoginMixin';

export default {
  name: 'TopNavigationBar',
  components: {
    HeaderLogo,
  },
  mixins: [
    navigateAfterLoginMixin,
  ],
  data: function () {
    return {
      logoColor: 'black',
      listItems: [],
      listItemsBurger: [],
      buttonLogin: '',
      buttonRegister: '',
      buttonText: '',
      openedBurgerMenu: false,
    };
  },

  mounted() {
    this.listItems = navbarsYaml.topBigNavbar.topBigNavBarLinks;
    this.listItemsBurger = navbarsYaml.topBigNavbarBurger.topBigNavBarLinks;
    this.buttonText = navbarsYaml.topBigNavbar.buttonText;
    this.buttonLogin = navbarsYaml.topBigNavbarBurger.buttonLogin;
    this.buttonRegister = navbarsYaml.topBigNavbarBurger.buttonRegister;
  },
  methods: {
    async navigate(index) {
      if (index === 2) {
        if (localStorage.token)
          await this.navigateAfterLogin();
        else
          this.$router.push('/login');

      }
    },
    getStarted() {
      this.$router.push('/registration');
    },
    async toLogin() {
      if (localStorage.token)
        await this.navigateAfterLogin();
      else
        this.$router.push('/login');

    },
    emitScrollToValue(scrollToValue) {
      this.$emit('scroll-to', {elementId: scrollToValue.replace(/\s/g, '')});
    },
  },
};
