<template>
  <div class="navigation">
    <div class="logo">
      <img
        src="../../../../public/assets/images/logo.png"
        @click="navigateToFirstPage()"
      />
    </div>
    <div class="reports-overview-btn-wrapper">
      <button class="btn" @click="navigateToReportsOverviewPage()">
        <span>{{ reportsOverview }}</span>
        <img alt="" src="../../../../public/assets/images/table-of-data.png"/>
      </button>
    </div>
    <div class="manual-reports-btn-wrapper">
      <button class="btn" @click="navigateToManualReportsPage()">
        <span>{{ manualReports }}</span>
        <span class="pi pi-chart-bar" style="font-size: 2.25rem; color: white"></span>
      </button>
    </div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div class="logout-btn-wrapper">
      <button class="btn" @click="logout()">
        <span>{{ logoutText }}</span>
        <img src="../../../../public/assets/images/log-out.svg"/>
      </button>
    </div>
  </div>
</template>

<script src="./navigation.js"></script>

<style lang="scss" scoped src="./navigation.scss"></style>
