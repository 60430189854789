<template>
  <p-dialog
    :aria-modal="null"
    :breakpoints="{'2400px': '40vw', '1060px': '50vw', '640px': '100vw'}"
    :modal=true
    :visible="show"
    @update:visible="$emit('on-close')">
    <template #header>
      <h3>Add new organization</h3>
    </template>
    <div class="modal-card">
      <section class="modal-card-body">
        <!-- ----------------------------------------------------------------------- -->
        <VeeForm v-slot="{handleSubmit}" :validation-schema="schema" as="div"
        >
          <form id="a-form" @submit="handleSubmit($event,inviteUserToOrganization())">
            <Field id="orgName" v-slot="{ field, errorMessage }" name="organizationName">
              <div class="input-field">
                <label for="orgName">Organization Name:</label>
                <span class="p-input-icon-left">
            <i class="pi pi-building"/>
            <p-input
              v-model="organizationName"
              :class="{ 'p-invalid': errorMessage }"
              placeholder="Organization name"
              v-bind="field"
            />
            </span>
                <small class="p-error">{{ errorMessage }}</small>
              </div>
            </Field>
          </form>
        </VeeForm>
        <!-- ------------------------------------------------------------------------ -->
      </section>
    </div>
    <template #footer>
      <div class="submit-btn">
        <p-button
          :loading="disableButton"
          form="a-form"
          type="submit"
        >
          <span>Done</span></p-button>
      </div>

    </template>
  </p-dialog>
</template>

<script src="./addOrganizationDialog.js"></script>

<style lang="scss" scoped src="./addOrganizationDialog.scss"></style>
